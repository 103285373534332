(function () {
    'use strict';

    angular.module('bunge.system').service('DynamicModulesLoader', DynamicModulesLoader);

    DynamicModulesLoader.$inject = ['Session', '$ocLazyLoad', 'ADM_NAV_DYN_OPTIONS', '$q', 'FOR_NAV_DYN_OPTIONS', 'ModulesLoaded'];

    function DynamicModulesLoader(Session, $ocLazyLoad, ADM_NAV_DYN_OPTIONS, $q, FOR_NAV_DYN_OPTIONS, ModulesLoaded) {

        function loadModulesFromToken(portal) {
            var modules = Session.GetPortalModules();
            var promises = [];
            var dynamic = portal == "administracao" ? ADM_NAV_DYN_OPTIONS : FOR_NAV_DYN_OPTIONS;
            Object.keys(modules).map(function (key) {
                var item = modules[key];
                item.menu.forEach(function (value) {
                    if (dynamic[value.internalKey]) {
                        promises.push($ocLazyLoad.load({ serie: true, cache: false, files: dynamic[value.internalKey] }));
                    }
                });
            });
            ModulesLoaded.GetLoaded()[portal] = true;
            return $q.all(promises);
        };

        return {
            LoadModulesFromToken: loadModulesFromToken,
        };
    }
}());