(function () {

    'use strict';

    angular
        .module('bunge')
        .factory('SessionManagerFactory', SessionManagerFactory);

    SessionManagerFactory.$inject = ['Session', 'TokenManagerFactory', '$rootScope'];
    function SessionManagerFactory(Session, TokenManagerFactory, $rootScope) {

        function CreateSession(token) {
            Session.Create(TokenManagerFactory.DecodeToken(token));
        }

        function DestroySession() {
            Session.Destroy();
        }

        function GetSession() {
            return $rootScope.currentUser;
        }

        function IsAuthorized(authorizedRoles) {
            if (!angular.isArray(authorizedRoles)) {
                authorizedRoles = [authorizedRoles];
            }
            return (IsAuthenticated() &&
              authorizedRoles.indexOf(Session.userRole) !== -1);
        }

        return {
            CreateSession: CreateSession,
            DestroySession: DestroySession,
            IsAuthorized: IsAuthorized,
            GetSession: GetSession
        };
    }
})();