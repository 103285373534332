(function () {

    'use strict';

    angular
        .module('bunge')
        .factory('UserManagerFactory', UserManagerFactory);

    UserManagerFactory.$inject = ['$window', 'jwtHelper', '$location', 'TokenManagerFactory'];
    function UserManagerFactory($window, jwtHelper, $location, TokenManagerFactory) {

        function RemoveUser() {
            return TokenManagerFactory.hub().onConnect().then(function () {
                return TokenManagerFactory.hub().del('user');
            })
        }

        function GetUser() {
            return TokenManagerFactory.hub().onConnect().then(function () {
                return TokenManagerFactory.hub().get('user');
            })
        }

        function SetUser(user) {
            TokenManagerFactory.hub().onConnect().then(function () {
                return TokenManagerFactory.hub().set('user', user);
            },
            function (e) {
                console.log(e);
            })
        }

        return {     
            GetUser: GetUser,
            SetUser: SetUser,
            RemoveUser: RemoveUser,
        };
    }
})();