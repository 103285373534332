(function () {
    'use strict';

    angular.module('bunge.modules.alterar-senha')
        .config(['$stateProvider',ConfigAlterarSenha]);

    function ConfigAlterarSenha($stateProvider) {
        $stateProvider
            .state('configuracoes.alterar-senha', {
                url: '/alterar-senha',
                templateUrl: 'app/modules/alterar-senha/view/alterar-senha.view.html',
                controller: 'AlterarSenhaController',
                controllerAs: 'vm'
            })
    }
})();
