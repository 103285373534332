(function() {
    'use strict';

    angular.module('bunge')
        .factory('FOR_NAV_DYN_OPTIONS', FOR_NAV_DYN_OPTIONS);

    function FOR_NAV_DYN_OPTIONS() {
        return {
            contratos: [
                'app/modules/fornecedor/submodules/contratos/contratos.routes.js',
                'app/modules/fornecedor/submodules/contratos/services/contratos.services.js',
                'app/modules/fornecedor/submodules/contratos/controller/contratos.controller.js',
                'app/modules/fornecedor/submodules/contratos/modal-detalhe-contrato/modal-detalhe-contrato.directive.js',
            ],
            "conta-corrente": [
                'app/modules/fornecedor/submodules/conta-corrente/conta-corrente.routes.js',
                'app/modules/fornecedor/submodules/conta-corrente/controller/conta-corrente.controller.js',
                'app/modules/fornecedor/submodules/conta-corrente/factories/params.factory.js',
                'app/modules/fornecedor/submodules/conta-corrente/submodules/recebimentos/recebimentos.routes.js',
                'app/modules/fornecedor/submodules/conta-corrente/submodules/recebimentos/controller/recebimentos.controller.js',
                'app/modules/fornecedor/submodules/conta-corrente/submodules/recebimentos/modal-detalhe-recebimento/modal-detalhe-recebimento.directive.js',
                'app/modules/fornecedor/submodules/conta-corrente/submodules/nota-fiscal/nota-fiscal.routes.js',
                'app/modules/fornecedor/submodules/conta-corrente/submodules/nota-fiscal/controller/nota-fiscal.controller.js',
                'app/modules/fornecedor/submodules/conta-corrente/submodules/nota-fiscal/modals/modal-detalhe-nota.directive.js',
                'app/modules/fornecedor/submodules/conta-corrente/submodules/fixacao/fixacao.routes.js',
                'app/modules/fornecedor/submodules/conta-corrente/submodules/fixacao/controller/fixacao.controller.js',
                'app/modules/fornecedor/submodules/conta-corrente/submodules/pagamento/pagamento.routes.js',
                'app/modules/fornecedor/submodules/conta-corrente/submodules/pagamento/controller/pagamento.controller.js',
                'app/modules/fornecedor/submodules/conta-corrente/submodules/saldo-contrato/saldo-contrato.routes.js',
                'app/modules/fornecedor/submodules/conta-corrente/submodules/saldo-contrato/controller/saldo-contrato.controller.js',
                'app/modules/fornecedor/submodules/conta-corrente/submodules/saldo-contrato/submodules/resumido/resumido.routes.js',
                'app/modules/fornecedor/submodules/conta-corrente/submodules/saldo-contrato/submodules/resumido/controller/resumido.controller.js',
                'app/modules/fornecedor/submodules/conta-corrente/submodules/saldo-contrato/submodules/detalhado/detalhado.routes.js',
                'app/modules/fornecedor/submodules/conta-corrente/submodules/saldo-contrato/submodules/detalhado/controller/detalhado.controller.js'
            ],
            pagamentos: [
                'app/modules/fornecedor/submodules/pagamentos/pagamentos.routes.js',
                'app/modules/fornecedor/submodules/pagamentos/services/pagamentos.services.js',
                'app/modules/fornecedor/submodules/pagamentos/controller/pagamentos.controller.js'
            ],
            administracao: [
                'app/modules/fornecedor/submodules/administracao/administracao.routes.js',
                'app/modules/fornecedor/submodules/administracao/services/administracao.services.js',
                'app/modules/fornecedor/submodules/administracao/controller/administracao.controller.js'
            ]
        };
    }
})();