(function () {
    'use strict';

    angular.module('bunge.modules.configuracoes')
        .config(['$stateProvider', Config]);

    function Config($stateProvider) {
        $stateProvider
            .state('configuracoes', {
                abstract: true,
                url: '/configuracoes',
                templateUrl: 'app/modules/configuracoes/view/configuracoes.view.html',
                controller: 'ConfiguracoesController',
                controllerAs: 'conf'
            })
    }
})();
