(function() {
    'use strict';

    angular.module('bunge.modules.fornecedor.conta-corrente', [])
        .config(['$stateProvider', '$urlRouterProvider', ConfigParametros])
        .run(['$rootScope', '$location', '$state', RunParametros]);


    var $urlRouterProviderRef = null;
    var $stateProviderRef = null;

    function ConfigParametros($stateProvider, $urlRouterProvider) {
        $urlRouterProviderRef = $urlRouterProvider;
        $stateProviderRef = $stateProvider;
    }

    function RunParametros($rootScope, $location, $state) {
        $stateProviderRef.state('fornecedor.conta-corrente', {
            url: '/conta-corrente',
            params: {
                posicao: null,
                tipoConsulta: null,
                codigoFornecedor: null,
                codigoNucleo: null,
                codigoProduto: null,
                anoSafra: null,
                contrato: null,
                filtros: null,
                documentoFornecedor: null,
                documentoFazenda: null,
                contratosSafra: null
            },
            templateUrl: 'app/modules/fornecedor/submodules/conta-corrente/view/conta-corrente.view.html',
            controller: 'FornecedorContaCorrenteController',
            controllerAs: 'vm',
        });

        $rootScope.$on('$locationChangeSuccess', function() {
            if ($rootScope.previousLocation == $location.path() && $rootScope.filtrosContratos && $rootScope.actualLocation != '/fornecedor/contratos' && $rootScope.previousLocation == $rootScope.actualLocation) {
                var filtros = $rootScope.filtrosContratos;
                delete $rootScope.filtrosContratos;
                $state.go('fornecedor.contratos', {
                    filtros: filtros
                });
            }
            $rootScope.previousLocation = $rootScope.actualLocation;
            $rootScope.actualLocation = $location.path();
        });
    }
})();