(function () {
	'use strict';

	angular
	   .module('bunge.components')
	   .directive('navOptions', ['$rootScope', 'NavigationService', function ($rootScope, NavigationService) {
		   return {
			   restrict: 'EA',
			   templateUrl:'/app/components/navigation-options/navigation-options.template.html',
			   replace: true,
			   link: function (scope, element, attrs) {
				  var PATHNAME      = document.location.pathname;
				  var pathSplit     = PATHNAME.split("/");
				  var stateTopAtual = pathSplit[1];
			  
				  if ($rootScope.navPermissions.length == 0)
				      NavigationService.BuildUserMenu();

				  scope.stateTop = stateTopAtual;
				  scope.navPermissions = $rootScope.navPermissions;
				}
		   };
	   	}]);
})();

