(function () {
    'use strict';

    angular
        .module('bunge')
        .controller('MainController', MainController);

    MainController.$inject = ['$rootScope', 'SessionManagerFactory', 'USER_ROLES','$http', 'ENDPOINT_URI'];
    function MainController($rootScope, SessionManagerFactory, USER_ROLES, $http, ENDPOINT_URI) {
        
        $rootScope.currentUser = null;
        $rootScope.userRoles = USER_ROLES;
        $rootScope.navPermissions = [];
        $rootScope.isAuthorized = SessionManagerFactory.isAuthorized;

        $rootScope.setCurrentUser = function (user) {
            $rootScope.currentUser = user;
        };

        //
        // Este método terá de ficar aqui, já que por algum motivo arquitetural, não está sendo possível
        // injetar determinados services dentro desta e de oturas controllers.
        $rootScope.registerAccess = function(event, appName){
            if(typeof appName != 'undefined'){
                var model = null;
                var userID = $rootScope.currentUser.userId; 
                var navigation = $rootScope.navPermissions;

                event.preventDefault();

                function _redirect(){
                    window.location.href = event.currentTarget.href;
                }

                for(var navIndex in navigation){
                    var navItem = navigation[navIndex];

                    if(navItem.appName == appName) 
                        model = {publicKeyPortal: navItem.publicKey, idUser: userID};
                }


                if(!!model) {
                    $http.post(ENDPOINT_URI.endpoint_sts + 'access-log-register', model).then(_redirect, _redirect);  
                } 
                else {
                    _redirect();
                } 
            }
        }
    }
})();