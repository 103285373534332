(function () {
     'use strict';
     
angular.module('bunge.modules.primeiro-acesso')
    .config(['$stateProvider',ConfigPrimeiroAcesso]);

function ConfigPrimeiroAcesso($stateProvider) {
    $stateProvider
            .state('primeiro-acesso', {
                 url: '/primeiro-acesso',
                 templateUrl: 'app/modules/primeiro-acesso/view/primeiro-acesso.view.html',
                 controller: 'PrimeiroAcessoController',
                 controllerAs: 'vm'
            })
        .state('primeiro-acesso-chave', {
            url: '/primeiro-acesso/:chave',
            templateUrl: 'app/modules/primeiro-acesso/view/primeiro-acesso.view.html',
            controller: 'PrimeiroAcessoController',
            controllerAs: 'vm'
        })
}
})();
