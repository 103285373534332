(function () {

    angular
        .module('bunge.modules.fornecedor.conta-corrente.recebimentos')
        .controller('FornecedorContaCorrenteRecebimentosController', FornecedorContaCorrenteRecebimentosController);

    FornecedorContaCorrenteRecebimentosController.$inject = ['KendoDataSourceFactory', '$stateParams', 'HelperFactory', '$rootScope'];

    function FornecedorContaCorrenteRecebimentosController(KendoDataSourceFactory, $stateParams, HelperFactory, $rootScope) {

        var vm = this;

        vm.abrirDetalhe = function (dataItem) {
            vm.recebimentoDetalhe = [];
            vm.recebimentoDetalhe.push({
                header: {
                    "OData.CodigoFornecedor": $stateParams['codigoFornecedor'],
                    "OData.CodigoNucleo": $stateParams['codigoNucleo'],
                    "OData.CodigoProduto": $stateParams['codigoProduto'],
                    "OData.TipoConsultaContrato": $stateParams['tipoConsulta'],
                    "OData.Posicao": $stateParams['posicao'],
                    "OData.ContratoCompraMtp": $stateParams['contrato'] ? $stateParams['contrato'].ContratoCompraMTP : "",
                    "OData.CodigoEmpresa": $stateParams['contrato'] ? $stateParams['contrato'].CodigoEmpresa : "",
                    "OData.CodigoFilial": $stateParams['contrato'] ? $stateParams['contrato'].CodigoFilial : "",
                    "OData.AnoSafra": !$stateParams['anoSafra'] || $stateParams['anoSafra'] == "TODAS" ? -1 : $stateParams['anoSafra'],
                    "OData.CodigoFilialEntrega": dataItem.CodigoFilialEntrega,
                    "OData.ContratosSafra": JSON.stringify($stateParams['contratosSafra'])
                }
            });
        };

        vm.paramsGridOptions = function (dataItem) {
            var ds = KendoDataSourceFactory.iniciar({
                endpoint: 'ContratoRecebimentosGrid',
                fornecedor: true,
                pageable: false,
                pageSize: 10000,
                sort: { field: "NomeFilialEntrega", dir: "asc" },
                header: {
                    "OData.CodigoFornecedor": $stateParams['codigoFornecedor'],
                    "OData.CodigoNucleo": $stateParams['codigoNucleo'],
                    "OData.CodigoProduto": $stateParams['codigoProduto'],
                    "OData.TipoConsultaContrato": $stateParams['tipoConsulta'],
                    "OData.Posicao": $stateParams['posicao'],
                    "OData.ContratoCompraMtp": $stateParams['contrato'] ? $stateParams['contrato'].ContratoCompraMTP : "",
                    "OData.CodigoEmpresa": $stateParams['contrato'] ? $stateParams['contrato'].CodigoEmpresa : "",
                    "OData.CodigoFilial": $stateParams['contrato'] ? $stateParams['contrato'].CodigoFilial : "",
                    "OData.AnoSafra": !$stateParams['anoSafra'] || $stateParams['anoSafra'] == "TODAS" ? -1 : $stateParams['anoSafra'],
                    "OData.ContratosSafra": JSON.stringify($stateParams['contratosSafra'])
                }
            });

            ds.$adicionarCampoModelo("QuantidadeUmidadeDesconto", { type: "number" });
            ds.$adicionarCampoModelo("QuantidadeImpurezaDesconto", { type: "number" });
            ds.$adicionarCampoModelo("QuantidadeDeterioracaoDesconto", { type: "number" });
            ds.$adicionarCampoModelo("QuantidadeEsverdeadoDesconto", { type: "number" });
            ds.$adicionarCampoModelo("QuantidadePesoLiquidoDestino", { type: "number" });

            ds.$adicionarCampoAggregate("QuantidadeUmidadeDesconto", "sum");
            ds.$adicionarCampoAggregate("QuantidadeImpurezaDesconto", "sum");
            ds.$adicionarCampoAggregate("QuantidadeDeterioracaoDesconto", "sum");
            ds.$adicionarCampoAggregate("QuantidadeEsverdeadoDesconto", "sum");
            ds.$adicionarCampoAggregate("QuantidadePesoLiquidoDestino", "sum");

            ds.$adicionarCampoAggregate("ProporcaoUmidadeDesconto", "sum");
            ds.$adicionarCampoAggregate("ProporcaoImpurezaDesconto", "sum");
            ds.$adicionarCampoAggregate("ProporcaoDeterioracaoDesconto", "sum");
            ds.$adicionarCampoAggregate("ProporcaoEsverdeadoDesconto", "sum");

            return {
                resizable: true,
                filterable: true,
                sortable: true,
                columnMenu: true,
                dataSource: ds.$gerarDataSource(),
                dataBound: function (e) {
                    HelperFactory.mergeFooterTemplate(e, 0, 2);
                    HelperFactory.moveGridToolbarToFooter("#gridRecebimentos");
                },
                excel: {
                    allPages: false,
                    fileName: 'Recebimentos.csv'
                },
                toolbar: [{
                    name: 'excel',
                    template: "<kendo-button class='btn btn-default k-grid-excel'>Gerar Excel</kendo-button>"
                }],
                columns: [{
                    command: [{
                        template: '<button  title="Detalhes"   type="button" class="btn k-button k-button-icontext btn-default btn-circle" ng-click="vm.abrirDetalhe(dataItem)" >' + '<i class="k-icon k-i-search ob-icon-only"  ></i></button>'
                    }],
                    headerAttributes: { style: "text-align: center" },
                    attributes: { style: "text-align: center" },
                    title: "Ações",
                    footerTemplate: "TOTAL",
                    footerAttributes: { style: "text-align: center" }
                },
                {
                    title: "Local de Entrega",
                    field: 'NomeFilialEntrega',
                    headerAttributes: { style: "text-align: center" },
                    attributes: { style: "text-align: center" }
                }, {
                    title: "Peso Líquido Entregue (kg)",
                    field: 'QuantidadePesoLiquidoDestino',
                    aggregates: ["sum"],
                    headerAttributes: { style: "text-align: center" },
                    attributes: { style: "text-align: right" },
                    template: "#if(data.QuantidadePesoLiquidoDestino) {# #=kendo.toString (data.QuantidadePesoLiquidoDestino,'n0')# #}else{#0#}#",
                    footerTemplate: function (data) {
                        return data.QuantidadePesoLiquidoDestino && data.QuantidadePesoLiquidoDestino.sum ? kendo.toString(data.QuantidadePesoLiquidoDestino.sum, 'n0') : 0
                    },
                    footerAttributes: { style: "text-align: right" },
                }, {
                    title: "Umidade (kg)",
                    field: 'QuantidadeUmidadeDesconto',
                    aggregates: ["sum"],
                    headerAttributes: { style: "text-align: center" },
                    attributes: { style: "text-align: right" },
                    template: "#if(data.QuantidadeUmidadeDesconto) {# #=kendo.toString (data.QuantidadeUmidadeDesconto,'n0')# #}else{#0#}#",
                    footerTemplate: function (data) {
                        var media = data.ProporcaoUmidadeDesconto.sum / data.QuantidadePesoLiquidoDestino.sum;
                        return media ? kendo.toString(media, 'n2') : 0
                    },
                    footerAttributes: { style: "text-align: right" },
                }, {
                    title: "Impureza (kg)",
                    field: 'QuantidadeImpurezaDesconto',
                    aggregates: ["sum"],
                    headerAttributes: { style: "text-align: center" },
                    attributes: { style: "text-align: right" },
                    template: "#if(data.QuantidadeImpurezaDesconto) {# #=kendo.toString (data.QuantidadeImpurezaDesconto,'n0')# #}else{#0#}#",
                    footerTemplate: function (data) {
                        var media = data.ProporcaoImpurezaDesconto.sum / data.QuantidadePesoLiquidoDestino.sum;
                        return media ? kendo.toString(media, 'n2') : 0
                    },
                    footerAttributes: { style: "text-align: right" },
                }, {
                    title: "Deteriorado (kg)",
                    field: 'QuantidadeDeterioracaoDesconto',
                    aggregates: ["sum"],
                    headerAttributes: { style: "text-align: center" },
                    attributes: { style: "text-align: right" },
                    template: "#if(data.QuantidadeDeterioracaoDesconto) {# #=kendo.toString (data.QuantidadeDeterioracaoDesconto,'n0')# #}else{#0#}#",
                    footerTemplate: function (data) {
                        var media = data.ProporcaoDeterioracaoDesconto.sum / data.QuantidadePesoLiquidoDestino.sum;
                        return media ? kendo.toString(media, 'n2') : 0
                    },
                    footerAttributes: { style: "text-align: right" },
                },
                {
                    title: "Esverdeado (kg)",
                    field: 'QuantidadeEsverdeadoDesconto',
                    aggregates: ["sum"],
                    headerAttributes: { style: "text-align: center" },
                    attributes: { style: "text-align: right" },
                    template: "#if(data.QuantidadeEsverdeadoDesconto) {# #=kendo.toString (data.QuantidadeEsverdeadoDesconto,'n0')# #}else{#0#}#",
                    footerTemplate: function (data) {
                        var media = data.ProporcaoEsverdeadoDesconto.sum / data.QuantidadePesoLiquidoDestino.sum;
                        return media ? kendo.toString(media, 'n2') : 0
                    },
                    footerAttributes: { style: "text-align: right" },
                }
                ],
            }
        };

    }
})();