(function() {

    angular
        .module('bunge.modules.fornecedor.pagamentos')
        .controller('FornecedorPagamentosController', FornecedorPagamentosController);

    FornecedorPagamentosController.$inject = ['KendoDataSourceFactory', '$rootScope', 'FornecedorPagamentosService', '$q', '$scope', 'HelperFactory', 'Flash'];

    function FornecedorPagamentosController(KendoDataSourceFactory, $rootScope, FornecedorPagamentosService, $q, $scope, HelperFactory, Flash) {
        var vm = this;
        vm.ehUsuarioExterno = function() {
            if (!$rootScope.currentUser) {
                return true;
            }
            return $rootScope.currentUser.userRole.toUpperCase() == "EXTERNO";
        };

        vm.init = function() {
            if (vm.ehUsuarioExterno()) {
                vm.fornecedorSelecionado = { Codigo: $rootScope.currentUser.userName };
                vm.tipoConsulta = "1";
                recarregarCombos();
            }
        };

        vm.init();

        var dataSourceFornecedor = KendoDataSourceFactory.iniciar({ endpoint: 'Fornecedor', fornecedor: true, sort: { field: "CodigoFornecedor", dir: "desc" } });
        dataSourceFornecedor.$adicionarFiltro('Fazenda', 'equal', '1');
        vm.usuariosDataSource = {
            filter: "contains",
            minLength: 3,
            dataTextField: 'DescricaoFornecedor',
            dataSource: dataSourceFornecedor.$gerarDataSource(),
            autoBind: false,
            change: comboUserOnChange
        };

        vm.regionalDataSource = {
            filter: "contains",
            minLength: 3,
            dataTextField: 'nome',
            change: regionalOnChange
        };

        vm.safraDataSource = {
            change: safraOnChange
        };

        function comboUserOnChange() {
            limparRegional();
            limparSafra();
            vm.fornecedorSelecionado = this.dataItem(this.select());
            if (vm.fornecedorSelecionado) {
                recarregarCombos();
            }
            $scope.$evalAsync();
        }

        function limparTabela() {
            if (vm.grid) {
                vm.grid.dataSource.data([]);
            }
        }

        function limparRegional() {
            vm.regional.dataSource.data([]);
            vm.regionalSelecionado = undefined;
            vm.regional.value('');
        }

        function limparSafra() {
            vm.safraSelecionado = undefined;
            vm.safra.dataSource.data([]);
            vm.safra.value('');
        }

        function recarregarCombos() {
            if (vm.fornecedorSelecionado) {
                var safra = (!vm.safraSelecionado || vm.safraSelecionado == 'TODOS') ? 0 : vm.safraSelecionado;
                var regional = !vm.regionalSelecionado ? "null" : vm.regionalSelecionado.codigo;
                var promises = [
                    FornecedorPagamentosService.getRegionalData(vm.fornecedorSelecionado.CodigoFornecedor, safra),
                    FornecedorPagamentosService.getAnoSafraData(vm.fornecedorSelecionado.CodigoFornecedor, regional)
                ];

                $q.all(promises).then(function(values) {
                    var retornoRegional = values[0].data;
                    retornoRegional.unshift({ nome: "TODOS", codigo: "null" });
                    vm.regional.setDataSource({ data: retornoRegional });

                    if (vm.regionalSelecionado) {
                        vm.regional.select(function(dataItem) {
                            return dataItem.codigo == vm.regionalSelecionado.codigo;
                        });
                    }
                    vm.regionalSelecionado = vm.regional.dataItem();

                    var retornoAnoSafra = values[1].data;
                    retornoAnoSafra.unshift("TODAS");
                    vm.safra.setDataSource({ data: retornoAnoSafra });

                    if (vm.regionalSelecionado) {
                        vm.safra.select(function(dataItem) {
                            return dataItem == vm.safraSelecionado;
                        });
                    }
                    vm.safraSelecionado = vm.safra.dataItem();
                });
            }
        }

        function regionalOnChange() {
            vm.regionalSelecionado = this.dataItem(this.select());
            recarregarCombos();
            $scope.$evalAsync();
        }

        function safraOnChange() {
            vm.safraSelecionado = this.dataItem(this.select());
            recarregarCombos();
            $scope.$evalAsync();
        }

        vm.paramsGridOptions = function() {
            return {
                resizable: true,
                filterable: true,
                sortable: true,
                columnMenu: true,
                excel: {
                    allPages: false,
                    fileName: 'Pagamentos.csv'
                },
                toolbar: [{
                    name: 'excel',
                    template: "<kendo-button class='btn btn-default k-grid-excel'>Gerar Excel</kendo-button>"
                }],
                dataBound: function(e) {
                    HelperFactory.mergeFooterTemplate(e, 0, 8);
                    if (!$('.k-toolbar-footer').length) {
                        $('#gridPagamentos').find(".k-grid-toolbar").addClass('k-toolbar-footer').insertAfter($('#gridPagamentos'));
                    } else {
                        $('.k-grid-toolbar').not('.k-toolbar-footer').remove();
                    }

                    var tfooter = e.sender.footer[0];
                    var trFooterClone = e.sender.footer[0].childNodes[0].cloneNode(true);
                    var firstDataItem = e.sender._data[0];
                    trFooterClone.childNodes[0].innerHTML = 'TOTAL US$';
                    trFooterClone.childNodes[3].innerHTML = '';

                    try {
                        ValorProgramadoDolar = e.sender.dataSource._aggregateResult.ValorProgramadoDolar.sum;
                        trFooterClone.childNodes[1].innerHTML = kendo.toString(ValorProgramadoDolar, 'n2');
                    } catch (error) {
                        trFooterClone.childNodes[1].innerHTML = '0';
                    }
                    tfooter.append(trFooterClone);
                },
                columns: [{
                    title: 'Situação',
                    field: 'Situacao',
                    footerAttributes: { style: "text-align: center" },
                    footerTemplate: "TOTAL R$"
                }, {
                    title: 'Filial',
                    field: 'NomeFilial'
                }, {
                    title: 'CPNJ Fornecedor',
                    field: 'DocumentoFornecedor ',
                    headerAttributes: { style: 'text-align: center' },
                    attributes: { style: 'text-align: center' },
                }, {
                    title: 'Nome Fornecedor',
                    field: 'NomeFornecedor'
                }, {
                    title: 'Contrato',
                    field: 'ContratoCompraMTP',
                    headerAttributes: { style: "text-align: center" },
                    attributes: { style: "text-align: center" },
                }, {
                    title: 'Nr. Pagamento',
                    field: 'NumeroPagamento',
                    headerAttributes: { style: "text-align: center" },
                    attributes: { style: "text-align: center" }
                }, {
                    title: 'Data Pagamento',
                    field: 'DataPagamento',
                    headerAttributes: { style: "text-align: center" },
                    attributes: { style: "text-align: center" },
                    template: '#= kendo.toString(kendo.parseDate(data.DataPagamento,"yyyy-MM-ddTHH:mm:ss"),"dd/MM/yyyy")#'
                }, {
                    title: "Moeda",
                    field: 'MoedaProgramado',
                    attributes: { style: 'text-align: center' }
                }, {
                    title: "Valor da Moeda",
                    field: 'ValorProgramado',
                    headerAttributes: { style: "text-align: center" },
                    attributes: { style: "text-align: right" },
                    template: "#if(data.ValorProgramado) {# #=kendo.toString(data.ValorProgramado, 'n2')# #}else{#0#}#",
                    footerAttributes: { style: "text-align: right" },
                    footerTemplate: function(data) {
                        return data.ValorProgramadoReal && data.ValorProgramadoReal.sum ? kendo.toString(data.ValorProgramadoReal.sum, 'n2') : 0
                    }
                }, {
                    title: 'Cotação US$',
                    field: 'ValorCotacao',
                    headerAttributes: { style: "text-align: center" },
                    attributes: { style: "text-align: right" },
                    template: "#if(data.ValorCotacao) {# #=kendo.toString (data.ValorCotacao,'n3')# #}else{#0#}#"
                }, {
                    title: "Valor Pagamento R$",
                    field: 'ValorPago',
                    aggregates: ["sum"],
                    headerAttributes: { style: "text-align: center" },
                    attributes: { style: "text-align: right" },
                    template: "#if(data.ValorPago) {# #=kendo.toString(data.ValorPago, 'n2')# #}else{#0#}#",
                    footerAttributes: { style: "text-align: right" },
                    footerTemplate: function(data) {
                        return data.ValorPago && data.ValorPago.sum ? kendo.toString(data.ValorPago.sum, 'n2') : 0
                    }
                }, {
                    title: 'Favorecido',
                    field: 'NomeFavorecido'
                }]
            }
        }

        vm.filtrar = function() {
            var ds = KendoDataSourceFactory.iniciar({
                endpoint: 'PagamentoTerceiro',
                fornecedor: true,
                pageable: false,
                pageSize: 999,
                sort: { field: "NomeNucleo", dir: "desc" },
                header: {
                    "OData.CodigoFornecedor": vm.fornecedorSelecionado ? vm.fornecedorSelecionado.CodigoFornecedor : "null",
                    "OData.CodigoNucleo": vm.regionalSelecionado ? vm.regionalSelecionado.codigo : undefined,
                    "OData.AnoSafra": (!vm.safraSelecionado || vm.safraSelecionado == 'TODAS') ? undefined : vm.safraSelecionado
                }
            });

            if (vm.dataInicio) {
                ds.$adicionarFiltro('DataPagamento', 'ge', vm.dataInicio);
            }

            if (vm.dataFim) {
                ds.$adicionarFiltro('DataPagamento', 'le', vm.dataFim);
            }

            if (vm.dataInicio && vm.dataFim) {
                if (vm.dataInicio.getTime() > vm.dataFim.getTime()) {
                    Flash.create('danger', "Data inicial não pode ser maior que a data final");
                    return;
                }
            }

            ds.$adicionarCampoModelo("DataPagamento", { type: "date" });
            ds.$adicionarCampoModelo("ValorPago", { type: "number" });
            ds.$adicionarCampoModelo("ValorCotacao", { type: "number" });
            ds.$adicionarCampoModelo("ValorProgramado", { type: "number" });
            ds.$adicionarCampoModelo("ValorProgramadoReal", { type: "number" });
            ds.$adicionarCampoModelo("ValorProgramadoDolar", { type: "number" });

            ds.$adicionarCampoAggregate("ValorPago", "sum");
            ds.$adicionarCampoAggregate("ValorProgramadoReal", "sum");
            ds.$adicionarCampoAggregate("ValorProgramadoDolar", "sum");
            vm.grid.setDataSource(ds.$gerarDataSource());
        };

        vm.limpar = function() {
            limparRegional();
            limparSafra();
            limparTabela();
            limparFornecedor();
            limparDatas();
            vm.form.$submitted = false;
        };

        function limparFornecedor() {
            vm.fornecedorSelecionado = undefined;
            vm.fornecedores.value('');
            vm.fornecedores.text('');
        }

        function limparDatas() {
            vm.dataInicio = undefined;
            vm.dataFim = undefined;
        }
    }
})();