(function () {
    'use strict';

    angular
       .module('bunge.components')
       .directive('admMobileOptions', ['Session', '$state', function ($rootScope, $state) {
           return {
               restrict: 'EA',
               templateUrl: '/app/components/administration-mobile-options/administration-mobile-options.template.html',
               replace: true,
               link: function (scope, element, attrs) {
                   scope.options = $rootScope.admPermissions;
                   var getIndexActive = function () {
                       var z = 0;
                       for (var i in scope.options) {
                           if ($state.current.name == 'administracao' + scope.options[i].route) {
                               return z;
                           }
                           z++;
                       }
                       return 0;
                   };
                   scope.toggleObject = { item: getIndexActive() };
               }
           };
       }]);
})();

