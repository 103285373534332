(function() {

    angular
        .module('bunge.modules.fornecedor.conta-corrente.saldo-contrato')
        .controller('FornecedorContaCorrenteSaldoContratoController', FornecedorContaCorrenteSaldoContratoController);

    FornecedorContaCorrenteSaldoContratoController.$inject = ['$state', '$stateParams', 'FOR_SALDO_TABS_CONSTANT'];

    function FornecedorContaCorrenteSaldoContratoController($state, $stateParams, FOR_SALDO_TABS_CONSTANT) {
        var vm = this;
        vm.tabData = [];
        vm.loadTabs = function() {
            for (var item in FOR_SALDO_TABS_CONSTANT) {
                var tabItem = FOR_SALDO_TABS_CONSTANT[item];

                var model = {
                    heading: '<p>' + tabItem.legenda + '</p>',
                    route: tabItem.route
                };
                vm.tabData.push(model);
            }
            $state.go(FOR_SALDO_TABS_CONSTANT[0].route, $stateParams);
        }
        vm.loadTabs();
    }
})();