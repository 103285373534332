(function () {

    'use strict';

    angular
        .module('bunge')
        .factory('VisibilidadeMenuLateralFactory', VisibilidadeMenuLateralFactory);

    function VisibilidadeMenuLateralFactory() {

        return {
            getVisivel: getVisivel,
            setVisivel: setVisivel
        };

        var visivel;

        function getVisivel() {
            if (visivel == undefined) {
                visivel = true;
            }
            return visivel;
        };

        function setVisivel(valor) {
            visivel = valor;
        };
    }
})();