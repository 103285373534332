(function() {
    'use strict';

    angular.module('bunge.modules.fornecedor.pagamentos')
        .service('FornecedorPagamentosService', FornecedorPagamentosService);

    FornecedorPagamentosService.$inject = ['ENDPOINT_URI', '$http', 'KendoDataSourceFactory'];

    function FornecedorPagamentosService(ENDPOINT_URI, $http, KendoDataSourceFactory) {
        var FornecedorPagamentosService = function() {
            this.getRegionalData = function(idFornecedor, anoSafra) {
                return $http.get(ENDPOINT_URI.endpoint_fornecedorReq + 'pagamentos/regional/' + idFornecedor + '/' + anoSafra);
            };
            this.getAnoSafraData = function(idFornecedor, idRegional) {
                return $http.get(ENDPOINT_URI.endpoint_fornecedorReq + 'pagamentos/safra/' + idFornecedor + '/' + idRegional);
            };
        };
        return new FornecedorPagamentosService();
    }
}());