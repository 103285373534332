if (window.location.pathname !== '/') {
    window.location.href = '/#' + window.location.pathname;
}

(function () {
    'use strict';

    angular.module('bunge.modules.home').controller('HomeController', HomeController);

    HomeController.$inject = ['$rootScope', 'AuthenticationService', 'NavigationService', 'ViewService'];
    function HomeController($rootScope, AuthenticationService,  NavigationService, ViewService) {
        var vm = this;

        $rootScope.currentApp = ViewService.GetViewPublicKey('administracao');

        vm.logoff = function () {
            AuthenticationService.ClearCredentials();
        };
        
        function initController() {
            if($rootScope.navPermissions.length == 0)
                NavigationService.BuildUserMenu();

            var navPerm = [];
            angular.forEach($rootScope.navPermissions, function (value, key) {
                if (!value.dontShowHome) {
                    navPerm.push(value);
                }
            });
            vm.navPermissions = navPerm;
        }

        initController();
    }
})();