(function() {
    'use strict';

    angular.module('bunge')
        .factory('HelperFactory', ['ngDialog', 'Flash', function(ngDialog, Flash) {
            var HelperFactory = function() {
                var helper = this;

                this.validate = function(form, name, type, value, uiSelect) {
                    if (uiSelect)
                        return this.validateUiSelect(form, name, value);

                    if (form.$submitted) form[name].$setTouched(true);
                    return (form[name].$touched) && form[name].$error[type];
                };

                this.getAdminGridToolbarNoPermission = function() {
                    var gridToolbar = [{
                        name: "create",
                        template: "<kendo-button class='btn btn-default k-grid-add' " +
                            "ng-model='vm.btnNew'>Novo</kendo-button>"
                    }];

                    return gridToolbar;
                };

                this.getAdminGridToolbar = function(page, functionality) {
                    var gridToolbar = [{
                        name: "create",
                        template: "<kendo-button class='btn btn-default k-grid-add' " +
                            "permission='[\"" + page + "\", \"" + functionality + "\"]' ng-model='vm.btnNew'>Novo</kendo-button>"
                    }];

                    return gridToolbar;
                };

                this.getAdminDeleteButtonTemplateNoPermission = function() {
                    var template = '<button type="button" class="btn k-button k-button-icontext btn-danger btn-delete ' +
                        'btn-circle" ng-click="vm.excluir(dataItem)" ' +
                        ' title="Excluir"><i class="glyphicon glyphicon-remove"></i></button>';

                    return template;
                }


                this.getAdminDeleteButtonTemplate = function(page, functionality) {
                    var template = '<button type="button" class="btn k-button k-button-icontext btn-danger btn-delete ' +
                        'btn-circle" ng-click="vm.excluir(dataItem)" permission="[\'' + page + '\', \'' + functionality + '\']" ' +
                        ' title="Excluir"><i class="glyphicon glyphicon-remove"></i></button>';
                    return template;
                }

                this.getAdminEditButtonTemplate = function(page, functionality) {
                    var template = '<a href="javascript:;" title="Editar" permission="[\'' + page + '\', \'' + functionality + '\']" class="k-button k-button-icontext k-grid-edit"><span class="k-icon k-edit"></span></a>' +
                        '<a class="k-button k-button-icontext k-grid-cancel" href="javascript:;" title="Cancelar"><span class="k-icon k-cancel"></span> </a>';

                    return template;
                }

                this.setKendoTitle = function(elm, cssItem, label) {
                    elm.sender.element.find(cssItem).attr('title', label);
                };

                this.validateUiSelect = function(form, name, value) {
                    if (form.$submitted && !value) {
                        form[name].$setValidity(name, false);
                        return true;
                    }
                    form[name].$setValidity(name, true);
                    return false;
                };

                this.gerarId = function(len) {
                    var chars = "0123456789";
                    var string_length = len;
                    var randomstring = '';

                    for (var x = 0; x < string_length; x++) {

                        var letterOrNumber = Math.floor(Math.random() * 2);
                        if (letterOrNumber == 0) {
                            var newNum = Math.floor(Math.random() * 9);
                            randomstring += newNum;
                        } else {
                            var rnum = Math.floor(Math.random() * chars.length);
                            randomstring += chars.substring(rnum, rnum + 1);
                        }

                    }
                    return randomstring;
                }

                this.listaParaObjeto = function(list, idAttr, parentAttr, childrenAttr) {
                    if (!idAttr) idAttr = 'id';
                    if (!parentAttr) parentAttr = 'parentId';
                    if (!childrenAttr) childrenAttr = 'children';
                    var treeList = [];
                    var lookup = {};
                    list.forEach(function(obj) {
                        obj['MetaName'] = obj.Name;
                        lookup[obj[idAttr]] = obj;
                        obj[childrenAttr] = [];
                    });
                    list.forEach(function(obj) {
                        delete obj[idAttr];
                        var parentId = obj[parentAttr];
                        delete obj[parentAttr];
                        if (parentId != null) {
                            obj['MetaName'] = lookup[parentId]['MetaName'] + '_' + obj['MetaName'];
                            lookup[parentId][childrenAttr].push(obj);
                        } else {
                            treeList.push(obj);
                        }
                    });
                    return treeList;
                };

                this.objetoParaLista = function(treeObj, atributos, dePara, idAttr, parentAttr, childrenAttr, levelAttr) {
                    if (!idAttr) idAttr = 'id';
                    if (!parentAttr) parentAttr = 'parentId';
                    if (!childrenAttr) childrenAttr = 'children';
                    if (!levelAttr) levelAttr = 'level';
                    var listaAtributosParaInserir = [];
                    var mostrarAtributos = atributos == 'comAtributos';

                    function gerarIdsLayout(list, parentId, level) {


                        list.forEach(function(obj, index) {
                            obj[idAttr] = helper.gerarId(10);
                            obj[parentAttr] = parentId ? parentId : null;
                            obj[childrenAttr] = gerarIdsLayout(obj[childrenAttr], obj[idAttr], level + 1);

                            if (dePara) {
                                obj[levelAttr] = level;
                                obj[childrenAttr] != '' ? obj['hasChilds'] = true : obj['hasChilds'] = false;
                                obj['link'] = '';
                            }
                            list[index] = obj;
                        });


                        return list;
                    }


                    function resgataAtributosComValor(obj) {
                        var listaAtributosComValor = [];
                        var metaName = obj['MetaName'];
                        if (obj['Attributes']) {
                            var attributes = obj['Attributes'];
                            var abriu = false;
                            var numeroItem = 0;
                            angular.forEach(attributes, function(item, key) {

                                if (item == "[") {
                                    abriu = true;
                                    numeroItem++;
                                    listaAtributosComValor[numeroItem] = '';
                                }

                                if (item == "]") {
                                    abriu = false;

                                }

                                if (item != "[" && item != "]" && abriu) {
                                    listaAtributosComValor[numeroItem] += item;

                                }

                            });

                            return listaAtributosComValor;
                        }
                    }

                    function flattenChild(childObj, parentId, level) {
                        var array = [];
                        //resgataAtributosComValor(childObj);
                        var childCopy = angular.extend({}, childObj);
                        childCopy[parentAttr] = parentId;
                        childCopy['ehAtributoValor'] = false;
                        delete childCopy[childrenAttr];
                        array.push(childCopy);



                        var atributosComValor = resgataAtributosComValor(childObj);
                        if (atributosComValor != null && mostrarAtributos) {
                            //console.log(atributosComValor);

                            atributosComValor.forEach(function(atributo) {
                                var childCopy = angular.extend({}, childObj);
                                childCopy['id'] = helper.gerarId(10);
                                childCopy['Name'] = atributo;
                                childCopy['MetaName'] = childObj['MetaName'] + "@" + atributo;
                                childCopy['ehAtributoValor'] = true;
                                array.push(childCopy);
                                //ray = array.concat(flattenChild(childObj, obj[idAttr], level+1));

                            });


                        }
                        array = array.concat(processChildren(childObj, level));

                        return array;
                    };

                    function processChildren(obj, level) {
                        if (!level) level = 0;
                        var array = [];

                        obj[childrenAttr].forEach(function(childObj) {
                            array = array.concat(flattenChild(childObj, obj[idAttr], level + 1));
                        });

                        return array;
                    };

                    function process(obj, level) {
                        if (!level) level = 0;
                        var array = [];
                        obj.forEach(function(childObj) {
                            array = array.concat(flattenChild(childObj, null, level + 1));

                        });
                        return array;
                    };

                    treeObj = gerarIdsLayout(treeObj, false, 0);

                    var result = process(treeObj);

                    return result;
                };

                this.alerta = function(mensagem) {
                    var template = '<div class="alert-dialog-container"> ' +
                        '<div class="alert-dialog-body"> ' +
                        '<p>' +
                        mensagem +
                        '</p> ' +
                        '</div>' +
                        '<div class="alert-dialog-footer"> ' +
                        '<button class="btn btn-primary" ng-click="closeThisDialog()">OK</button> ' +
                        '</div> ' +
                        '</div>';
                    ngDialog.open({
                        template: template,
                        plain: true,
                    });
                };

                this.alertaConfirma = function(mensagem) {
                    var template = '<div class="alert-dialog-container"> ' +
                        '<div class="alert-dialog-body"> ' +
                        '<p>' +
                        mensagem +
                        '</p> ' +
                        '</div>' +
                        '<div class="alert-dialog-footer"> ' +
                        '<button class="btn btn-default" ng-click="confirm(0); closeThisDialog()">Não</button> ' +
                        '<button class="btn btn-primary" ng-click="confirm(1)">Sim</button> ' +
                        '</div> ' +
                        '</div>';
                    return ngDialog.openConfirm({
                        template: template,
                        plain: true,
                    });
                };


                this.alertaConfirmaStateChangeAlert = function() {
                    var mensagem = 'Todas as alterações não salvas serão perdidas. Tem certeza que deseja fazer isso?';
                    var template = '<div class="alert-dialog-container"> ' +
                        '<div class="alert-dialog-body"> ' +
                        '<p>' +
                        mensagem +
                        '</p> ' +
                        '</div>' +
                        '<div class="alert-dialog-footer"> ' +
                        '<button class="btn btn-default" ng-click="closeThisDialog()">Não</button> ' +
                        '<button class="btn btn-primary" ng-click="confirm(1)">Sim</button> ' +
                        '</div> ' +
                        '</div>';
                    return ngDialog.openConfirm({
                        template: template,
                        plain: true,
                    });
                };

                this.getErrorMessage = function(data) {
                    var mensage = angular.isDefined(data[0]) ? '<strong>' + data[0].campo + '</strong> ' + data[0].mensagem : data.message ? data.message : data.Message;
                    Flash.create('danger', mensage);
                };

                this.mergeFooterTemplate = function(event, indexColSpan, size) {
                    var tds = event.sender.footer[0].childNodes[0].childNodes;
                    mergeFooterTemplate(event, indexColSpan, size, tds);

                }

                this.mergeSubFooterTemplate = function(event, indexColSpan, size) {
                    var tds;
                    for (var i = 0; i < event.sender.tbody[0].querySelectorAll(".k-group-footer").length; i++) {
                        tds = event.sender.tbody[0].querySelectorAll(".k-group-footer")[i].childNodes;
                        mergeFooterTemplate(event, indexColSpan, size, tds);
                    }
                }

                function mergeFooterTemplate(event, indexColSpan, size, tds) {
                    var tdColSpan = tds[indexColSpan];
                    tdColSpan.setAttribute("colspan", size);

                    if (event.sender.thead[0].childNodes) {
                        var columns = event.sender.thead[0].childNodes[event.sender.thead[0].childNodes.length - 1];
                        var columnsSize = columns.childNodes.length - (size - 1);
                        if (tds.length > columnsSize) {
                            for (var i = 1; i < size; i++) {
                                $(tds[indexColSpan + i]).remove();
                                indexColSpan--;
                            }
                        }
                    }


                }

                this.moveGridToolbarToFooter = function(id) {
                    $(id).find(".k-grid-toolbar").addClass('k-toolbar-footer').insertAfter($(id));
                }
            };
            return new HelperFactory();
        }]);


}());