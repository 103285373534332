(function () {
    'use strict';

    angular.module('bunge.modules.fornecedor.contratos')
        .service('FornecedorContratosService', FornecedorContratosService);

    FornecedorContratosService.$inject = ['ENDPOINT_URI', '$http', 'KendoDataSourceFactory'];

    function FornecedorContratosService(ENDPOINT_URI, $http, KendoDataSourceFactory) {
        var FornecedorContratosService = function () {
            this.getRegionalData = function (idFornecedor, documentoFazenda, idRegional, idProduto) {
                return $http.get(ENDPOINT_URI.endpoint_fornecedorReq + 'contratos/regional/' + idFornecedor + '/' + documentoFazenda + '/' + idRegional + '/' + idProduto);
            };
            this.getProdutoData = function (idFornecedor, documentoFazenda, idRegional, anoSafra) {
                return $http.get(ENDPOINT_URI.endpoint_fornecedorReq + 'contratos/produtos/' + idFornecedor + '/' + documentoFazenda + '/' + idRegional + '/' + anoSafra);
            };
            this.getAnoSafraData = function (idFornecedor, documentoFazenda, idRegional, idProduto) {
                return $http.get(ENDPOINT_URI.endpoint_fornecedorReq + 'contratos/safra/' + idFornecedor + '/' + documentoFazenda + '/' + idRegional + '/' + idProduto);
            };
            this.getMensagemContaCorrente = function () {
                return $http.get(ENDPOINT_URI.endpoint_fornecedorReq + 'conta-corrente/mensagem-conta-corrente');
            };
            this.getFazendas = function(documentoFornecedor, fazenda) {
                if (!fazenda) fazenda = "";
                documentoFornecedor = documentoFornecedor.substring(0, 8);
                return $http.get(ENDPOINT_URI.endpoint_fornecedorReq + 'oData/Fornecedor?$top=10&$orderby=CodigoFornecedor%20desc&$filter=((Fazenda%20eq%20%272%27)%20and%20((startswith(Documento,%27' + documentoFornecedor + '%27)%20and%20contains(tolower(DescricaoFornecedor),%27' + fazenda + '%27))))&$count=true');
            }
        };

        return new FornecedorContratosService();
    }
}());