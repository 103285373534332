(function() {
    'use strict';

    angular.module('bunge.modules.fornecedor.adm')
        .service('FornecedorAdministracaoService', FornecedorAdministracaoService);

    FornecedorAdministracaoService.$inject = ['ENDPOINT_URI', '$http', 'KendoDataSourceFactory'];

    function FornecedorAdministracaoService(ENDPOINT_URI, $http, KendoDataSourceFactory) {
        var FornecedorAdministracaoService = function() {

            this.addRelacaoFornecedor = function(codigoFornecedorOrigem, codigoFornecedorDestino) {
                return $http.post(ENDPOINT_URI.endpoint_fornecedorReq + 'relacaoFornecedor/adicionar/' + codigoFornecedorOrigem + '/' + codigoFornecedorDestino);
            };

            this.removeRelacaoFornecedor = function(codigoRelacaoFornecedor) {
                return $http.delete(ENDPOINT_URI.endpoint_fornecedorReq + 'relacaoFornecedor/remover/' + codigoRelacaoFornecedor);
            };

            this.getFornecedores = function(fornecedor) {
                if (typeof fornecedor == 'undefined' || !fornecedor)
                    return [];
                else {
                    return KendoDataSourceFactory.iniciar({ endpoint: 'AppOData', pageSize: 30, STS: true })
                        .$adicionarIdModelo('Id')
                        .$adicionarFiltro('IdPortal', "eq", fornecedor.CodigoFornecedor)
                        .$adicionarCampoModelo("NmApp", { type: "string" })
                        .$adicionarCampoModelo("PublicKey", { type: "string" })
                        .$adicionarCampoModelo("Description", { type: "string" })
                        .$gerarDataSource();
                }


            }

        };
        return new FornecedorAdministracaoService();
    }
}());