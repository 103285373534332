(function() {
    'use strict';

    angular.module('bunge.modules.fornecedor.conta-corrente.pagamento', [])
        .config(['$stateProvider', '$urlRouterProvider', ConfigParametros])
        .run([RunParametros]);


    var $urlRouterProviderRef = null;
    var $stateProviderRef = null;

    function ConfigParametros($stateProvider, $urlRouterProvider) {
        $urlRouterProviderRef = $urlRouterProvider;
        $stateProviderRef = $stateProvider;
    }

    function RunParametros() {
        $stateProviderRef.state('fornecedor.conta-corrente.pagamento', {
            url: '/pagamento',
            params: {
                'contrato': null,
                'codigoFornecedor': null,
                'codigoNucleo': null,
                'codigoProduto': null,
                'anoSafra': null
            },
            templateUrl: 'app/modules/fornecedor/submodules/conta-corrente/submodules/pagamento/view/pagamento.view.html',
            controller: 'FornecedorContaCorrentePagamentoController',
            controllerAs: 'vm',
        });
    }
})();