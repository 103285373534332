(function () {

    'use strict';

    angular
        .module('bunge.modules.alterar-senha')
        .service('ResetPasswordService', ResetPasswordService);

    ResetPasswordService.$inject = ['ENDPOINT_URI', '$resource'];
    function ResetPasswordService(ENDPOINT_URI, $resource) {

        var Resource = $resource(ENDPOINT_URI.endpoint_sts + 'reset-password', {}, {
            resetPassword: {
                url: ENDPOINT_URI.endpoint_sts + 'reset-password',
                method: 'PUT'
            }
        });

        function resetPassword(data) {
            var resource = new Resource();
            resource.Login = data.Login;
            resource.Password = data.Password;
            resource.NewPassword = data.NewPassword;
            return resource.$resetPassword();
        }

        return {
            resetPassword: resetPassword
        };
    }
})();