(function () {

    'use strict';

    angular
        .module('bunge.system')
        .factory('ModalUsuarioExternoService', ModalUsuarioExternoService);

    ModalUsuarioExternoService.$inject = ['$resource', 'ENDPOINT_URI'];
    function ModalUsuarioExternoService($resource, ENDPOINT_URI) {
        var MyResource = $resource(ENDPOINT_URI.endpoint_backendReq + 'update-user-status', {}, {
            atualizarStatusEmail: {
                url: ENDPOINT_URI.endpoint_backendReq + 'fornecedor/atualizar-status-email',
                method: 'PUT',
            },
        });

        function atualizarStatusEmail(data) {
            var myResource = new MyResource();
            myResource.Documento = data.Documento;
            myResource.Email = data.Email;
            myResource.Status = data.Status;
            return myResource.$atualizarStatusEmail();
        }

        return {
            atualizarStatusEmail: atualizarStatusEmail,
        };
    }
})();