(function () {
    'use strict';

    angular.module('bunge').factory('NAV_OPTIONS', NAV_OPTIONS);

    NAV_OPTIONS.$inject = ['USER_ROLES', 'ENDPOINT_URI'];

    function NAV_OPTIONS(USER_ROLES, ENDPOINT_URI) {
        return {
            inicio: { legenda: 'Início', route: 'home', isRootMenu: true, isExternalApp: false, publicKey: null, dontShowHome: true },
            administrador: { legenda: 'Administração', route: 'administracao', isRootMenu: false, appName: 'administracao', isExternalApp: false, publicKey: 'PADM', imgFileName: 'images/administrador.png'},
            logistico: { legenda: 'Logístico', route: ENDPOINT_URI.endpoint_logistico, isRootMenu: false, appName: 'logistico', isExternalApp: true, publicKey: 'PLGS', imgFileName: 'images/logistico.png'},
            fornecedor: { legenda: 'Fornecedor', route: 'fornecedor', isRootMenu: false, appName: 'fornecedor', isExternalApp: false, publicKey: 'PFOR', imgFileName: 'images/fornecedor.png'}
        };
    }
})();