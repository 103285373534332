(function() {

    angular
        .module('bunge.modules.fornecedor.conta-corrente.fixacao')
        .controller('FornecedorContaCorrenteFixacaoController', FornecedorContaCorrenteFixacaoController);

    FornecedorContaCorrenteFixacaoController.$inject = ['KendoDataSourceFactory', '$stateParams', 'ContaCorrenteParamsFactory', 'HelperFactory'];

    function FornecedorContaCorrenteFixacaoController(KendoDataSourceFactory, $stateParams, ContaCorrenteParamsFactory, HelperFactory) {

        var vm = this;

        vm.paramsGridOptions = function(dataItem) {
            var dataSourceParams = {
                endpoint: 'ContratoFixacao',
                fornecedor: true,
                pageable: false,
                pageSize: 999,
                sort: {
                    field: "ContratoCompraMTP",
                    dir: "asc"
                },
                header: ContaCorrenteParamsFactory.getHeader($stateParams)
            }

            var ds = KendoDataSourceFactory.iniciar(dataSourceParams);
            ds.$adicionarCampoModelo("DataFixacao", { type: "date" });
            ds.$adicionarCampoModelo("NumeroFixacao", { type: "number" });
            ds.$adicionarCampoModelo("QuantidadeFixada", { type: "number" });
            ds.$adicionarCampoModelo("QuantidadeFixadaReal", { type: "number" });
            ds.$adicionarCampoModelo("QuantidadeFixadaDolar", { type: "number" });
            ds.$adicionarCampoModelo("Preco", { type: "number" });
            ds.$adicionarCampoModelo("ValorFixacao", { type: "number" });
            ds.$adicionarCampoModelo("ValorImposto", { type: "number" });
            ds.$adicionarCampoModelo("ValorAtencipado", { type: "number" });
            ds.$adicionarCampoModelo("ValorSaldo", { type: "number" });
            ds.$adicionarCampoModelo("ValorFixacaoDolar", { type: "number" });
            ds.$adicionarCampoModelo("ValorFixacaoReal", { type: "number" });
            ds.$adicionarCampoModelo("ValorImpostoDolar", { type: "number" });
            ds.$adicionarCampoModelo("ValorImpostoReal", { type: "number" });
            ds.$adicionarCampoModelo("ValorAtencipadoDolar", { type: "number" });
            ds.$adicionarCampoModelo("ValorAtencipadoReal", { type: "number" });
            ds.$adicionarCampoModelo("ValorSaldoDolar", { type: "number" });
            ds.$adicionarCampoModelo("ValorSaldoReal", { type: "number" });

            ds.$adicionarCampoAggregate("QuantidadeFixada", "sum");
            ds.$adicionarCampoAggregate("QuantidadeFixadaReal", "sum");
            ds.$adicionarCampoAggregate("QuantidadeFixadaDolar", "sum");
            ds.$adicionarCampoAggregate("ValorFixacao", "sum");
            ds.$adicionarCampoAggregate("ValorImposto", "sum");
            ds.$adicionarCampoAggregate("ValorAtencipado", "sum");
            ds.$adicionarCampoAggregate("ValorSaldo", "sum");
            ds.$adicionarCampoAggregate("ValorFixacaoDolar", "sum");
            ds.$adicionarCampoAggregate("ValorFixacaoReal", "sum");
            ds.$adicionarCampoAggregate("ValorImpostoDolar", "sum");
            ds.$adicionarCampoAggregate("ValorImpostoReal", "sum");
            ds.$adicionarCampoAggregate("ValorAtencipadoDolar", "sum");
            ds.$adicionarCampoAggregate("ValorAtencipadoReal", "sum");
            ds.$adicionarCampoAggregate("ValorSaldoDolar", "sum");
            ds.$adicionarCampoAggregate("ValorSaldoReal", "sum");

            return {
                resizable: true,
                filterable: true,
                sortable: true,
                columnMenu: true,
                page: 1,
                dataSource: ds.$gerarDataSource(),
                excel: {
                    allPages: false,
                    fileName: 'Fixações.csv'
                },
                toolbar: [{
                    name: 'excel',
                    template: "<kendo-button class='btn btn-default k-grid-excel'>Gerar Excel</kendo-button>"
                }],
                dataBound: function(e) {
                    HelperFactory.mergeFooterTemplate(e, 0, 3);
                    HelperFactory.moveGridToolbarToFooter("#gridFixacoes");
                    var tfooter = e.sender.footer[0];
                    var trFooterClone = e.sender.footer[0].childNodes[0].cloneNode(true);
                    var firstDataItem = e.sender._data[0];
                    trFooterClone.childNodes[0].innerHTML = 'TOTAL US$';

                    try {
                        somaQuantidadeFixadaDolar = e.sender.dataSource._aggregateResult.QuantidadeFixadaDolar.sum;
                        somaValorFixacaoDolar = e.sender.dataSource._aggregateResult.ValorFixacaoDolar.sum;
                        somaValorImpostoDolar = e.sender.dataSource._aggregateResult.ValorImpostoDolar.sum;
                        somaValorAtencipado = e.sender.dataSource._aggregateResult.ValorAtencipadoDolar.sum;
                        somaValorSaldo = e.sender.dataSource._aggregateResult.ValorSaldoDolar.sum;
                        trFooterClone.childNodes[1].innerHTML = kendo.toString(somaQuantidadeFixadaDolar, 'n0');
                        trFooterClone.childNodes[4].innerHTML = kendo.toString(somaValorFixacaoDolar, 'n2');
                        trFooterClone.childNodes[5].innerHTML = kendo.toString(somaValorImpostoDolar, 'n2');
                        trFooterClone.childNodes[6].innerHTML = kendo.toString(somaValorAtencipado, 'n2');
                        trFooterClone.childNodes[7].innerHTML = kendo.toString(somaValorSaldo, 'n2');
                    } catch (error) {
                        trFooterClone.childNodes[1].innerHTML = '0';
                        trFooterClone.childNodes[4].innerHTML = '0';
                        trFooterClone.childNodes[5].innerHTML = '0';
                        trFooterClone.childNodes[6].innerHTML = '0';
                        trFooterClone.childNodes[7].innerHTML = '0';
                    }
                    tfooter.append(trFooterClone);
                },
                columns: [{
                        title: 'Contrato',
                        field: 'ContratoCompraMTP',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: center" },
                        footerTemplate: "TOTAL R$",
                        footerAttributes: { style: "text-align: center" }
                    },
                    {
                        title: 'Nro. Fixação',
                        field: 'NumeroFixacao',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: center" }
                    },
                    {
                        title: 'Data',
                        field: 'DataFixacao',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: center" },
                        template: '#= kendo.toString(kendo.parseDate(data.DataFixacao,"yyyy-MM-ddTHH:mm:ss"),"dd/MM/yyyy")#'
                    },
                    {
                        title: "Qtd. Fixada (kg)",
                        field: 'QuantidadeFixada',
                        aggregates: ["sum"],
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: right" },
                        template: "#if(data.QuantidadeFixada) {# #=kendo.toString (data.QuantidadeFixada,'n0')# #}else{#0#}#",
                        footerTemplate: function(data) {
                            return data.QuantidadeFixadaReal && data.QuantidadeFixadaReal.sum ? kendo.toString(data.QuantidadeFixadaReal.sum, 'n0') : 0
                        },
                        footerAttributes: { style: "text-align: right" },
                    },
                    {
                        title: 'Preço',
                        field: 'Preco',
                        template: "#if(data.Preco) {# #=kendo.toString (data.Preco,'n2')# #}else{#0#}#",
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: right" }
                    },
                    {
                        title: 'Moeda',
                        field: 'Moeda',
                        headerAttributes: { style: "text-align: center" }
                    },
                    {
                        title: 'Valor Fixação',
                        field: 'ValorFixacao',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: right" },
                        template: "#if(data.ValorFixacao) {# #=kendo.toString (data.ValorFixacao,'n2')# #}else{#0#}#",
                        footerTemplate: function(data) {
                            return data.ValorFixacaoReal && data.ValorFixacaoReal.sum ? kendo.toString(data.ValorFixacaoReal.sum, 'n2') : 0
                        },
                        footerAttributes: { style: "text-align: right" }
                    },
                    {
                        title: 'Impostos',
                        field: 'ValorImposto',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: right" },
                        template: "#if(data.ValorImposto) {# #=kendo.toString (data.ValorImposto,'n2')# #}else{#0#}#",
                        footerTemplate: function(data) {
                            return data.ValorImpostoReal && data.ValorImpostoReal.sum ? kendo.toString(data.ValorImpostoReal.sum, 'n2') : 0
                        },
                        footerAttributes: { style: "text-align: right" }
                    },
                    {
                        title: 'Antecipados',
                        field: 'ValorAtencipado',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: right" },
                        template: "#if(data.ValorAtencipado) {# #=kendo.toString (data.ValorAtencipado,'n2')# #}else{#0#}#",
                        footerTemplate: function(data) {
                            return data.ValorAtencipadoReal && data.ValorAtencipadoReal.sum ? kendo.toString(data.ValorAtencipadoReal.sum, 'n2') : 0
                        },
                        footerAttributes: { style: "text-align: right" }
                    },
                    {
                        title: 'Saldo',
                        field: 'ValorSaldo',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: right" },
                        template: "#if(data.ValorSaldo) {# #=kendo.toString (data.ValorSaldo,'n2')# #}else{#0#}#",
                        footerTemplate: function(data) {
                            return data.ValorSaldoReal && data.ValorSaldoReal.sum ? kendo.toString(data.ValorSaldoReal.sum, 'n2') : 0
                        },
                        footerAttributes: { style: "text-align: right" }
                    }
                ],
            }
        };
    }
})();