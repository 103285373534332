(function () {
    'use strict';

    angular.module('bunge.system').service('Session', Session);

    Session.$inject = ['$rootScope', 'APP_TOKEN_NAMES', 'NAV_OPTIONS', 'ADM_NAV_OPTIONS', 'FOR_NAV_OPTIONS', 'CLAIMS_TYPE', 'UserManagerFactory'];
    function Session($rootScope, APP_TOKEN_NAMES, NAV_OPTIONS, ADM_NAV_OPTIONS, FOR_NAV_OPTIONS, CLAIMS_TYPE, UserManagerFactory) {

        function Create(parsedToken) {
            var userType = new Array(null, "Externo", "Interno", "Root");
            var userStatus = new Array(null, "Normal", "Expired", "Blocked", "Juridical");

            var apps = [];
            var role = [];
            var claims = parsedToken.Claims;
            var profile = 'normal';

            this.userId = null;
            this.userRole = null;
            this.userName = null;
            this.userStatus = null;
            this.groupList = [];

            UserManagerFactory.GetUser().then(function (user) {
                if (user) {
                    $rootScope.currentUser.userSystemName = JSON.parse(user).nome.toUpperCase();
                }
            });

            for (var claimIndex in claims) {
                var claim = claims[claimIndex];
                var claimType = claim.Type.toLowerCase();

                if (claimType == "id") {
                    this.userId = claim.Value;
                }
                else if (claimType == "login") {
                    this.userName = claim.Value;
                }
                else if (claimType == "userstatus") {
                    this.userStatus = userStatus[claim.Value];
                }
                else if (claimType == "isAdmin" && claim.Value == true) {
                    profile = 'admin';
                }
                else if (claimType == "usertype") {
                    this.userRole = userType[claim.Value];

                    if (userType[claim.Value] == "Root")
                        profile = 'root';
                }
                else if (claimType == "role") {
                    role.push(JSON.parse(claims[claimIndex].Value));
                }
            }

            for (var groupIndex in role) {
                var group = role[groupIndex];
                var newGroup = { EmpresaList: group.EmpresaList, PortalList: [] };
                this.groupList.push(newGroup);
                if (group.PortalClaim) {
                    var newPortal = { PublicKey: group.PortalClaim.PublicKey, NmApp: group.PortalClaim.Description, AppList: {} };
                    newGroup.PortalList.push(newPortal);
                    for (var appIndex in group.PortalClaim.AppList) {
                        var app = group.PortalClaim.AppList[appIndex];

                        var name = null;
                        var label = null;
                        var route = false;

                        var menu = [];
                        var funcList = [];
                        var publicKey = app.PublicKey;

                        var appName = APP_TOKEN_NAMES[publicKey];
                        var descriptionApp = app.NmApp;
                        var externalApp = false;

                        switch (appName) {
                               case 'fornecedor':
                              name = NAV_OPTIONS.fornecedor.appName;

                             funcList = getPermissionList(FOR_NAV_OPTIONS, app.FunctionalityList, profile);

                                Object.keys(FOR_NAV_OPTIONS).map(function (key) {
                                    var item = FOR_NAV_OPTIONS[key];

                                    if (item.internalKey in funcList)
                                        menu.push(FOR_NAV_OPTIONS[key]);
                                });
                                
                            break;
                            case 'edi':
                            case 'ordem-carga':
                            case 'agendamento':
                            case 'tempo-em-fila':
                            case 'monitoramento':
                                name = NAV_OPTIONS.logistico.appName;

                                break;
                            case 'portaladministrador':
                                name = NAV_OPTIONS.administrador.appName;
                                funcList = getPermissionList(ADM_NAV_OPTIONS, app.FunctionalityList, profile);

                                Object.keys(ADM_NAV_OPTIONS).map(function (key) {
                                    var item = ADM_NAV_OPTIONS[key];

                                    if (item.internalKey in funcList)
                                        menu.push(ADM_NAV_OPTIONS[key]);
                                });

                                break;
                        }

                        newPortal.AppList[name] = { name: name, permissions: funcList, menu: menu, publicKey: publicKey, descriptionApp: newPortal.NmApp };
                    }
                }
            }

            $rootScope.setCurrentUser(this);
        }

        function getPermissionList(navOptions, functionalityList, profile) {
            var funcList = [];

            for (var funcIndex in functionalityList) {
                var func = functionalityList[funcIndex];
                var readOnly = func.ReadOnly;

                var obj = {
                    Id: func.Id, Description: func.Description,
                    PublicKey: func.PublicKey, ReadOnly: readOnly
                };

                for (var navIndex in navOptions) {
                    var menuItem = navOptions[navIndex];

                    if (profile == 'root') {
                        obj.ReadOnly = false;
                        funcList[menuItem.internalKey] = obj;
                    }

                    else if (profile == 'admin' && typeof menuItem.onlyAdmin != 'undefined' && menuItem.onlyAdmin == true || menuItem.publicKey == func.PublicKey)
                        funcList[menuItem.internalKey] = obj;

                    else if (menuItem.publicKey == func.PublicKey)
                        funcList[menuItem.internalKey] = obj;
                }
            }


            return funcList;
        }


        function Destroy() {
            this.userId = null;
            this.userRole = null;
            this.userName = null;
            this.userStatus = null;
            this.navPermissions = null;
            this.groupList = null;

            $rootScope.setCurrentUser(null);
        }

        function hasApp(appName) {
            return this.GetFirstAppByName(appName);
        }

        function getFirstPortalByKey(publicKey) {
            for (var groupIndex in this.groupList) {
                for (var portalIndex in this.groupList[groupIndex].PortalList) {
                    var portal = this.groupList[groupIndex].PortalList[portalIndex];
                    if (portal.PublicKey == publicKey) {
                        return portal;
                    }
                };
            };
            return null;
        };

        function getFirstAppByName(appName) {
            for (var groupIndex in this.groupList) {
                for (var portalIndex in this.groupList[groupIndex].PortalList) {
                    for (var appIndex in this.groupList[groupIndex].PortalList[portalIndex].AppList) {
                        if (appIndex == appName)
                            return this.groupList[groupIndex].PortalList[portalIndex].AppList[appIndex];
                    };
                };
            };
            return null;
        };

        function getAppsByName(appName) {
            var appList = [];
            for (var groupIndex in this.groupList) {
                for (var portalIndex in this.groupList[groupIndex].PortalList) {
                    for (var appIndex in this.groupList[groupIndex].PortalList[portalIndex].AppList) {
                        if (appIndex == appName)
                            appList.push(this.groupList[groupIndex].PortalList[portalIndex].AppList[appIndex]);
                    };
                };
            };
            return appList;
        };
        

             function getPortalModules(){
var portalList = [];

 for (var groupIndex in this.groupList) {
                for (var portalIndex in this.groupList[groupIndex].PortalList) {
                    if(!portalList[this.groupList[groupIndex].PortalList[portalIndex].PublicKey]){
                         portalList[this.groupList[groupIndex].PortalList[portalIndex].PublicKey] = {name: this.groupList[groupIndex].PortalList[portalIndex].NmApp, menu:[]};
                    }

                    var portal = portalList[this.groupList[groupIndex].PortalList[portalIndex].PublicKey];
                   for (var appIndex in this.groupList[groupIndex].PortalList[portalIndex].AppList) {
                                          this.groupList[groupIndex].PortalList[portalIndex].AppList[appIndex].menu.forEach(function (value){
                         if(!portal.menu[value.publicKey]){
portal.menu.push({internalKey: value.internalKey, publicKey: value.publicKey});
                         }
                    });
                    };

                };
            };
            return portalList;
        };

        return {
            Destroy: Destroy,
            Create: Create,
            GetFirstAppByName: getFirstAppByName,
            GetAppsByName: getAppsByName,
            GetFirstPortalByKey: getFirstPortalByKey,
            CheckAppPermission: hasApp,
            GetPortalModules: getPortalModules
        };
    }
})();