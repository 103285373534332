(function () {
    'use strict';

    angular.module('bunge.system').service('NavigationService', Navigation);

    Navigation.$inject = ['$rootScope', 'NAV_OPTIONS', 'Session'];

    function Navigation($rootScope, NAV_OPTIONS, Session) {
        var menuItens = NAV_OPTIONS;

        function getUserSubMenu(appName) {
            var subMenuItens = [];         
   
            var appList = Session.GetAppsByName(appName);
            for (var appIndex in appList) {
                var subMenu = appList[appIndex].menu;
                var permission = appList[appIndex].permissions;

                for (var menuIndex in subMenu) {
                    var item = subMenu[menuIndex];

                    var incluir = true;

                    subMenuItens.forEach(function (value) {
                        if (value.internalKey == item.internalKey) {
                            incluir = false;
                        }
                    });

                    if (item.internalKey in permission && incluir && !item.invisivel) {
                        subMenuItens.push(item);
                    }
                }
            }

            return subMenuItens.sort(function (a, b) {
                return a.order - b.order;
            });
        }

        function buildUserMenu() {
            if ($rootScope.currentUser) {
                for (var menuIndex in menuItens) {
                    var menuItem = menuItens[menuIndex];
                    var app = Session.CheckAppPermission(menuItem.appName);
                    if (menuItem.isRootMenu || app) {
                        if (app) {
                            menuItem.legenda = app.descriptionApp;
                        }
                        $rootScope.navPermissions.push(menuItem);
                    }
                }
            }
        }

        function canISeeThisTab(appName, functionality) {

            var appList = Session.GetAppsByName(appName);
            for (var appIndex in appList) {
                var permissions = appList[appIndex].permissions;
                if (permissions[functionality] && !permissions[functionality].ReadOnly)
                    return true;
            }

            return false;
        }

        return {
            BuildUserMenu: buildUserMenu,
            GetUserSubMenu: getUserSubMenu,
            CanISeeThisTab: canISeeThisTab
        };
    }
}());