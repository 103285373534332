(function(){
	'use strict';

	angular.module('bunge').directive('permission', ['$rootScope', 'Session', function ($rootScope, Session) {
		return {
			restrict: 'A',
			scope: {
				permission: '='
			},
	 
			link: function (scope, elem, attrs) {
				if("permission" in scope || scope.permission.length == 2){
					var appName = scope.permission[0];					
					var functionality = scope.permission[1];

					var appList = Session.GetAppsByName(appName);
					for (var appIndex in appList) {
					    var permissions = appList[appIndex].permissions;

					    if (functionality in permissions) {
					        if (!permissions[functionality].ReadOnly) {
					            return;
					        }
					    }
					}

					elem.remove();
				}
				else {
					throw "Faltam parâmetros na diretiva de permissão";
				}
			}
		}
	}]);
}());