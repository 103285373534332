if (window.location.pathname !== '/') {
    window.location.href = '/#' + window.location.pathname;
}

(function () {
    'use strict';

    angular
        .module('bunge.modules.login')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$state', 'AuthenticationService', 'TokenManagerFactory', 'ENDPOINT_URI', '$http'];
    function LoginController($state, AuthenticationService, TokenManagerFactory, ENDPOINT_URI, $http) {
        var vm = this;

        (function initController() {
            $http.get(ENDPOINT_URI.endpoint_sts + 'cookie');
            return TokenManagerFactory.GetToken().then(function (token) {
                if (token) {
                    window.location.href = $state.href('home');
                } else {
                    AuthenticationService.ClearCredentials();
                }
            });
        })();
    }
})();