(function () {
    'use strict';

    angular
        .module('bunge.modules.fornecedor.conta-corrente.recebimentos')
        .directive('modalDetalheRecebimento', function () {
            return {
                restrict: 'EA',
                transclude: true,
                scope: {
                    model: "=",
                    onRemove: "&"
                },
                controller: ['$uibModal', '$scope', '$uibModalStack', 'HelperFactory', 'Flash', '$http', 'ENDPOINT_URI', 'KendoDataSourceFactory', function ($uibModal, $scope, $uibModalStack, HelperFactory, Flash, $http, ENDPOINT_URI, KendoDataSourceFactory) {
                    function iniciar() {
                        Flash.clear();

                        var ds = KendoDataSourceFactory.iniciar({
                            endpoint: 'ContratoRecebimentos',
                            fornecedor: true,
                            pageable: false,
                            pageSize: 999,
                            sort: { field: "ContratoCompraMTP", dir: "desc" },
                            header: $scope.model.header
                        });

                        ds.$adicionarCampoModelo("QuantidadePesoLiquidoOrigem", { type: "number" });
                        ds.$adicionarCampoModelo("QuantidadePesoBruto", { type: "number" });
                        ds.$adicionarCampoModelo("QuantidadePesoTara", { type: "number" });
                        ds.$adicionarCampoModelo("QuantidadePesoLiquidoDestino", { type: "number" });
                        ds.$adicionarCampoModelo("PercentualClassificacaoUmidadeDestino", { type: "number" });
                        ds.$adicionarCampoModelo("PercentualClassificacaoImpurezaDestino", { type: "number" });
                        ds.$adicionarCampoModelo("PercentualClassificacaoDeterioracaoDestino", { type: "number" });
                        ds.$adicionarCampoModelo("PercentualClassificacaoEsverdeadoDestino", { type: "number" });
                        ds.$adicionarCampoModelo("QuantidadePesoLiquidoFinal", { type: "number" });
                        ds.$adicionarCampoModelo("CodigoControleEntradaGtm", { type: "number" });

                        ds.$adicionarCampoAggregate("QuantidadePesoLiquidoOrigem", "sum");
                        ds.$adicionarCampoAggregate("QuantidadePesoLiquidoDestino", "sum");
                        ds.$adicionarCampoAggregate("PercentualClassificacaoUmidadeDestino", "average");

                        ds.$adicionarCampoAggregate("ProporcaoUmidadeDesconto", "sum");
                        ds.$adicionarCampoAggregate("ProporcaoImpurezaDesconto", "sum");
                        ds.$adicionarCampoAggregate("ProporcaoDeterioracaoDesconto", "sum");
                        ds.$adicionarCampoAggregate("ProporcaoEsverdeadoDesconto", "sum");

                        ds.$adicionarCampoAggregate("PercentualClassificacaoImpurezaDestino", "average");
                        ds.$adicionarCampoAggregate("PercentualClassificacaoDeterioracaoDestino", "average");
                        ds.$adicionarCampoAggregate("PercentualClassificacaoEsverdeadoDestino", "average");
                        ds.$adicionarCampoAggregate("QuantidadePesoLiquidoFinal", "sum");

                        $scope.paramsGridPrazosEntregaOptions = {
                            resizable: true,
                            filterable: true,
                            sortable: true,
                            columnMenu: true,
                            dataSource: ds.$gerarDataSource(),
                            dataBound: function (e) {
                                HelperFactory.mergeFooterTemplate(e, 0, 5);
                                HelperFactory.moveGridToolbarToFooter("#gridDetalheRecebimento");
                            },
                            excel: {
                                allPages: false,
                                fileName: 'Recibimentos - Detalhe.csv'
                            },
                            toolbar: [{
                                name: 'voltar',
                                template: '<button class="btn btn-grey-custom" ng-click="close()" type="button">FECHAR</button>'
                            }, {
                                name: 'excel',
                                template: "<button class='btn btn-grey-custom k-grid-excel'>Gerar Excel</button>"
                            }],
                            columns: [{
                                title: 'Contrato',
                                field: 'ContratoCompraMTP',
                                headerAttributes: { style: "text-align: center" },
                                attributes: { style: "text-align: center" },
                                footerAttributes: { style: "text-align: center" },
                                footerTemplate: "TOTAL"
                            },
                            {
                                title: 'Recebimento',
                                field: 'CodigoControleEntradaGtm',
                                headerAttributes: { style: "text-align: center" },
                                attributes: { style: "text-align: center" },
                            },
                            {
                                title: 'Data Entrega',
                                field: 'DataControleEntradaGtm',
                                headerAttributes: { style: "text-align: center" },
                                attributes: { style: "text-align: center" },
                                template: '#= kendo.toString(kendo.parseDate(data.DataControleEntradaGtm,"yyyy-MM-ddTHH:mm:ss"),"dd/MM/yyyy")#'
                            },
                            {
                                title: 'Frete',
                                field: 'DescricaoFrete',
                                headerAttributes: { style: "text-align: center" },
                                attributes: { style: "text-align: center" },
                            },
                            {
                                title: 'Placa',
                                field: 'CodigoPlacaVeculo',
                                headerAttributes: { style: "text-align: center" },
                                attributes: { style: "text-align: center" },
                            },
                            {
                                title: "Peso Origem Líquido (kg)",
                                field: 'QuantidadePesoLiquidoOrigem',
                                aggregates: ["sum"],
                                template: "#if(data.QuantidadePesoLiquidoOrigem) {# #=kendo.toString(data.QuantidadePesoLiquidoOrigem, 'n0')# #}else{#0#}#",
                                headerAttributes: { style: "text-align: center" },
                                attributes: { style: "text-align: right" },
                                footerAttributes: { style: "text-align: right" },
                                footerTemplate: function (data) {
                                    return data.QuantidadePesoLiquidoOrigem && data.QuantidadePesoLiquidoOrigem.sum ? kendo.toString(data.QuantidadePesoLiquidoOrigem.sum, 'n0') : 0
                                }
                            },
                            {
                                title: "Peso Destino (kg)",
                                headerAttributes: { style: "text-align: center; border-right-color: rgb(204, 204, 204) !important;border-right-style: solid !important;border-right-width: 1px !important;border-collapse: separate !important;" },
                                attributes: { style: "text-align: right" },
                                footerAttributes: { style: "text-align: right" },
                                columns: [{
                                    title: "Bruto",
                                    field: 'QuantidadePesoBruto',
                                    aggregates: ["sum"],
                                    template: "#if(data.QuantidadePesoBruto) {# #=kendo.toString(data.QuantidadePesoBruto, 'n0')# #}else{#0#}#",
                                    headerAttributes: { style: "text-align: center" },
                                    attributes: { style: "text-align: right" },
                                },
                                {
                                    title: "Tara",
                                    field: 'QuantidadePesoTara',
                                    aggregates: ["sum"],
                                    template: "#if(data.QuantidadePesoTara) {# #=kendo.toString(data.QuantidadePesoTara, 'n0')# #}else{#0#}#",
                                    headerAttributes: { style: "text-align: center" },
                                    attributes: { style: "text-align: right" },
                                },
                                {
                                    title: "Liquido",
                                    field: 'QuantidadePesoLiquidoDestino',
                                    aggregates: ["sum"],
                                    template: "#if(data.QuantidadePesoLiquidoDestino) {# #=kendo.toString(data.QuantidadePesoLiquidoDestino, 'n0')# #}else{#0#}#",
                                    headerAttributes: { style: "text-align: center" },
                                    attributes: { style: "text-align: right" },
                                    footerAttributes: { style: "text-align: right" },
                                    footerTemplate: function (data) {
                                        return data.QuantidadePesoLiquidoDestino && data.QuantidadePesoLiquidoDestino.sum ? kendo.toString(data.QuantidadePesoLiquidoDestino.sum, 'n0') : 0
                                    }
                                }
                                ]
                            },
                            {
                                title: "Classificação",
                                headerAttributes: { style: "text-align: center; border-right-color: rgb(204, 204, 204) !important;border-right-style: solid !important;border-right-width: 1px !important;border-collapse: separate !important;" },
                                attributes: { style: "text-align: right" },
                                footerAttributes: { style: "text-align: right" },
                                columns: [{
                                    title: "%Umid",
                                    field: 'PercentualClassificacaoUmidadeDestino',
                                    aggregates: ["sum"],
                                    template: "#if(data.PercentualClassificacaoUmidadeDestino) {# #=kendo.toString(data.PercentualClassificacaoUmidadeDestino, 'n2')# #}else{#0#}#",
                                    headerAttributes: { style: "text-align: center" },
                                    attributes: { style: "text-align: right" },
                                    footerAttributes: { style: "text-align: right" },
                                    footerTemplate: function (data) {
                                        var media = data.ProporcaoUmidadeDesconto.sum / data.QuantidadePesoLiquidoDestino.sum;
                                        return media ? kendo.toString(media, 'n2') : 0
                                    }
                                },
                                {
                                    title: "%Imp",
                                    field: 'PercentualClassificacaoImpurezaDestino',
                                    aggregates: ["sum"],
                                    template: "#if(data.PercentualClassificacaoImpurezaDestino) {# #=kendo.toString(data.PercentualClassificacaoImpurezaDestino, 'n2')# #}else{#0#}#",
                                    headerAttributes: { style: "text-align: center" },
                                    attributes: { style: "text-align: right" },
                                    footerAttributes: { style: "text-align: right" },
                                    footerTemplate: function (data) {
                                        var media = data.ProporcaoImpurezaDesconto.sum / data.QuantidadePesoLiquidoDestino.sum;
                                        return media ? kendo.toString(media, 'n2') : 0
                                    }
                                },
                                {
                                    title: "%Det",
                                    field: 'PercentualClassificacaoDeterioracaoDestino',
                                    aggregates: ["sum"],
                                    template: "#if(data.PercentualClassificacaoDeterioracaoDestino) {# #=kendo.toString(data.PercentualClassificacaoDeterioracaoDestino, 'n2')# #}else{#0#}#",
                                    headerAttributes: { style: "text-align: center" },
                                    attributes: { style: "text-align: right" },
                                    footerAttributes: { style: "text-align: right" },
                                    footerTemplate: function (data) {
                                        var media = data.ProporcaoDeterioracaoDesconto.sum / data.QuantidadePesoLiquidoDestino.sum;
                                        return media ? kendo.toString(media, 'n2') : 0
                                    }
                                },
                                {
                                    title: "%Esv",
                                    field: 'PercentualClassificacaoEsverdeadoDestino',
                                    aggregates: ["sum"],
                                    template: "#if(data.PercentualClassificacaoEsverdeadoDestino) {# #=kendo.toString(data.PercentualClassificacaoEsverdeadoDestino, 'n2')# #}else{#0#}#",
                                    headerAttributes: { style: "text-align: center" },
                                    attributes: { style: "text-align: right" },
                                    footerAttributes: { style: "text-align: right" },
                                    footerTemplate: function (data) {
                                        var media = data.ProporcaoEsverdeadoDesconto.sum / data.QuantidadePesoLiquidoDestino.sum;
                                        return media ? kendo.toString(media, 'n2') : 0
                                    }
                                }
                                ]
                            },
                            {
                                title: "Peso Líquido Final (kg)",
                                field: 'QuantidadePesoLiquidoFinal',
                                aggregates: ["sum"],
                                template: "#if(data.QuantidadePesoLiquidoFinal) {# #=kendo.toString(data.QuantidadePesoLiquidoFinal, 'n0')# #}else{#0#}#",
                                headerAttributes: { style: "text-align: center" },
                                attributes: { style: "text-align: right" },
                                footerAttributes: { style: "text-align: right" },
                                footerTemplate: function (data) {
                                    return data.QuantidadePesoLiquidoFinal && data.QuantidadePesoLiquidoFinal.sum ? kendo.toString(data.QuantidadePesoLiquidoFinal.sum, 'n0') : 0
                                }
                            }
                            ]
                        };

                    };

                    iniciar();

                    $scope.close = function () {
                        $uibModalStack.dismissAll();
                    }

                    var modalInstance = $uibModal.open({
                        animation: true,
                        scope: $scope,
                        size: 'lg',
                        windowClass: 'modal-xl',
                        templateUrl: 'app/modules/fornecedor/submodules/conta-corrente/submodules/recebimentos/modal-detalhe-recebimento/modal-detalhe-recebimento.template.html',
                    });

                    modalInstance.result.then(function (selectedItem) { }, function () {
                        $scope.onRemove();
                    });
                }],
            };
        });
})();