if (window.location.pathname !== '/') {
    window.location.href = '/#' + window.location.pathname;
}

(function () {
    'use strict';

    angular
        .module('bunge.modules.recuperar-acesso')
        .controller('RecuperarSenhaController', RecuperarSenhaController);

    RecuperarSenhaController.$inject = ['$state', 'NewPasswordService', 'Flash', '$stateParams']
    function RecuperarSenhaController($state, NewPasswordService, Flash, $stateParams) {
        var vm = this;

        (function initController() {
            vm.showForm = true;
            vm.chave = $stateParams.chave;
            vm.tipo = 1;
            vm.showForm = true;
            vm.chave = $stateParams.chave;
            if (vm.chave) {
                NewPasswordService.VerificarChave({ chave: vm.chave }).then(function (result) {
                    if (result !== "ok") {
                        vm.erro = {};
                        vm.erro.mensagem = result;
                    }
                });
            }
        })();

        vm.novoTipo = function (value) {
            vm.cpf = undefined;
            vm.cnpj = undefined;
        };

        vm.recuperar = function () {
            vm.dataLoading = true;
            if (vm.cpf) {
                var user = {
                    NumeroDocumento: vm.cpf 
                };
            } else if (vm.cnpj) {
                var user = {
                    NumeroDocumento: vm.cnpj
                };
            }

            var newPassword = NewPasswordService.Reset(user);
            newPassword.then(function (result) {
                Flash.clear();
                if (result === "ok") {                   
                    vm.dataLoading = false;
                    vm.showForm = false;                
                } else {
                    var message = 'A solicitação não pode ser realizada! '+ result  ;
                    var id = Flash.create('danger', message, 10000);
                    vm.dataLoading = false;                    
                }
            }, function () {
                var message = 'A solicitação não pode ser realizada!';
                var id = Flash.create('danger', message, 10000);
                vm.dataLoading = false;
            });
        };

            vm.executar = function () {
                if (!vm.password ||  !vm.passwordConfirmacao) {
                    var id = Flash.create('danger', "Todos os campos são obrigatórios.", 8000);
                    return;
                }

                if ((vm.password != vm.passwordConfirmacao)) {
                    var id = Flash.create('danger', "Senha e Confirmar a nova senha devem ser iguais.", 8000);
                    return;
                }

                if (vm.password.length < 6) {
                    var id = Flash.create('danger', "A senha deve ter mais que 6 caracteres.", 8000);
                    vm.password = '';
                    vm.passwordConfirmacao = '';
                    return;
                }

                var res1 = new RegExp("[A-Z]").test(vm.password);
                var res2 = new RegExp("[a-z]").test(vm.password);
                var res3 = new RegExp("[0-9]").test(vm.password);
                var res4 = new RegExp("[^a-zA-Z0-9]").test(vm.password);

                if (!(res1 && res2 && res3 && res4)) {
                    var id = Flash.create('danger', "A senha deve ter mais de 6 caracteres e conter números, caracteres especiais, letras maiúsculas e minúsculas. A senha não pode ser iqual as 3 ultimas senhas.", 8000);
                    vm.passwordConfirmacao = '';
                    vm.password = '';
                    return;
                }

                vm.dataLoading = true;
                var user = {
                    Chave: vm.chave,
                    Senha: vm.password,
                    ConfirmacaoSenha: vm.passwordConfirmacao
                }

                NewPasswordService.Executar(user).then(function (result) {
                    Flash.clear();
                    if (result === "ok") {
                        vm.dataLoading = false;
                        vm.showForm = false;
                    } else {
                        var message = 'A solicitação não pode ser realizada! ' + result;
                        var id = Flash.create('danger', message, 10000);
                        vm.dataLoading = false;
                    }
                }, function () {
                    var message = 'A solicitação não pode ser realizada!';
                    var id = Flash.create('danger', message, 10000);
                    vm.dataLoading = false;
                });
            };

            //vm.dataLoading = false;
            //vm.showForm = false;
    }

})();