(function () {
    'use strict';

    angular.module('bunge', [
        'ui.bootstrap',
        'kendo.directives',
        'ngDialog',
        'ui.utils.masks',
        'smoothScroll',
        'ui.router',
        'ngResource',
        'ngFlash',
        'ODataResources',
        'angular-jwt',
        'bunge.modules',
        'bunge.system',
        'bunge.components',
        'ui.router.tabs',
         'angularSpinner',
         'oc.lazyLoad',
    ]);

})();