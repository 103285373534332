(function () {

    'use strict';

    angular
        .module('bunge.system')
        .factory('ModalAdicionarUsuarioService', ModalUsuarioExternoService);

    ModalUsuarioExternoService.$inject = ['$resource', 'ENDPOINT_URI'];
    function ModalUsuarioExternoService($resource, ENDPOINT_URI) {
        var MyResource = $resource(ENDPOINT_URI.endpoint_backendReq + 'fornecedor-usuario', {}, {
            criarNovo: {
                url: ENDPOINT_URI.endpoint_backendReq + 'fornecedor-usuario',
                method: 'POST',
            },
            editar: {
                url: ENDPOINT_URI.endpoint_backendReq + 'fornecedor-usuario',
                method: 'PUT',
            },
        });

        function criarNovo(data) {
            var myResource = new MyResource();
            myResource.DocumentoFornecedor = data.CodigoFornecedor,
            myResource.Email = data.Email,
            myResource.Documento = data.NumeroDocumento,
            myResource.Nome = data.Nome
            return myResource.$criarNovo();
        }


        function editar(data) {
            var myResource = new MyResource();
            myResource.DocumentoFornecedor = data.CodigoFornecedor,
            myResource.Email = data.Email,
            myResource.Documento = data.NumeroDocumento,
            myResource.Nome = data.Nome,
            myResource.Id = data.CodigoUsuario
            return myResource.$editar();
        }

        return {
            criarNovo: criarNovo,
            editar: editar,
        };
    }
})();
