(function () {

    'use strict';

    angular
        .module('bunge.modules.alterar-email')
        .service('NewEmailService', NewEmailService);

    NewEmailService.$inject = ['ENDPOINT_URI', '$resource'];
    function NewEmailService(ENDPOINT_URI, $resource) {

          var basePath = 'fornecedor';

        var Program = $resource(ENDPOINT_URI.endpoint_backendReq + basePath, {}, {
            
            alterarEmail: {
                
                url: ENDPOINT_URI.endpoint_backendReq + 'fornecedor/alterar-email',
                method: 'PUT'
            },
            recuperarEmail: {
                url: ENDPOINT_URI.endpoint_backendReq + 'fornecedor/recuperar-email/:codigoSts',
                method: 'GET',
                transformResponse: function (data) { 
                    return { email: angular.fromJson(data)}; 
                },
                params: {
                    codigoSts: '@codigoSts'
                }
            },
        });
        
        function RecuperarEmail(data)
        {
            var program = new Program();
            return program.$recuperarEmail({codigoSts : data });
        }

        function Alterar(data) {
            var program = new Program();
            program.Login = data.Login;
            program.Email = data.Email;
            program.Senha = data.Senha;
            program.ConfimacaoSenha = data.ConfirmSenha;
            return program.$alterarEmail();
        }

        return {
            Alterar: Alterar,
            RecuperarEmail: RecuperarEmail
        };
    }
})();