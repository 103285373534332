(function () {
     'use strict';
     
angular.module('bunge.modules.home')
    .config(['$stateProvider',ConfigHome]);

function ConfigHome($stateProvider) {
    $stateProvider
            .state('home', {
            url: '/home',
            templateUrl: 'app/modules/home/view/home.view.html',
            controller: 'HomeController',
            controllerAs: 'vm'
        })
}
})();
