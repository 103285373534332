(function () {
    'use strict';

    angular
       .module('bunge.components')
       .directive('loginPanel', function () {
           return {
               restrict: 'EA',
               templateUrl: '/app/components/login-panel/login-panel.template.html',
               controller: LoginController,
               controllerAs: 'vm'
           };
       });

    LoginController.$inject = ['$state', 'AuthenticationService', '$rootScope', 'AUTH_EVENTS', 'TokenManagerFactory', 'Flash','HelperFactory'];
    function LoginController($state, AuthenticationService, $rootScope, AUTH_EVENTS, TokenManagerFactory, Flash, HelperFactory) {
        var vm = this;

        vm.login = login;

        function login() {
            vm.dataLoading = true;
            var user = {
                login: vm.username,
                password: vm.password
            };

            //Check for token
            return TokenManagerFactory.GetToken().then(function (token) {
                if (!token) {
                    Flash.clear();
                    AuthenticationService.Login(user).then(function (result) {
                        if (result.status == 200 || result.status == 201 || result.status == 202) {
                            $rootScope.$broadcast(AUTH_EVENTS.loginSuccess);
                            window.location.href = $state.href('home');
                        } else {
                            $rootScope.$broadcast(AUTH_EVENTS.loginFailed);
                            try {
                                var json = JSON.parse(result.data.content);
                                HelperFactory.getErrorMessage(json);
                            } catch (e) {
                                HelperFactory.getErrorMessage(result.data);
                            }
                            
                        }
                        vm.dataLoading = false;
                    });
                }
            });
        }
    }

})();

