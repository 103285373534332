(function () {
    'use strict';

    angular
        .module('bunge.modules.configuracoes')
        .controller('ConfiguracoesController', ConfiguracoesController);

    ConfiguracoesController.$inject = ['$state'];
    function ConfiguracoesController($state) {
        var vm = this;
        vm.active = active;

        vm.menuItens = [{
            route: 'configuracoes.alterar-senha',
            legenda: 'ALTERAR MINHA SENHA'
        },{
            route: 'configuracoes.alterar-email',
            legenda: 'ALTERAR MEU E-MAIL'
        }];


        function active(item) {
            return $state.current.name == item.route;
        }
    }
})();