(function () {

    'use strict';

    angular.module('bunge.modules', [
        'bunge.modules.alterar-email',
        'bunge.modules.alterar-senha',
        'bunge.modules.home',
        'bunge.modules.login',
        'bunge.modules.primeiro-acesso',
        'bunge.modules.recuperar-acesso',
        // 'bunge.modules.registro-acesso',
        'bunge.modules.administracao',
         'bunge.modules.fornecedor',
        'bunge.modules.configuracoes'

    ]);
})();