(function() {
    'use strict';

    angular.module('bunge.modules.fornecedor.conta-corrente')
        .factory('ContaCorrenteParamsFactory', ContaCorrenteParamsFactory);

    function ContaCorrenteParamsFactory() {

        var factory = {
            getHeader: getHeader
        };

        function getHeader($stateParams) {
            return {
                "OData.DocumentoFornecedor": $stateParams['documentoFornecedor'],
                "OData.DocumentoFazenda": $stateParams['documentoFazenda'],
                "OData.CodigoFornecedor": $stateParams['codigoFornecedor'],
                "OData.CodigoNucleo": $stateParams['codigoNucleo'],
                "OData.CodigoProduto": $stateParams['codigoProduto'],
                "OData.Posicao": $stateParams['posicao'],
                "OData.ContratoCompraMtp": $stateParams['contrato'] ? $stateParams['contrato'].ContratoCompraMTP : "",
                "OData.CodigoEmpresa": $stateParams['contrato'] ? $stateParams['contrato'].CodigoEmpresa : "",
                "OData.CodigoFilial": $stateParams['contrato'] ? $stateParams['contrato'].CodigoFilial : "",
                "OData.AnoSafra": !$stateParams['anoSafra'] || $stateParams['anoSafra'] == "TODAS" ? -1 : $stateParams['anoSafra'],
                "OData.ContratosSafra": JSON.stringify($stateParams['contratosSafra'])
            }
        }
        return factory;
    }
})();