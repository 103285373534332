(function () {
    'use strict';

    angular
        .module('bunge')
        .constant('USER_ROLES', {
            all: '*',
            interno: 'interno',
            externo: 'externo',
        });
})();