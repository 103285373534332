(function() {

    angular
        .module('bunge.modules.fornecedor.conta-corrente.saldo-contrato.resumido')
        .controller('FornecedorContaCorrenteSaldoContratoResumidoController', FornecedorContaCorrenteSaldoContratoResumidoController);

    FornecedorContaCorrenteSaldoContratoResumidoController.$inject = ['KendoDataSourceFactory', '$stateParams', 'ContaCorrenteParamsFactory', 'HelperFactory'];

    function FornecedorContaCorrenteSaldoContratoResumidoController(KendoDataSourceFactory, $stateParams, ContaCorrenteParamsFactory, HelperFactory) {

        var vm = this;

        vm.paramsGridOptions = function(dataItem) {

            var dataSourceParams = {
                endpoint: 'ContratoSaldoAgrupado',
                fornecedor: true,
                pageable: false,
                pageSize: 999,
                sort: {
                    field: "Agrupador",
                    dir: "asc"
                },
                header: ContaCorrenteParamsFactory.getHeader($stateParams)
            };

            var ds = KendoDataSourceFactory.iniciar(dataSourceParams);
            ds.$adicionarCampoModelo("ValorMoedaInterna", { type: "number" });
            ds.$adicionarCampoAggregate("ValorMoedaInterna", "sum");

            return {
                resizable: true,
                filterable: true,
                sortable: true,
                columnMenu: true,
                dataSource: ds.$gerarDataSource(),
                excel: {
                    allPages: false,
                    fileName: 'SaldoContratos.csv'
                },
                toolbar: [{
                    name: 'excel',
                    template: "<kendo-button class='btn btn-default k-grid-excel'>Gerar Excel</kendo-button>"
                }],
                dataBound: function(e) {
                    HelperFactory.moveGridToolbarToFooter("#gridResumido");
                },
                columns: [
                    {
                        title: 'Descrição',
                        field: 'Agrupador',
                        attributes: { style: "#if(data.IdDebitoCredito == 'D') {# color: red; #}#" },
                        footerTemplate: "SALDO",
                        footerAttributes: { style: "text-align: center" }
                    },
                    {
                        title: 'Valor R$',
                        field: 'ValorMoedaInterna',
                        headerAttributes: { style: "text-align: center" },
                        template: "#if(data.ValorMoedaInterna) {# #=kendo.toString (data.ValorMoedaInterna,'n2')# #}else{#0#}#",
                        attributes: { style: "#if(data.IdDebitoCredito == 'D') {# color: red; #}# text-align: right;" },
                        footerAttributes: { style: "#if(data.ValorMoedaInterna.sum < 0) {# color: red; #}# text-align: right;" },
                        footerTemplate: function(data) {
                            return data.ValorMoedaInterna && data.ValorMoedaInterna.sum ? kendo.toString(data.ValorMoedaInterna.sum, 'n2') : 0;
                        }
                    },
                    {
                        title: 'D/C',
                        field: 'IdDebitoCredito',
                        attributes: { style: "#if(data.IdDebitoCredito == 'D') {# color: red; #}#" },
                    },

                ]
            }
        };


    }
})();