(function() {

    'use strict';

    angular.module('bunge.system')
        .factory('AuthenticationInterceptor', [
            '$q', '$rootScope', 'TokenManagerFactory', 'ENDPOINT_URI',
            function($q, $rootScope, TokenManagerFactory, ENDPOINT_URI) {
                return {
                    request: function(config) {
                        var appKey = null;

                        if (typeof $rootScope.currentApp != 'undefined' && $rootScope.currentApp != '')
                            appKey = $rootScope.currentApp;

                        config.headers['X-APPKEY'] = appKey;

                        if ($rootScope.funcAtual)
                            config.headers['X-FUNCTIONALITY'] = $rootScope.funcAtual.publicKey;


                        if (!(config.url.startsWith(ENDPOINT_URI.endpoint_backendReq) || config.url.startsWith(ENDPOINT_URI.endpoint_sts) || config.url.startsWith(ENDPOINT_URI.endpoint_fornecedorReq))) {
                            return config;
                        }

                        if (config.url.indexOf("user-authenticate") > 0 || config.url.indexOf("verificar-usuario") > 0 || config.url.indexOf("pedido/executar-pedido") > 0 || config.url.indexOf("pedido/obter-pedido/") > 0 || config.url.toUpperCase() == (ENDPOINT_URI.endpoint_backendReq + "pedido").toUpperCase()) {
                            return config;
                        }

                        return TokenManagerFactory.GetToken().then(function(token) {
                            var canceler = $q.defer();
                            config.timeout = canceler.promise;
                            if (!token) {
                                $rootScope.$broadcast('LOGOUT');
                                canceler.resolve();
                                return;
                            } else if (config.url.indexOf('token-refresh') < 0) {
                                $rootScope.$broadcast('REFRESH_TOKEN');
                            }
                            if (token) {
                                config.headers['Authorization'] = 'Bearer ' + token;
                            }
                            return config;
                        });
                    },
                    response: function(response) {
                        return response;
                    },
                    responseError: function(response) {
                        $rootScope.$broadcast({
                            401: 'LOGOUT',
                            403: 'LOGOUT',
                        }[response.status], response);
                        return $q.reject(response);
                    }
                };
            }
        ]);
})();