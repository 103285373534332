(function () {
    'use strict';

    angular.module('bunge')
        .run(['$rootScope', '$state', '$window', 'TokenManagerFactory', 'AUTH_EVENTS', 'SessionManagerFactory', 'AuthenticationService', 'Flash', 'Session', 'ViewService', 'PORTAL_PUBLIC_KEYS', 'DynamicModulesLoader', ConfigBunge]);

    var verificarPaginaRestrita = function (nomeState) {
        return ['login', 'esqueci-minha-senha', 'esqueci-minha-senha-chave', 'primeiro-acesso', 'primeiro-acesso-chave'].indexOf(nomeState) === -1;
    }

    function ConfigBunge($rootScope, $state, $window, TokenManagerFactory, AUTH_EVENTS, SessionManagerFactory, AuthenticationService, Flash, Session, ViewService, PORTAL_PUBLIC_KEYS, DynamicModulesLoader) {
        $rootScope.$on('$stateChangeSuccess', function (event, next, current) {
            $rootScope.funcAtual = ViewService.GetCurrentAppFunc();

            if ($state.current.name.indexOf('administracao') > -1 || $state.current.name.indexOf('fornecedor') > -1) {
                var currentPortal = $state.current.name.indexOf("administracao") > -1 ? "administracao" : "fornecedor";
                var portal = Session.GetFirstPortalByKey(PORTAL_PUBLIC_KEYS[currentPortal]);
                var app = Session.GetFirstAppByName(currentPortal);
                if (app) {
                    document.title = "PortalBBR Bunge Brasil - " + portal.NmApp + " - " + app.descriptionApp;
                } else if (portal) {
                    document.title = "PortalBBR Bunge Brasil - " + portal.NmApp;
                }
            } else {
                document.title = "PortalBBR Bunge Brasil";
            }
            Flash.clear();
        });
        $rootScope.$on('$locationChangeSuccess', function (event, next, current) {
            TokenManagerFactory.GetToken().then(function (token) {
                var current = $rootScope.currentState ? $rootScope.currentState.name : $state.current.name;
                var restrictedPage = verificarPaginaRestrita(current);
                if (restrictedPage && !token) {
                    $rootScope.$broadcast(AUTH_EVENTS.notAuthenticated);
                    event.preventDefault();
                    $state.go('login');
                }
            });
        });
        $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
            if (!$rootScope.currentState || ($rootScope.currentState && toState.name != $rootScope.currentState.name)) {
                event.preventDefault()

                TokenManagerFactory.GetToken().then(function (token) {
                    if (!$rootScope.currentUser && token) {
                        SessionManagerFactory.CreateSession(token);
                    }

                    $rootScope.currentState = toState;
                    var current = $rootScope.currentState ? $rootScope.currentState.name : $state.current.name;
                    var restrictedPage = verificarPaginaRestrita(current);
                    if (restrictedPage && !token) {
                        $rootScope.$broadcast(AUTH_EVENTS.notAuthenticated);
                        $rootScope.currentState = fromState;
                        $state.go('login');
                        return;
                    }
                    if ($rootScope.currentUser && $rootScope.currentUser.userStatus.toLowerCase() == 'expired' && current != 'configuracoes.alterar-senha') {
                        $rootScope.currentState = fromState;
                        $state.go('configuracoes.alterar-senha');
                        return;
                    }
                    if (!restrictedPage && token) {
                        if (!($rootScope.currentUser.userStatus.toLowerCase() == 'expired' && current == 'configuracoes.alterar-senha')) {
                            $rootScope.currentState = fromState;
                            $state.go('home');
                            return;
                        }
                    }
                    $state.go(toState, toParams);
                });
            }
        });
        $rootScope.$on('REFRESH_TOKEN', function () {
            AuthenticationService.RenewToken();
        });
        $rootScope.$on('LOGOUT', function () {
            var current = $rootScope.currentState ? $rootScope.currentState.name : $state.current.name;
            var restrictedPage = verificarPaginaRestrita(current);
            if (restrictedPage) {
                AuthenticationService.ClearCredentials(true);
            }
        });
    }
})();
