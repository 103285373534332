(function () {
    'use strict';

    angular
       .module('bunge.components')
       .directive('pagFooter', function () {
           return {
               restrict: 'EA',
               templateUrl:'/app/components/footer/pagFooter.template.html',
               replace: true
           };
       });
})();

