(function () {
    'use strict';

    angular
        .module('bunge')
        .constant('CLAIMS_TYPE', {
            APP: 'app',
            PERMISSION: 'permission',
            EXP: 'exp',
            USERTYPE: 'userType'
        });
})();