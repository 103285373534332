(function() {
    'use strict';

    angular.module('bunge.modules.fornecedor.conta-corrente.nota-fiscal', [])
        .config(['$stateProvider', '$urlRouterProvider', ConfigParametros])
        .run([RunParametros]);


    var $urlRouterProviderRef = null;
    var $stateProviderRef = null;

    function ConfigParametros($stateProvider, $urlRouterProvider) {
        $urlRouterProviderRef = $urlRouterProvider;
        $stateProviderRef = $stateProvider;
    }

    function RunParametros() {
        $stateProviderRef.state('fornecedor.conta-corrente.nota-fiscal', {
            url: '/nota-fiscal',
            params: {
                'contrato': null,
                'codigoFornecedor': null,
                'codigoNucleo': null,
                'codigoProduto': null,
                'anoSafra': null
            },
            templateUrl: 'app/modules/fornecedor/submodules/conta-corrente/submodules/nota-fiscal/view/nota-fiscal.view.html',
            controller: 'FornecedorContaCorrenteNotaFiscalController',
            controllerAs: 'vm',
        });
    }
})();