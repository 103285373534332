(function () {
	'use strict';

	angular.module('bunge.components').directive('admOptions', ADM_OPTIONS);

	ADM_OPTIONS.$inject = ['NavigationService', 'Session', '$state', 'VisibilidadeMenuLateralFactory'];

	function ADM_OPTIONS(NavigationService, $rootScope, $state, VisibilidadeMenuLateralFactory) {
		return {
			restrict: 'EA',
			templateUrl: '/app/components/administration-options/administration-options.template.html',
			replace: true,
			link: function (scope, element, attrs) {
			    var appName = attrs.page;
			    setTimeout(function () { scope.options = NavigationService.GetUserSubMenu(appName); scope.$evalAsync(); }, 50);
				

				scope.retrair = retrair;

				function retrair() {
				    VisibilidadeMenuLateralFactory.setVisivel(true);
				};
				
				var getIndexActive = function () {

				    if (!scope.options) {
				        scope.options = NavigationService.GetUserSubMenu(appName);
				        scope.$evalAsync();
				    }

					var z = 0;
					
					for (var i in scope.options) {
						if ($state.current.name == 'administracao' + scope.options[i].route) {
							return z;
						} else if (scope.options[i].subRoutes) {
						    var contemSubRoute = false;
						    scope.options[i].subRoutes.forEach(function (element, index, array) {
						        if (!contemSubRoute && $state.current.name == 'administracao' + element) {
						            contemSubRoute = true;
						        }
						    });

						    if (contemSubRoute) {
						        return z;
						    }
						}
						
						z++;
					}
					
					return 0;
				};
				
				scope.toggleObject = { item: getIndexActive() };
			}
		};
	};
}());

