(function () {
     'use strict';
     
angular.module('bunge.modules.recuperar-acesso')
    .config(['$stateProvider',ConfigRecuperarAcesso]);

function ConfigRecuperarAcesso($stateProvider) {
    $stateProvider
 .state('esqueci-minha-senha', {
              url: '/esqueci-minha-senha',
              templateUrl: 'app/modules/recuperar-acesso/views/recuperar-acesso.view.html',
              controller: 'RecuperarSenhaController',
              controllerAs: 'vm'
 })
     .state('esqueci-minha-senha-chave', {
         url: '/esqueci-minha-senha/:chave',
         templateUrl: 'app/modules/recuperar-acesso/views/recuperar-acesso.view.html',
         controller: 'RecuperarSenhaController',
         controllerAs: 'vm'
     })
}
})();
