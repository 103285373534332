(function () {
    'use strict';

    angular.module('bunge.modules.fornecedor')
        .config(['$stateProvider', ConfigFornecedor]);

    function ConfigFornecedor($stateProvider) {
        $stateProvider
            .state('fornecedor', {
                url: '/fornecedor',
                templateUrl: 'app/modules/fornecedor/view/fornecedor.view.html',
                controller: 'FornecedorController',
                controllerAs: 'vm',
                resolve: { // Any property in resolve should return a promise and is executed before the view is loaded
                    depsForn: ['DynamicModulesLoader', '$rootScope', 'ModulesLoaded', function (DynamicModulesLoader, $rootScope, ModulesLoaded) {
                        // you can lazy load files for an existing module
                        if (ModulesLoaded.GetLoaded()['fornecedor']) {
                            return;
                        }
                        return DynamicModulesLoader.LoadModulesFromToken('fornecedor');

                    }]
                }
            })
    }
})();
