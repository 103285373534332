(function() {

    'use strict';

    angular
        .module('bunge.system')
        .factory('KendoDataSourceFactory', KendoDataSourceFactory);

    KendoDataSourceFactory.$inject = ['ENDPOINT_URI', 'KendoDataSourceFilterGenerator', 'TokenManagerFactory', 'AuthenticationService', '$rootScope', '$http'];

    function KendoDataSourceFactory(ENDPOINT, KendoDataSourceFilterGenerator, TokenManagerFactory, AuthenticationService, $rootScope, $http) {

        var mappers = {
            pageSize: angular.noop,
            page: angular.noop,
            filter: function(params, filter, useVersionFour) {
                if (filter) {
                    filter = toOdataFilter(filter, useVersionFour);
                    if (filter) {
                        params.$filter = filter;
                    }
                }
            },
            sort: function(params, orderby) {
                var expr = $.map(orderby, function(value) {
                    var order = value.field.replace(/\./g, "/");

                    if (value.dir === "desc") {
                        order += " desc";
                    }

                    return order;
                }).join(",");

                if (expr) {
                    params.$orderby = expr;
                }
            },
            skip: function(params, skip) {
                if (skip) {
                    params.$skip = skip;
                }
            },
            take: function(params, take) {
                if (take) {
                    params.$top = take;
                }
            }
        };

        function toOdataFilter(filter, useOdataFour) {
            var result = [],
                logic = filter.logic || "and",
                idx,
                length,
                field,
                type,
                format,
                operator,
                value,
                ignoreCase,
                filters = filter.filters;

            for (idx = 0, length = filters.length; idx < length; idx++) {
                filter = filters[idx];
                field = filter.field;
                value = filter.value;
                operator = filter.operator;

                if (filter.filters) {
                    filter = toOdataFilter(filter, useOdataFour);
                } else {
                    ignoreCase = filter.ignoreCase;
                    field = field.replace(/\./g, "/");
                    filter = odataFilters[operator];
                    if (useOdataFour) {
                        filter = odataFiltersVersionFour[operator];
                    }

                    if (operator === "isnull" || operator === "isnotnull") {
                        filter = kendo.format("{0} {1} null", field, filter);
                    } else if (operator === "isempty" || operator === "isnotempty") {
                        filter = kendo.format("{0} {1} ''", field, filter);
                    } else if (operator === odataFilters.any || operator === odataFilters.all) {
                        value = toOdataFilter(filters[idx].params, useOdataFour).replace("(", "").replace(")", "");
                        format = "{0}/{1}(child:{2})";
                        filter = kendo.format(format, field, operator, value);
                    } else if (filter && value !== undefined) {
                        type = $.type(value);
                        if (type === "string") {
                            format = "'{1}'";
                            value = value.replace(/'/g, "''");

                            if (ignoreCase === true) {
                                field = "tolower(" + field + ")";
                            }

                        } else if (type === "date") {
                            if (useOdataFour) {
                                format = "{1:yyyy-MM-ddTHH:mm:ssZ}";
                                value = kendo.timezone.apply(value, 'Etc/UTC');
                            } else {
                                format = "datetime'{1:yyyy-MM-ddTHH:mm:ss}'";
                            }
                        } else {
                            format = "{1}";
                        }

                        if (filter.length > 3) {
                            if (filter !== "substringof") {
                                format = "{0}({2}," + format + ")";
                            } else {
                                format = "{0}(" + format + ",{2})";
                                if (operator === "doesnotcontain") {
                                    if (useOdataFour) {
                                        format = "{0}({2},'{1}') eq -1";
                                        filter = "indexof";
                                    } else {
                                        format += " eq false";
                                    }
                                }
                            }
                        } else {
                            format = "{2} {0} " + format;
                        }

                        filter = kendo.format(format, filter, value, field);
                    }
                }

                result.push(filter);
            }

            filter = result.join(" " + logic + " ");

            if (result.length > 1) {
                filter = "(" + filter + ")";
            }

            return filter;
        }

        var odataFilters = {
            eq: "eq",
            neq: "ne",
            gt: "gt",
            gte: "ge",
            lt: "lt",
            lte: "le",
            contains: "substringof",
            doesnotcontain: "substringof",
            endswith: "endswith",
            startswith: "startswith",
            isnull: "eq",
            isnotnull: "ne",
            isempty: "eq",
            isnotempty: "ne",
            any: "any",
            all: "all"
        };

        var odataFiltersVersionFour = angular.extend({}, odataFilters, {
            contains: "contains"
        });

        function KendoDataSourceFactory(oArg) {
            angular.extend(this);
            this.oArg = oArg;
            this.geradorFiltros = KendoDataSourceFilterGenerator.iniciar();
            this.modelo = {};
        }

        KendoDataSourceFactory.prototype = {
            $adicionarFiltro: function(campo, operador, valor) {
                this.geradorFiltros.$adicionarFiltro(campo, operador, valor);
                return this;
            },
            $adicionarFiltroLambda: function(collection, lambdaOperator, expression) {
                this.geradorFiltros.$adicionarFiltroLambda(collection, lambdaOperator, expression);
                return this;
            },
            $setFiltro: function(filter) {
                this.geradorFiltros = filter;
            },
            $gerarDataSource: function() {
                return this.$gerarDataSourceChild(this);
            },
            $adicionarIdModelo: function(id) {
                this.modelo.id = id;
                return this;
            },
            $adicionarCampoModelo: function(campo, atributos) {
                if (!this.modelo.fields) {
                    this.modelo.fields = {};
                }
                this.modelo.fields[campo] = atributos;
                return this;
            },
            $adicionarCampoAggregate: function(campo, atributos) {
                if (!this.aggregate) {
                    this.aggregate = [];
                }
                this.aggregate.push({ field: campo, aggregate: atributos });
                return this;
            },
            $gerarDataSourceChild: function(parent) {
                return new kendo.data.DataSource({
                    type: 'odata',
                    transport: {
                        read: function(options) {
                            var params, value, option;

                            params = {
                                //$inlinecount: "allpages"
                            };

                            for (option in options.data) {
                                if (mappers[option]) {
                                    mappers[option](params, options.data[option], true);
                                } else {
                                    params[option] = options.data[option];
                                }
                            }

                            params.$count = true;

                            var query;
                            angular.forEach(params, function(value, key) {
                                if (!query) {
                                    query = "?" + key + "=" + value;
                                } else {
                                    query += "&" + key + "=" + value;

                                };
                            });
                            if (parent.oArg.lambda) {
                                query += "&" + parent.oArg.lambda;
                            }
                            if (parent.oArg.expand) {
                                query += "&$expand=" + parent.oArg.expand;
                            }
                            if (parent.oArg.apply) {
                                query += "&$apply=" + parent.oArg.apply;
                            }
                            if (parent.oArg.select) {
                                query += "&$select=" + parent.oArg.select;
                            }

                            var endpoint = parent.oArg.STS ? ENDPOINT.endpoint_stsOdata : parent.oArg.fornecedor ? ENDPOINT.endpoint_fornecedor : ENDPOINT.endpoint_backend;
                            $http.get(endpoint + parent.oArg.endpoint + query, { headers: parent.oArg.header }).then(function(data) {
                                options.success(data.data);
                            });
                        }
                    },
                    error: function(e) {
                        $rootScope.$broadcast({
                            401: 'LOGOUT',
                            403: 'LOGOUT'
                        }[e.status]);
                    },
                    aggregate: parent.aggregate,
                    schema: {
                        model: parent.modelo,

                        data: function(data) {
                            if (data['value']) {
                                var itens = data['value'];
                                itens.forEach(function(element) {
                                    delete element['@odata.type']
                                });
                                return itens;
                            } else
                                return data;
                        },
                        total: function(data) {
                            if (data['@odata.count'])
                                return data['@odata.count'];
                            else
                                return data.length;
                        }
                    },
                    change: parent.oArg.change,
                    batch: parent.oArg.batch,
                    pageSize: parent.oArg.pageSize || 10,
                    serverPaging: true,
                    serverSorting: true,
                    serverFiltering: true,
                    filter: parent.geradorFiltros.filtros.length > 0 ? parent.geradorFiltros.$gerarFiltro() : null,
                    sort: parent.oArg.sort || { field: "Id", dir: "desc" },
                    group: parent.oArg.group,
                    groupable: parent.oArg.groupable
                });
            }
        };

        function iniciar(oArg) {
            return new KendoDataSourceFactory(oArg);
        }

        function gerarDataSource(oArg) {
            return new kendo.data.DataSource({
                type: 'odata-v4',
                transport: {
                    read: function(options) {
                        var params, value, option;

                        params = {
                            //$inlinecount: "allpages"
                        };

                        for (option in options.data) {
                            if (mappers[option]) {
                                mappers[option](params, options.data[option], true);
                            } else {
                                params[option] = options.data[option];
                            }
                        }

                        params.$count = true;

                        var query;
                        angular.forEach(params, function(value, key) {
                            if (!query) {
                                query = "?" + key + "=" + value;
                            } else {
                                query += "&" + key + "=" + value;
                            }
                        });
                        var endpoint = parent.oArg.STS ? ENDPOINT.endpoint_stsOdata : parent.oArg.fornecedor ? ENDPOINT.endpoint_fornecedor : ENDPOINT.endpoint_backend;
                        $http.get(endpoint + oArg.endpoint + query).then(function(data) {
                            options.success(data.data);
                        });
                    },
                },
                error: function(e) {
                    $rootScope.$broadcast({
                        401: 'LOGOUT',
                        403: 'LOGOUT'
                    }[e.status]);
                },
                schema: {
                    data: function(data) {
                        if (data['value'])
                            return data['value'];
                        else
                            return data;
                    },
                    total: function(data) {
                        if (data['@odata.count'])
                            return data['@odata.count'];
                        else
                            return data.length;
                    },
                    parse: function(data) {
                        console.log(data);
                    }
                },
                change: oArg.change,
                batch: oArg.batch,
                pageSize: oArg.pageSize || 10,
                serverPaging: true,
                serverSorting: true,
                serverFiltering: true,
                filter: geradorFiltros.filtros.length > 0 ? geradorFiltros.$gerarFiltro() : null,
                sort: { field: "Id", dir: "desc" }
            });
        }

        return {
            gerarDataSource: gerarDataSource,
            iniciar: iniciar
        };
    }
})();