if (window.location.pathname !== '/') {
    window.location.href = '/#' + window.location.pathname;
}

(function () {
    'use strict';

    angular
        .module('bunge.modules.alterar-senha')
        .controller('AlterarSenhaController', AlterarSenhaController);

    AlterarSenhaController.$inject = ['$rootScope', 'Flash', 'ResetPasswordService', '$scope', '$state', 'ViewService'];
    function AlterarSenhaController($rootScope, Flash, ResetPasswordService, $scope, $state, ViewService) {
        var vm = this;


        $rootScope.currentApp = ViewService.GetViewPublicKey('administracao');

        vm.init = function () {
            if ($rootScope.currentUser.userType == 2) {
                $state.go('home');
            }
        };
        vm.init();
        vm.salvar = function () {
            if (!vm.password || !vm.newPassword || !vm.confirmPassword) {
                var id = Flash.create('danger', "Todos os campos são obrigatórios.", 8000);
                return;
            }

            if ((vm.newPassword != vm.confirmPassword)) {
                var id = Flash.create('danger', "O campo senha e confirmação de senha devem ser iguais.", 8000);
                return;
            }

            if (vm.newPassword.length < 6) {
                var id = Flash.create('danger', "A senha deve ter mais que 6 caracteres.", 8000);
                vm.confirmPassword = '';
                vm.newPassword = '';
                return;
            }

            var res1 = new RegExp("[A-Z]").test(vm.newPassword);
            var res2 = new RegExp("[a-z]").test(vm.newPassword);
            var res3 = new RegExp("[0-9]").test(vm.newPassword);
            var res4 = new RegExp("[^a-zA-Z0-9]").test(vm.newPassword);
  

            if(!(res1&&res2&&res3&&res4)){
                var id = Flash.create('danger', "A senha deve ter mais de 6 caracteres e conter números, caracteres especiais, letras maiúsculas e minúsculas. A senha não pode ser iqual as 3 ultimas senhas.", 8000);       
                vm.confirmPassword = '';
                vm.newPassword = '';
                return; 
            }
    
            var req = {
                Login: $rootScope.currentUser.userName,
                Password: vm.password,
                NewPassword: vm.confirmPassword
            }

            ResetPasswordService.resetPassword(req).then(function (result) {
                $rootScope.$broadcast('REFRESH_TOKEN');
                vm.limpar();
                var id = Flash.create('success', "Atualizado com sucesso!", 5000);
            }, function (result) {
                
                if(result.status===417){
                    var id = Flash.create('danger', "Senha atual incorreta", 5000);
                    vm.password = '';
                }else if(result.status===404){
                    var id = Flash.create('danger', "A senha deve ter mais de 6 caracteres e conter números, caracteres especiais, letras maiúsculas e minúsculas. A senha não pode ser iqual as 3 ultimas senhas.", 8000);
                    vm.confirmPassword = '';
                    vm.newPassword = '';
                }
                else{
                    var id = Flash.create('danger', "Falha ao realizar alterações.", 5000);
                }
            });
        };

        vm.limpar = function () {
            vm.password = '';
            vm.newPassword = '';
            vm.confirmPassword = '';
            vm.form.$setPristine();
        }
    }
})();