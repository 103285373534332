(function () {
    'use strict';

    angular.module('bunge.modules.fornecedor').controller('FornecedorController', FornecedorController);

    FornecedorController.$inject = ['$rootScope', 'NavigationService', 'ViewService', 'VisibilidadeMenuLateralFactory'];
    function FornecedorController($rootScope, NavigationService, ViewService, VisibilidadeMenuLateralFactory) {
        var vm = this;

        $rootScope.currentApp = ViewService.GetViewPublicKey('fornecedor');

        ViewService.GoToFirstView('fornecedor');

        if ($rootScope.navPermissions.length == 0)
            NavigationService.BuildUserMenu();

        vm.sideBarMenuVisivel = sideBarMenuVisivel;

        vm.expandir = expandir;

        function sideBarMenuVisivel() {
            return VisibilidadeMenuLateralFactory.getVisivel();
        }

        function expandir() {
            VisibilidadeMenuLateralFactory.setVisivel(true);
        }
    }

})();