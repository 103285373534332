(function() {
    'use strict';

    angular.module('bunge.modules.fornecedor.contratos', [])
        .config(['$stateProvider', '$urlRouterProvider', ConfigParametros])
        .run([RunParametros]);


    var $urlRouterProviderRef = null;
    var $stateProviderRef = null;

    function ConfigParametros($stateProvider, $urlRouterProvider) {
        $urlRouterProviderRef = $urlRouterProvider;
        $stateProviderRef = $stateProvider;
    }

    function RunParametros() {
        $stateProviderRef.state('fornecedor.contratos', {
            url: '/contratos',
            params: {
                filtros: null
            },
            templateUrl: 'app/modules/fornecedor/submodules/contratos/view/contratos.view.html',
            controller: 'FornecedorContratosController',
            controllerAs: 'vm',
        });
    }
})();