(function () {

    'use strict';

    angular
        .module('bunge.system')
        .service('AuthenticationService', AuthenticationService);

    AuthenticationService.$inject = ['SessionManagerFactory', 'ENDPOINT_URI', '$resource', 'TokenManagerFactory', '$state', 'UserManagerFactory','$http'];
    function AuthenticationService(SessionManagerFactory, ENDPOINT_URI, $resource, TokenManagerFactory, $state, UserManagerFactory, $http) {

        var Program = $resource(ENDPOINT_URI.endpoint_sts + 'authenticate', {}, {
            gerarToken: {
                url: ENDPOINT_URI.endpoint_sts + 'user-authenticate',
                method: 'POST',
                transformResponse: function (data, headersGetter, status) {
                    return { content: data ? data.replace(/^"(.*)"$/, '$1') : data };
                }
            },
            renovarToken: {
                url: ENDPOINT_URI.endpoint_sts + 'token-refresh',
                method: 'POST',
                transformResponse: function (data, headersGetter, status) {
                    return { content: data ? data.replace(/^"(.*)"$/, '$1') : data };
                }
            }
        });

        function Login(user) {
            var program = new Program();
            program.login = user.login;
            program.password = user.password;
            return program.$gerarToken().then(function (res) {
               var token = res.content;
                return $http.get(ENDPOINT_URI.endpoint_backendReq + 'fornecedor/verificar-usuario/'+user.login+'/',{
                    headers: { 'Authorization': 'Bearer ' + token }
                }).then(function (resVerificar) {
                    SetCredentials(token, JSON.stringify(resVerificar.data));
                    token = null;
                    resVerificar.status = 200;
                    return resVerificar;
                }, function (resVerificar) {
                    token = null;
                    if (!resVerificar.data.message && !resVerificar.data.Message) {
                        try {
                            JSON.parse(resVerificar.data);
                        } catch (e) {
                            var msg = resVerificar.data;
                            resVerificar.data = {message:msg};
                        }
                    }
                    return resVerificar;
                });
            }, function (res) {
                return res;
            });
        }

        function SetCredentials(token, usuario) {
            if (usuario) {
                UserManagerFactory.SetUser(usuario);
            }
            SessionManagerFactory.CreateSession(token);
            TokenManagerFactory.SetToken(token);
        }

        function ClearCredentials(redirect) {
            TokenManagerFactory.RemoveToken();
            UserManagerFactory.RemoveUser();
            // $http.defaults.headers.common.Authorization = 'Basic';
            SessionManagerFactory.DestroySession();
            if (redirect) {
                $state.go('login');
            }
        }

        function RenewToken() {
            var program = new Program();
            return program.$renovarToken().then(function (res) {
                SetCredentials(res.content);
                return true;
            }, function (res) {
                return false;
            });
        }

        return {
            Login: Login,
            SetCredentials: SetCredentials,
            ClearCredentials: ClearCredentials,
            RenewToken: RenewToken
        };
    }
})();