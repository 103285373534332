(function () {
	'use strict';

	angular.module('bunge').factory('ADM_NAV_OPTIONS', ADM_NAV_OPTIONS);

	ADM_NAV_OPTIONS.$inject = ['USER_ROLES'];
	
	function ADM_NAV_OPTIONS(USER_ROLES) {
		return {
			parametros: {
				route: '.parametros',
				legenda: 'PARÂMETROS',
				publicKey: "ADM0001",
				internalKey: "parametros",
				showPublicKey: true,
                order: 1
			},
			consultarRegistroLog: {
				route: '.consultar-registro-acesso',
				legenda: 'CONSULTAR REGISTROS DE ACESSO',
				publicKey: "ADM0003",
				internalKey: "consultar-registro-acesso",
				showPublicKey: true,
				order: 2
			},
			UsuarioExterno: {
				route: '.usuario-externo',
				legenda: 'USUÁRIO EXTERNO',
				publicKey: "ADM0004",
				internalKey: "usuario-externo",
				showPublicKey: true,
				order: 3
			},
			UsuariosPorFornecedor: {
			    route: '.usuarios-por-fornecedor',
				legenda: 'USUÁRIOS POR FORNECEDOR',
				publicKey: "ADM0005",
				internalKey: "usuarios-por-fornecedor",
				subRoutes: ['.usuarios-por-fornecedor.consultar-usuarios',
           '.usuarios-por-fornecedor.adicionar-usuario', '.usuarios-por-fornecedor'
				]
				, showPublicKey: true,
				order: 4
			},
            AdministradorDeAplicacao: {
                route: '.administrador-de-aplicacao',
                legenda: 'ADMINISTRADOR DE APLICAÇÃO',
                publicKey: 'ADM0008',
                internalKey: 'administrador-de-aplicacao'
				, showPublicKey: true,
                order: 5
            },
            ManterPortal: {
				route: '.manter-portal',
				legenda: 'MANTER PORTAL',
				publicKey: 'ADM0006',
				internalKey: 'manter-portal'
				, showPublicKey: true,
				order: 6
			},
            ManterAplicacao: {
                route: '.manter-aplicacao',
                legenda: 'MANTER APLICAÇÃO',
                publicKey: 'ADM0009',
                internalKey: 'manter-aplicacao'
				, showPublicKey: true,
                order: 7
            },
            ManterFuncionalidade: {
				route: '.manter-funcionalidade',
				legenda: 'MANTER FUNCIONALIDADE',
				publicKey: 'ADM0007',
				internalKey: 'manter-funcionalidade'
				, showPublicKey: true,
				order: 8
			},
			AdministradorDePerfil: {
                route: '.manter-perfil',
                legenda: 'PERMITIR LIBERAÇÃO DE ACESSO',
                publicKey: "ADM0002",
                internalKey: "manter-perfil",
				showPublicKey: true,
				onlyAdmin: true,
				order: 9
            },
            UsuarioPerfil: {
				route: '.usuario-perfil',
				legenda: 'USUÁRIO PERFIL',
				publicKey: 'ADM0010',
				internalKey: 'usuario-perfil',
				onlyAdmin: true,
				showPublicKey: true,
				order: 10
			},
            AcessoEmpresaFilial: {
                route: '.acesso-empresa-filial',
                legenda: 'ACESSO A EMPRESA E FILIAL',
                publicKey: "ADM0011",
                internalKey: "acesso-empresa-filial",
                onlyAdmin: true,
                showPublicKey: true,
                order: 11
            }
            
		};
	}
})();