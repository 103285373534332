(function () {
    'use strict';

    angular.module('bunge.system').service('ViewService', viewService);
    viewService.$inject = ['Session', '$state'];

    function viewService(Session, $state) {
        function goToFirstView(appName) {
            var subMenus = [];
            if ($state.current.name == appName) {
                var apps = Session.GetAppsByName(appName);
                for (var appIndex in apps) {
                    var subMenu = apps[appIndex].menu.sort(function (a, b) {
                        return a.order - b.order;
                    })[0];

                    subMenus.push(subMenu);
                }

                var lowestMenu = subMenus.sort(function (a, b) {
                    return a.order - b.order;
                })[0];

                if (lowestMenu) {
                    $state.go(lowestMenu.route);
                } else {
                    $state.go('home');
                }
            }
        }

        function getViewPublicKey(appName) {
            var app = Session.GetFirstAppByName(appName);
            var publicKey = null;

            if (app)
                publicKey = app.publicKey;

            return publicKey;
        }

        function getCurrentAppFunc() {
            var retorno;
            var app;
            var ehModulo = false;
            if ($state.current.name.indexOf("administracao") > -1 || $state.current.name.indexOf("fornecedor") > -1) {
                ehModulo = true;
                var apps = Session.GetAppsByName($state.current.name.indexOf("administracao") > -1 ? "administracao" : "fornecedor");
                for (var appIndex in apps) {
                    app = apps[appIndex];
                    for (var indexFunc in app.menu) {
                        if ($state.current.name.indexOf(app.menu[indexFunc].route) > -1) {
                            retorno = app.menu[indexFunc];
                        }
                    }
                }
            }
            if ((app && !retorno) || (ehModulo && !app)) {
                //   $state.go('home');
                return;
            }
            return retorno;
        };

        return {
            GoToFirstView: goToFirstView,
            GetViewPublicKey: getViewPublicKey,
            GetCurrentAppFunc: getCurrentAppFunc
        };
    }

}());