(function() {

    angular
        .module('bunge.modules.fornecedor.adm')
        .controller('FornecedorAdministracaoController', FornecedorAdministracaoController);

    FornecedorAdministracaoController.$inject = ['KendoDataSourceFactory', 'FornecedorAdministracaoService', '$scope', 'HelperFactory', 'Flash', '$compile', '$timeout'];

    function FornecedorAdministracaoController(KendoDataSourceFactory, FornecedorAdministracaoService, $scope, HelperFactory, Flash, $compile, $timeout) {
        var vm = this;
        var gridToolbar = HelperFactory.getAdminGridToolbar('fornecedor', 'administracao');
        var gridDeleteButton = HelperFactory.getAdminDeleteButtonTemplate('fornecedor', 'administracao');

        vm.usuariosDataSource = {
            filter: "contains",
            minLength: 3,
            dataTextField: 'DescricaoFornecedor',
            dataSource: KendoDataSourceFactory.iniciar({
                endpoint: 'Fornecedor',
                fornecedor: true,
                sort: { field: "CodigoFornecedor", dir: "desc" }
            }).$gerarDataSource(),
            autoBind: false,
            change: comboUserOnChange
        };

        function comboUserOnChange() {
            limparTabela();
            vm.fornecedorSelecionado = this.dataItem(this.select());
            $scope.$evalAsync();
        }

        function limparTabela() {
            if (vm.grid) {
                vm.grid.dataSource.data([]);
            }
        }

        vm.filtrar = function() {
            var ds = KendoDataSourceFactory.iniciar({
                endpoint: 'RelacaoFornecedor',
                fornecedor: true,
                pageable: false,
                pageSize: 999,
                sort: { field: "DocumentoFornecedorDestino", dir: "asc" }
            });

            ds.$adicionarFiltro('CodigoFornecedorOrigem', 'eq', vm.fornecedorSelecionado.CodigoFornecedor);
            vm.grid.setDataSource(ds.$gerarDataSource());
        };

        vm.limpar = function() {
            limparTabela();
            limparFornecedor();
        };

        function limparFornecedor() {
            vm.fornecedorSelecionado = undefined;
            vm.fornecedores.value('');
            vm.fornecedores.text('');
        }

        vm.excluir = function(data) {
            var confirmation = HelperFactory.alertaConfirma("Você deseja realmente deletar o fornecedor " + data.DocumentoFornecedorDestino + " - " + data.NomeFornecedorDestino + "?");

            confirmation.then(function(result) {
                if (!!result) {
                    FornecedorAdministracaoService.removeRelacaoFornecedor(data.CodigoRelacaoFornecedor)
                        .success(function(data) {
                            $scope.vm.filtrar();
                            Flash.create('success', "Excluído com sucesso!");
                        }).error(function(data) {
                            Flash.create('danger', data.exceptionMessage);
                        });
                }
            });
        }

        vm.comboBoxFornecedorDataSource = {
            dataTextField: 'DescricaoFornecedor',
            dataValueField: 'CodigoFornecedor',
            change: comboBoxFornecedorOnChange,
            filter: "contains",
            minLength: 3
        }

        function comboBoxFornecedorOnChange(evt, selected, data) {
            var dataItem = this.dataItem(this.select());
            var model = angular.copy(dataItem);

            $scope.vm.novoFornecedorSelecionado = model;
            $scope.$evalAsync()
        }

        function gridFornecedorEditor(container, options) {
            var dataSource = KendoDataSourceFactory
                .iniciar({
                    endpoint: 'Fornecedor',
                    fornecedor: true,
                    sort: { field: "CodigoFornecedor", dir: "desc" }
                })
                .$adicionarFiltro('CodigoFornecedor', 'ne', $scope.vm.fornecedorSelecionado.CodigoFornecedor)
                .$gerarDataSource();

            var element = angular.element('<select kendo-combo-box="vm.comboNovoFornecedor" maxlength="200"  ' +
                'k-placeholder="\'Selecione o fornecedor...\'" ' +
                'options="vm.comboBoxFornecedorDataSource"></select>');
            element.appendTo(container);

            $compile(angular.element(container).contents())($scope);
            $timeout(function() {
                $scope.vm.comboNovoFornecedor.setDataSource(dataSource);
                $scope.$evalAsync();
            }, 100);
        }

        function gridSave(data) {
            data.preventDefault();
            if ($scope.vm.novoFornecedorSelecionado) {
                var codigoFornecedorOrigem = $scope.vm.fornecedorSelecionado.CodigoFornecedor;
                var codigoFornecedorDestino = $scope.vm.novoFornecedorSelecionado.CodigoFornecedor;

                FornecedorAdministracaoService
                    .addRelacaoFornecedor(codigoFornecedorOrigem, codigoFornecedorDestino)
                    .then(function() {
                        Flash.create('success', "Salvo com sucesso!");
                        $scope.vm.filtrar();
                        $scope.vm.novoFornecedorSelecionado = undefined;
                    }, function(data) {
                        Flash.create('danger', data.data.exceptionMessage);
                    });
            } else {
                Flash.create('danger', "Preencha todos os campos para inclusão.");
                $scope.$evalAsync();
                return;
            }
        }

        vm.paramsGridOptions = function() {
            return {
                editable: "inline",
                resizable: true,
                filterable: true,
                sortable: true,
                columnMenu: true,
                noRecords: {
                    template: "Nenhum item encontrado"
                },
                toolbar: gridToolbar,
                columns: [{
                    title: 'Fornecedor',
                    field: 'DocumentoFornecedorDestino',
                    template: '#= data.DocumentoFornecedorDestino + " - " + data.NomeFornecedorDestino #',
                    width: "90%",
                    editor: gridFornecedorEditor
                }, {
                    headerAttributes: { style: "text-align: center" },
                    attributes: { style: "text-align: center" },
                    width: '10%',
                    title: "Ações",
                    command: ['edit', { name: 'excluir', template: gridDeleteButton }]
                }],
                messages: {
                    commands: {
                        edit: " ",
                        update: " ",
                        destroy: " ",
                        canceledit: " "
                    }
                },
                dataBound: function(e) {
                    e.sender.element.find(".k-grid-edit").css("display", "none");
                },
                save: gridSave
            }
        }

    }
})();