(function() {
    'use strict';

    angular.module('bunge').factory('FOR_CC_TABS_CONSTANT', FOR_CC_TABS_CONSTANT);

    function FOR_CC_TABS_CONSTANT() {
        return [{
                order: 1,
                route: 'fornecedor.conta-corrente.recebimentos',
                legenda: 'RECEBIMENTOS'
            },
            {
                order: 2,
                route: 'fornecedor.conta-corrente.nota-fiscal',
                legenda: 'NOTA FISCAL'
            },
            {
                order: 3,
                route: 'fornecedor.conta-corrente.fixacao',
                legenda: 'FIXAÇÕES'
            },
            {
                order: 4,
                route: 'fornecedor.conta-corrente.pagamento',
                legenda: 'PAGAMENTOS'
            },
            {
                order: 5,
                route: 'fornecedor.conta-corrente.saldo-contrato',
                legenda: 'SALDO CONTRATOS'
            }
        ];
    }
})();