(function(){
	'use strict';

	angular.module('bunge').factory('APP_TOKEN_NAMES', APP_TOKEN_NAMES);

	// PublicKey: InternalApplicationName
	function APP_TOKEN_NAMES(){
		return {
			'EDI': 'edi',
			'OCW': 'ordem-carga',
			'AGD': 'agendamento',
			'MON': 'monitoramento',
			'ADM': 'portaladministrador',
			'TEF': 'tempo-em-fila',
			'FOR': 'fornecedor'
		};
	} 
}());