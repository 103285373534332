(function () {
    'use strict';

    angular
       .module('bunge.components')
       .directive('modalAdicionarUsuario', function () {
           return {
               restrict: 'EA',
               transclude: true,
               scope: {
                   model: "=",
                   onRemove: "&"
               },
               controller: ['Flash', '$uibModal', '$scope', '$uibModalStack', 'ModalAdicionarUsuarioService', 'KendoDataSourceFactory', function (Flash, $uibModal, $scope, $uibModalStack, ModalAdicionarUsuarioService, KendoDataSourceFactory) {
                   $scope.save = function () {
                       var data = !$scope.model.Id ? {
                           CodigoFornecedor: $scope.model.DocumentoFornecedor,
                           Email: $scope.model.Email,
                           Nome: $scope.model.Nome,
                           NumeroDocumento: $scope.model.NumeroDocumento,
                       } : {
                           CodigoUsuario: $scope.model.idUsuarioFornecedor,
                           CodigoFornecedor: $scope.model.DocumentoFornecedor,
                           Email: $scope.model.Email,
                           Nome: $scope.model.Nome,
                           NumeroDocumento: $scope.model.NumeroDocumento,
                           Id: $scope.model.Id
                       };

                       var promise = $scope.model.Id ? ModalAdicionarUsuarioService.editar(data) : ModalAdicionarUsuarioService.criarNovo(data);
                       promise.then(function () {                           
                           $uibModalStack.dismissAll();
                           Flash.create('success', "Salvo com sucesso!");
                       }, function (motivo) {
                           Flash.clear();
                           Flash.create('danger', motivo.data.message, 8000);
                       });
                   };

                   $scope.registroNovo = function () {
                       return !$scope.model.Id;
                   }

                   $scope.pesquisarCPF = function () {
                       var dSource = KendoDataSourceFactory.iniciar({
                           endpoint: 'PessoaOData', pageSize: 1
                       }).$adicionarFiltro('TipoFornecedor', 'eq', 2).$adicionarFiltro('Login', 'eq', $scope.model.NumeroDocumento).$gerarDataSource();
                       dSource.read().then(function () {
                           if (dSource.data().length > 0) {
                               $scope.model.Nome = dSource.data()[0].Nome;
                               $scope.model.Email = dSource.data()[0].Email;
                           } else {
                               $scope.model.Nome = "";
                               $scope.model.Email = "";
                           }
                       });
                   };

                   $scope.close = function () {
                       $uibModalStack.dismissAll();
                   }

                   var modalInstance = $uibModal.open({
                       animation: true,
                       scope: $scope,
                       templateUrl: '/app/components/modal-adicionar-usuario/modal-adicionar-usuario.template.html',
                   });

                   modalInstance.result.then(function (selectedItem) {
                   }, function () {
                       $scope.onRemove();
                   });
               }],
           };
       });
})();
