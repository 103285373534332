(function() {

    angular
        .module('bunge.modules.fornecedor.conta-corrente.pagamento')
        .controller('FornecedorContaCorrentePagamentoController', FornecedorContaCorrentePagamentoController);

    FornecedorContaCorrentePagamentoController.$inject = ['KendoDataSourceFactory', '$stateParams', 'ContaCorrenteParamsFactory', 'HelperFactory'];

    function FornecedorContaCorrentePagamentoController(KendoDataSourceFactory, $stateParams, ContaCorrenteParamsFactory, HelperFactory) {

        var vm = this;

        vm.paramsGridOptions = function(dataItem) {
            var dataSourceParams = {
                endpoint: 'ContratoPagamento',
                fornecedor: true,
                pageable: false,
                pageSize: 999,
                sort: {
                    field: "ValorCotacao",
                    dir: "asc"
                },
                header: ContaCorrenteParamsFactory.getHeader($stateParams)
            }

            var ds = KendoDataSourceFactory.iniciar(dataSourceParams);
            ds.$adicionarCampoModelo("DataPagamento", { type: "date" });
            ds.$adicionarCampoModelo("ValorCotacao", { type: "number" });
            ds.$adicionarCampoModelo("ValorPagamento", { type: "number" });
            ds.$adicionarCampoModelo("ValorProgramado", { type: "number" });
            ds.$adicionarCampoModelo("ValorProgramadoReal", { type: "number" });
            ds.$adicionarCampoModelo("ValorProgramadoDolar", { type: "number" });

            ds.$adicionarCampoAggregate("ValorPagamento", "sum");
            ds.$adicionarCampoAggregate("ValorProgramadoReal", "sum");
            ds.$adicionarCampoAggregate("ValorProgramadoDolar", "sum");

            return {
                resizable: true,
                filterable: true,
                sortable: true,
                columnMenu: true,
                dataSource: ds.$gerarDataSource(),
                excel: {
                    allPages: false,
                    fileName: 'Pagamentos.csv'
                },
                toolbar: [{
                    name: 'excel',
                    template: "<kendo-button class='btn btn-default k-grid-excel'>Gerar Excel</kendo-button>"
                }],
                dataBound: function(e) {
                    HelperFactory.mergeFooterTemplate(e, 0, 3);
                    HelperFactory.moveGridToolbarToFooter("#gridPagamento");
                    var tfooter = e.sender.footer[0];
                    var trFooterClone = e.sender.footer[0].childNodes[0].cloneNode(true);
                    var firstDataItem = e.sender._data[0];
                    trFooterClone.childNodes[0].innerHTML = 'TOTAL US$';
                    trFooterClone.childNodes[3].innerHTML = '';

                    try {
                        ValorProgramadoDolar = e.sender.dataSource._aggregateResult.ValorProgramadoDolar.sum;
                        trFooterClone.childNodes[1].innerHTML = kendo.toString(ValorProgramadoDolar, 'n2');
                    } catch (error) {
                        trFooterClone.childNodes[1].innerHTML = '0';
                    }
                    tfooter.append(trFooterClone);
                },
                columns: [{
                        title: 'Situação',
                        field: 'SituacaoPagamento',
                        footerTemplate: "TOTAL R$",
                        footerAttributes: { style: "text-align: center" }
                    },
                    {
                        title: 'Data Pagamento',
                        field: 'DataPagamento',
                        // template: '#= kendo.toString(kendo.parseDate(data.DataPagamento,"yyyy-MM-ddTHH:mm:ss"),"dd/MM/yyyy")#',
                        template: '#if(data.DataPagamento) {# #= kendo.toString(kendo.parseDate(data.DataPagamento,"yyyy-MM-ddTHH:mm:ss"),"dd/MM/yyyy")# #}#',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: center" }
                    },
                    {
                        title: 'Moeda',
                        field: 'MoedaProgramado',
                        attributes: { style: 'text-align: center' }
                    },
                    {
                        title: 'Valor da Moeda',
                        field: 'ValorProgramado',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: right" },
                        template: "#if(data.ValorProgramado) {# #=kendo.toString (data.ValorProgramado,'n2')# #}else{#0#}#",
                        footerTemplate: function(data) {
                            return data.ValorProgramadoReal && data.ValorProgramadoReal.sum ? kendo.toString(data.ValorProgramadoReal.sum, 'n2') : 0
                        },
                        footerAttributes: { style: "text-align: right" }
                    },
                    {
                        title: 'Cotação US$',
                        field: 'ValorCotacao',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: right" },
                        template: "#if(data.ValorCotacao) {# #=kendo.toString (data.ValorCotacao,'n3')# #}else{#0#}#"
                    },
                    {
                        title: 'Valor Pagamento R$',
                        field: 'ValorPagamento',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: right" },
                        template: "#if(data.ValorPagamento) {# #=kendo.toString (data.ValorPagamento,'n2')# #}else{#0#}#",
                        footerTemplate: function(data) {
                            return data.ValorPagamento && data.ValorPagamento.sum ? kendo.toString(data.ValorPagamento.sum, 'n2') : 0
                        },
                        footerAttributes: { style: "text-align: right" }
                    },
                    {
                        title: 'Banco',
                        field: 'Banco'
                    },
                    {
                        title: 'Agência',
                        field: 'Agencia',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: center" }
                    },
                    {
                        title: 'Conta Corrente',
                        field: 'ContaCorrente',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: center" }
                    },
                    {
                        title: 'Favorecido',
                        field: 'NomeFavorecido'
                    },
                    {
                        title: 'Contrato',
                        field: 'ContratoCompraMTP',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: center" }
                    },
                    {
                        title: 'Filial Pagto.',
                        field: 'CodigoFilial',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: center" }
                    },
                    {
                        title: 'Nr. Pagamento',
                        field: 'NumeroPagamento',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: center" }
                    }
                ],
            }
        };
    }
})();