(function() {
    'use strict';

    angular
        .module('bunge.modules.fornecedor.conta-corrente.nota-fiscal')
        .directive('modalDetalheNota', ModalDetalheNota);

    function ModalDetalheNota() {
        return {
            restrict: 'EA',
            transclude: true,
            scope: {
                model: "=",
                onRemove: "&"
            },
            controller: ['$uibModal', '$scope', '$uibModalStack', 'HelperFactory', 'Flash', '$http', 'ENDPOINT_URI', 'KendoDataSourceFactory', ModalDetalheNotaController]
        };
    }

    function ModalDetalheNotaController($uibModal, $scope, $uibModalStack, HelperFactory, Flash, $http, ENDPOINT_URI, KendoDataSourceFactory) {
        function iniciar() {
            Flash.clear();

            var ds = KendoDataSourceFactory.iniciar({
                endpoint: 'NotaFiscalFornecedor',
                fornecedor: true,
                pageable: false,
                pageSize: 999,
                sort: { field: "CodigoNotaFiscal", dir: "asc" },
                header: $scope.model.header
            });

            ds.$adicionarCampoModelo("DataEmissao", { type: "date" });
            ds.$adicionarCampoModelo("PesoNota", { type: "number" });
            ds.$adicionarCampoModelo("Preco", { type: "number" });
            ds.$adicionarCampoModelo("ValorTotalNota", { type: "number" });
            ds.$adicionarCampoModelo("ValorTotalNota", { type: "number" });
            ds.$adicionarCampoModelo("ValorContribuicaoSocial", { type: "number" });
            ds.$adicionarCampoModelo("ValorFethabFund", { type: "number" });
            ds.$adicionarCampoModelo("ValorIcms", { type: "number" });


            ds.$adicionarCampoAggregate("PesoNota", "sum");
            ds.$adicionarCampoAggregate("ValorTotalNota", "sum");
            ds.$adicionarCampoAggregate("ValorContribuicaoSocial", "sum");
            ds.$adicionarCampoAggregate("ValorFethabFund", "sum");
            ds.$adicionarCampoAggregate("ValorIcms", "sum");

            $scope.paramsGridOptions = {
                resizable: true,
                filterable: true,
                sortable: true,
                columnMenu: true,
                dataSource: ds.$gerarDataSource(),
                dataBound: function(e) {
                    HelperFactory.mergeFooterTemplate(e, 0, 6);
                    HelperFactory.moveGridToolbarToFooter("#gridDetalheNota");
                },
                excel: {
                    allPages: false,
                    fileName: 'Nota Fiscal - Detalhe.csv'
                },
                toolbar: [{
                    name: 'voltar',
                    template: '<button class="btn btn-grey-custom" ng-click="close()" type="button">FECHAR</button>'
                }, {
                    name: 'excel',
                    template: "<button class='btn btn-grey-custom k-grid-excel'>Gerar Excel</button>"
                }],
                columns: [{
                        title: 'NF',
                        field: 'NumeroNota',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: center" },
                        footerTemplate: "TOTAL",
                        footerAttributes: { style: "text-align: center" }
                    },
                    {
                        title: 'Serie',
                        field: 'SerieNota',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: center" }
                    },
                    {
                        title: 'Nota Bunge',
                        field: 'NumeroNotaBunge',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: center" }
                    },
                    {
                        title: 'Serie Bunge',
                        field: 'SerieNotaBunge',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: center" }
                    },
                    {
                        title: 'Data',
                        field: 'DataEmissao',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: center" },
                        template: '#= kendo.toString(kendo.parseDate(data.DataEmissao,"yyyy-MM-ddTHH:mm:ss"),"dd/MM/yyyy")#'
                    },
                    {
                        title: 'Tipo',
                        field: 'TipoNota',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: center" }
                    },
                    {
                        title: 'Peso (kg)',
                        field: 'PesoNota',
                        aggregates: ["sum"],
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: right" },
                        template: "#if(data.PesoNota) {# #=kendo.toString (data.PesoNota,'n0')# #}else{#0#}#",
                        footerTemplate: function(data) {
                            return data.PesoNota && data.PesoNota.sum ? kendo.toString(data.PesoNota.sum, 'n0') : 0
                        },
                        footerAttributes: { style: "text-align: right" }
                    },
                    {
                        title: 'Preço R$',
                        field: 'Preco',
                        template: "#if(data.Preco) {# #=kendo.toString (data.Preco,'n2')# #}else{#0#}#",
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: right" }
                    },
                    {
                        title: 'Valor R$',
                        field: 'ValorTotalNota',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: right" },
                        template: "#if(data.ValorTotalNota) {# #=kendo.toString (data.ValorTotalNota,'n2')# #}else{#0#}#",
                        footerTemplate: function(data) {
                            return data.ValorTotalNota && data.ValorTotalNota.sum ? kendo.toString(data.ValorTotalNota.sum, 'n2') : 0
                        },
                        footerAttributes: { style: "text-align: right" }
                    },
                    {
                        title: 'Contra. Social R$',
                        field: 'ValorContribuicaoSocial',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: right" },
                        template: "#if(data.ValorContribuicaoSocial) {# #=kendo.toString (data.ValorContribuicaoSocial,'n2')# #}else{#0#}#",
                        footerTemplate: function(data) {
                            return data.ValorContribuicaoSocial && data.ValorContribuicaoSocial.sum ? kendo.toString(data.ValorContribuicaoSocial.sum, 'n2') : 0
                        },
                        footerAttributes: { style: "text-align: right" }
                    },
                    {
                        title: 'FETHAB/FUND. R$',
                        field: 'ValorFethabFund',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: right" },
                        template: "#if(data.ValorFethabFund) {# #=kendo.toString (data.ValorFethabFund,'n2')# #}else{#0#}#",
                        footerTemplate: function(data) {
                            return data.ValorFethabFund && data.ValorFethabFund.sum ? kendo.toString(data.ValorFethabFund.sum, 'n2') : 0
                        },
                        footerAttributes: { style: "text-align: right" }
                    },
                    {
                        title: 'ICMS R$',
                        field: 'ValorIcms',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: right" },
                        template: "#if(data.ValorIcms) {# #=kendo.toString (data.ValorIcms,'n2')# #}else{#0#}#",
                        footerTemplate: function(data) {
                            return data.ValorIcms && data.ValorIcms.sum ? kendo.toString(data.ValorIcms.sum, 'n2') : 0
                        },
                        footerAttributes: { style: "text-align: right" }
                    },
                    {
                        title: 'TRANSG.',
                        field: 'Gmo'
                    },
                    {
                        title: 'OP',
                        field: 'NumeroCfop',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: center" }
                    }
                ]
            };
        }

        iniciar();

        $scope.close = function() {
            $uibModalStack.dismissAll();
        }

        var modalInstance = $uibModal.open({
            animation: true,
            scope: $scope,
            size: 'lg',
            windowClass: 'modal-xl',
            templateUrl: 'app/modules/fornecedor/submodules/conta-corrente/submodules/nota-fiscal/modals/modal-detalhe-nota.template.html'
        });

        modalInstance.result.then(function(selectedItem) {}, function() {
            $scope.onRemove();
        });
    }
})();