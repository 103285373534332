(function() {
    'use strict';

    angular
        .module('bunge.modules.fornecedor.contratos')
        .directive('modalDetalheContrato', function() {
            return {
                restrict: 'EA',
                transclude: true,
                scope: {
                    model: "=",
                    onRemove: "&"
                },
                controller: ['$uibModal', '$scope', '$uibModalStack', 'HelperFactory', 'Flash', '$http', 'ENDPOINT_URI', function($uibModal, $scope, $uibModalStack, HelperFactory, Flash, $http, ENDPOINT_URI) {
                    function iniciar() {
                        Flash.clear();

                        $http.get(ENDPOINT_URI.endpoint_fornecedorReq + 'contratos/' + $scope.model.contratoMTP + '/' + $scope.model.codigoEmpresa + '/' + $scope.model.codigoFilial).then(function(data) {
                            $scope.model = data.data;

                            $scope.paramsGridPrazosEntregaOptions = {
                                resizable: true,
                                filterable: true,
                                sortable: true,
                                columnMenu: true,
                                dataSource: new kendo.data.DataSource({
                                    data: data.data.prazoEntrega,
                                    page: 1,
                                    schema: {
                                        model: {
                                            fields: {
                                                dataPrazoEntrega: {
                                                    type: "date",
                                                },
                                                dataPrazoRetirada: {
                                                    type: "date",
                                                },
                                                quantidadePrazoEntrega: {
                                                    type: "number",
                                                }
                                            }
                                        },
                                        total: function() {
                                            return data.data.prazoEntrega.length;
                                        }
                                    }
                                }),
                                excel: {
                                    allPages: false,
                                    fileName: 'Prazo de Entrega.csv'
                                },
                                toolbar: [{
                                    name: 'excel',
                                    template: "<kendo-button class='btn btn-default k-grid-excel'>Gerar Excel</kendo-button>"
                                }],
                                dataBound: function(e) {
                                    HelperFactory.moveGridToolbarToFooter("#gridPrazoEntrega");
                                },
                                columns: [{
                                        title: 'Data Prazo Entrega',
                                        field: 'dataPrazoEntrega',
                                        headerAttributes: { style: "text-align: center" },
                                        attributes: { style: "text-align: center" },
                                        template: '#= kendo.toString(kendo.parseDate(data.dataPrazoEntrega,"yyyy-MM-ddTHH:mm:ss"),"dd/MM/yyyy")#'
                                    },
                                    {
                                        title: 'Data Prazo Retirada',
                                        field: 'dataPrazoRetirada',
                                        headerAttributes: { style: "text-align: center" },
                                        attributes: { style: "text-align: center" },
                                        template: '#= kendo.toString(kendo.parseDate(data.dataPrazoEntrega,"yyyy-MM-ddTHH:mm:ss"),"dd/MM/yyyy")#'
                                    },
                                    {
                                        title: 'Quantidade (Kg)',
                                        field: 'quantidadePrazoEntrega',
                                        attributes: { style: "text-align: right" },
                                        headerAttributes: { style: "text-align: center" },
                                        template: "#if(data.quantidadePrazoEntrega) {# #=kendo.toString (data.quantidadePrazoEntrega,'n0')# #}else{#0#}#"
                                    }
                                ]
                            };

                            $scope.paramsGridPrazosFixacaoOptions = {
                                resizable: true,
                                filterable: true,
                                sortable: true,
                                columnMenu: true,
                                dataSource: new kendo.data.DataSource({
                                    data: data.data.prazoFixacao,
                                    page: 1,
                                    schema: {
                                        model: {
                                            fields: {
                                                dataFimPrazoFixacao: {
                                                    type: "date",
                                                },
                                                dataInicioPrazoFixacao: {
                                                    type: "date",
                                                },
                                                quantidadeProgramada: {
                                                    type: "number",
                                                },
                                                quantidadeFixada: {
                                                    type: "number",
                                                },
                                                quantidadePrazoFixacao: {
                                                    type: "number",
                                                }
                                            }
                                        },
                                        total: function() {
                                            return data.data.prazoFixacao.length;
                                        }
                                    }
                                }),
                                excel: {
                                    allPages: false,
                                    fileName: 'Prazo de Fixação.csv'
                                },
                                toolbar: [{
                                    name: 'excel',
                                    template: "<kendo-button class='btn btn-default k-grid-excel'>Gerar Excel</kendo-button>"
                                }],
                                dataBound: function(e) {
                                    HelperFactory.moveGridToolbarToFooter("#gridPrazoFixacao");
                                },
                                columns: [{
                                        title: 'Data Início',
                                        field: 'dataInicioPrazoFixacao',
                                        template: '#= kendo.toString(kendo.parseDate(data.dataInicioPrazoFixacao,"yyyy-MM-ddTHH:mm:ss"),"dd/MM/yyyy")#',
                                        headerAttributes: { style: "text-align: center" },
                                        attributes: { style: "text-align: center" },
                                        width: 50
                                    },
                                    {
                                        title: 'Data Fim',
                                        field: 'dataFimPrazoFixacao',
                                        template: '#= kendo.toString(kendo.parseDate(data.dataFimPrazoFixacao,"yyyy-MM-ddTHH:mm:ss"),"dd/MM/yyyy")#',
                                        headerAttributes: { style: "text-align: center" },
                                        attributes: { style: "text-align: center" },
                                        width: '50px'
                                    },
                                    {
                                        title: 'Quantidade Programada (Kg)',
                                        field: 'quantidadeProgramada',
                                        width: '50px',
                                        template: "#if(data.quantidadeProgramada) {# #=kendo.toString (data.quantidadeProgramada,'n0')# #}else{#0#}#",
                                        headerAttributes: { style: "text-align: center" },
                                        attributes: { style: "text-align: right" },
                                    },
                                    {
                                        title: 'Quantidade Fixada (Kg)',
                                        field: 'quantidadeFixada',
                                        width: '50px',
                                        template: "#if(data.quantidadeFixada) {# #=kendo.toString (data.quantidadeFixada,'n0')# #}else{#0#}#",
                                        headerAttributes: { style: "text-align: center" },
                                        attributes: { style: "text-align: right" },
                                    },
                                    {
                                        title: 'Quantidade a Fixar (Kg)',
                                        field: 'quantidadePrazoFixacao',
                                        width: '50px',
                                        template: "#if(data.quantidadePrazoFixacao) {# #=kendo.toString (data.quantidadePrazoFixacao,'n0')# #}else{#0#}#",
                                        headerAttributes: { style: "text-align: center" },
                                        attributes: { style: "text-align: right" },
                                    }
                                ]
                            };
                        });
                    }
                    iniciar();

                    $scope.close = function() {
                        $uibModalStack.dismissAll();
                    }

                    var modalInstance = $uibModal.open({
                        animation: true,
                        scope: $scope,
                        size: 'lg',
                        templateUrl: 'app/modules/fornecedor/submodules/contratos/modal-detalhe-contrato/modal-detalhe-contrato.template.html',
                    });

                    modalInstance.result.then(function(selectedItem) {}, function() {
                        $scope.onRemove();
                    });
                }],
            };
        });
})();