(function () {
    'use strict';

    angular.module('bunge').factory('PORTAL_PUBLIC_KEYS', PORTAL_PUBLIC_KEYS);

    // PublicKey: InternalApplicationName
    function PORTAL_PUBLIC_KEYS() {
        return {
            'administracao': 'PADM',
            'fornecedor': 'PFOR',       
        };
    }
}());