(function () {

    angular
        .module('bunge.modules.fornecedor.contratos')
        .controller('FornecedorContratosController', FornecedorContratosController);

    FornecedorContratosController.$inject = ['KendoDataSourceFactory', '$scope', 'FornecedorContratosService', '$rootScope', '$q', '$state', 'HelperFactory', '$stateParams'];

    function FornecedorContratosController(KendoDataSourceFactory, $scope, FornecedorContratosService, $rootScope, $q, $state, HelperFactory, $stateParams) {
        var vm = this;
        vm.filtros = {};
        vm.filtros.posicao = "1";
        

        vm.ehUsuarioExterno = function () {
            if (!$rootScope.currentUser) {
                return true;
            }
            return $rootScope.currentUser.userRole.toUpperCase() == "EXTERNO";
        };

        var dataSourceFornecedor = KendoDataSourceFactory.iniciar({ endpoint: 'Fornecedor', fornecedor: true, sort: { field: "CodigoFornecedor", dir: "desc" } });
        dataSourceFornecedor.$adicionarFiltro('Fazenda', 'equal', '1');
        vm.usuariosDataSource = {
            filter: "contains",
            minLength: 3,
            dataTextField: 'DescricaoFornecedor',
            dataSource: dataSourceFornecedor.$gerarDataSource(),
            placeholder: 'Informe um usuário...',
            change: comboUserOnChange
        };

        vm.fazendaDataSource = {
            filter: "contains",
            minLength: 3,
            dataTextField: 'NomeFantasia',
            placeholder: 'Informe uma fazenda...',
            change: fazendaOnChange
        };

        vm.regionalDataSource = {
            filter: "contains",
            minLength: 3,
            dataTextField: 'nome',
            placeholder: 'Informe uma regional...',
            change: regionalOnChange
        };

        vm.produtoDataSource = {
            filter: "contains",
            minLength: 3,
            dataTextField: 'nome',
            placeholder: 'Informe um produto...',
            change: produtoOnChange
        };

        vm.safraDataSource = {
            placeholder: 'Informe um produto...',
            change: safraOnChange
        };

        vm.init = function () {
            if ($stateParams['filtros']) {
                vm.filtros = $stateParams['filtros'];

                vm.usuariosDataSource.dataSource.read()
                    .then(function () {
                        if (vm.ehUsuarioExterno()) {
                            vm.fornecedores.value($rootScope.currentUser.userSystemName);
                            var fornecedores = vm.fornecedores.dataSource._data;
                            var fornecedorSelecionado = fornecedores.filter(function (fornecedor) {
                                return fornecedor.DescricaoFornecedor = $rootScope.currentUser.userSystemName;
                            });
                            vm.filtros.fornecedorSelecionado = fornecedorSelecionado[0];
                        } else {
                            vm.fornecedores.value(vm.filtros.fornecedorSelecionado.NomeFantasia);
                        }
                    })
                    .then(function () {
                        return carregarFazenda();
                    })
                    .then(function () {
                        if(vm.filtros.fazendaSelecionada) {
                            vm.fazendas.value(vm.filtros.fazendaSelecionada.NomeFantasia);
                        }
                        return recarregarCombos(true);
                    })
                    .then(function () {
                        vm.form.$submitted = true;
                        vm.filtrar();
                    });
            } else {
                if (vm.ehUsuarioExterno()) {
                    vm.usuariosDataSource.dataSource.read()
                        .then(function () {
                            vm.fornecedores.value($rootScope.currentUser.userSystemName);
                            var fornecedores = vm.fornecedores.dataSource._data;
                            var fornecedorSelecionado = fornecedores.filter(function (fornecedor) {
                                return fornecedor.DescricaoFornecedor = $rootScope.currentUser.userSystemName;
                            });
                            vm.filtros.fornecedorSelecionado = fornecedorSelecionado[0];
                            recarregarCombos();
                        });
                }
            }
        };

        vm.init();

        vm.abrirDetalhe = function (dataItem) {
            vm.contratoDetalhe = [];
            vm.contratoDetalhe.push({
                contratoMTP: dataItem.ContratoCompraMTP,
                codigoEmpresa: dataItem.CodigoEmpresa,
                codigoFilial: dataItem.CodigoFilial
            });
        };

        vm.abrirContaCorrente = function (dataItem) {
            if(vm.filtros.posicao == 2) {
                vm.getContratosFromDatasource(dataItem.AnoSafra)
                    .then(function(data) {
                        abrirContaCorrente(data);
                    })
            } else {
               abrirContaCorrente();
            }

            function abrirContaCorrente(contratosSafra) {
                $state.go('fornecedor.conta-corrente', {
                    posicao: vm.filtros.posicao,
                    contrato: vm.filtros.posicao == 1 ? dataItem : null,
                    documentoFornecedor: vm.filtros.fornecedorSelecionado.Documento,
                    documentoFazenda: vm.filtros.fazendaSelecionada ? vm.filtros.fazendaSelecionada.Documento : null,
                    codigoFornecedor: vm.filtros.fornecedorSelecionado.CodigoFornecedor,
                    codigoNucleo: vm.filtros.regionalSelecionado ? vm.filtros.regionalSelecionado.codigo : null,
                    codigoProduto: vm.filtros.produtoSelecionado && vm.filtros.produtoSelecionado.codigo > 0 ? vm.filtros.produtoSelecionado.codigo : "null",
                    anoSafra: vm.filtros.posicao == 2 ? dataItem.AnoSafra : vm.filtros.safraSelecionado,
                    filtros: vm.ultimoFiltro,
                    contratosSafra: contratosSafra
                });
            }
        };

        function recarregarCombos(vemDoFiltro) {
            vm.form.$submitted = false;
            if (vm.filtros.fornecedorSelecionado) {
                if(!vemDoFiltro) {
                    carregarFazenda();     
                }

                // vm.fornecedores.value(vm.filtros.fornecedorSelecionado.NomeFantasia)
                var fazendaSelecionada = vm.filtros.fazendaSelecionada ? vm.filtros.fazendaSelecionada.Documento : null;
                var promises = [
                    FornecedorContratosService.getRegionalData(vm.filtros.fornecedorSelecionado.Documento, fazendaSelecionada, vm.filtros.produtoSelecionado && vm.filtros.produtoSelecionado.codigo > 0 ? vm.filtros.produtoSelecionado.codigo : "null", (!vm.filtros.safraSelecionado || vm.filtros.safraSelecionado == 'TODOS') ? 0 : vm.filtros.safraSelecionado),
                    FornecedorContratosService.getProdutoData(vm.filtros.fornecedorSelecionado.Documento, fazendaSelecionada, !vm.filtros.regionalSelecionado ? null : vm.filtros.regionalSelecionado.codigo, (!vm.filtros.safraSelecionado || vm.filtros.safraSelecionado == 'TODOS') ? 0 : vm.filtros.safraSelecionado),
                    FornecedorContratosService.getAnoSafraData(vm.filtros.fornecedorSelecionado.Documento, fazendaSelecionada, !vm.filtros.regionalSelecionado ? null : vm.filtros.regionalSelecionado.codigo, vm.filtros.produtoSelecionado && vm.filtros.produtoSelecionado.codigo > 0 ? vm.filtros.produtoSelecionado.codigo : "null")
                ];

                return $q.all(promises).then(function (values) {
                    var retornoRegional = values[0].data;
                    retornoRegional.unshift({ nome: "TODOS", codigo: null });
                    vm.regional.setDataSource({ data: retornoRegional });

                    if (vm.filtros.regionalSelecionado) {
                        vm.regional.select(function (dataItem) {
                            return dataItem.codigo == vm.filtros.regionalSelecionado.codigo;
                        });
                    }
                    vm.filtros.regionalSelecionado = vm.regional.dataItem();

                    var retornoProduto = values[1].data;
                    retornoProduto.unshift({ nome: "TODOS", codigo: "-1" });
                    vm.produto.setDataSource({ data: retornoProduto });

                    if (vm.filtros.produtoSelecionado) {
                        vm.produto.select(function (dataItem) {
                            return dataItem.codigo == vm.filtros.produtoSelecionado.codigo;
                        });
                    }
                    vm.filtros.produtoSelecionado = vm.produto.dataItem();

                    var retornoAnoSafra = values[2].data;
                    retornoAnoSafra.unshift("TODAS");
                    vm.safra.setDataSource({ data: retornoAnoSafra });

                    if (vm.filtros.regionalSelecionado || vm.filtros.safraSelecionado) {
                        vm.safra.select(function (dataItem) {
                            return dataItem == vm.filtros.safraSelecionado;
                        });
                    }

                    vm.filtros.safraSelecionado = vm.safra.dataItem();
                });
            }
        }

        function fazendaOnChange() {
            vm.filtros.fazendaSelecionada = this.dataItem(this.select());
            recarregarCombos();
            $scope.$evalAsync();
        }

        function carregarFazenda(fazenda) {
            //TODO Filtrar novamente a fazendo quando filtrado pelo texto
            //fazenda = angular.isUndefined(fazenda) ? '' : fazenda;
            return FornecedorContratosService.getFazendas(vm.filtros.fornecedorSelecionado.Documento, fazenda)
                .then(function(result) {
                    var fazendas = result.data.value;
                    fazendas.unshift({NomeFantasia: "TODAS", Documento: null});
                    vm.fazendas.setDataSource({data: fazendas});
                });
        }

        function comboUserOnChange() {
            limparFazenda();
            limparRegional();
            limparProduto();
            limparSafra();
            vm.filtros.fornecedorSelecionado = this.dataItem(this.select());
            if (vm.filtros.fornecedorSelecionado) {
                recarregarCombos();
            }
            $scope.$evalAsync();
        }

        function reiniciarPosicao() {
            vm.filtros.posicao = "1";
        }

        function regionalOnChange() {
            vm.filtros.regionalSelecionado = this.dataItem(this.select());
            recarregarCombos();
            $scope.$evalAsync();
        }

        function produtoOnChange() {
            vm.filtros.produtoSelecionado = this.dataItem(this.select());
            recarregarCombos();
            $scope.$evalAsync();
        }

        vm.limpar = function () {
            limparFornecedor();
            limparFazenda();
            reiniciarPosicao();
            limparRegional();
            limparProduto();
            limparSafra();
            limparTabela();
            vm.form.$submitted = false;
            $scope.$evalAsync();
        };

        function limparTabela() {
            if (vm.grid) {
                vm.grid.dataSource.data([]);
            }
        }

        vm.filtrar = function() {
            var ds;
            if (vm.filtros.posicao == 1) {
                ds = KendoDataSourceFactory.iniciar({
                    endpoint: 'Contrato',
                    fornecedor: true,
                    pageable: false,
                    pageSize: 999,
                    expand: 'Fornecedor,Filial',
                    sort: { field: "ContratoCompraMTP", dir: "desc" }
                });

                var fazendaSelecionada = vm.filtros.fazendaSelecionada ? vm.filtros.fazendaSelecionada.Documento : null;
                if (fazendaSelecionada) {
                    ds.$adicionarFiltro('Fornecedor/Documento', 'eq', fazendaSelecionada);
                } else {
                    // if (vm.ehUsuarioExterno()) {
                    //     ds.$adicionarFiltro('Fornecedor/Documento', 'eq', vm.filtros.fornecedorSelecionado.Documento);
                    // } else {
                        ds.$adicionarFiltro('Fornecedor/Documento', 'startswith', vm.filtros.fornecedorSelecionado.Documento.substring(0, 8));
                    // }
                }
             

                if (vm.filtros.regionalSelecionado && vm.filtros.regionalSelecionado.codigo > 0) {
                    ds.$adicionarFiltro('Nucleo/Codigo', 'eq', vm.filtros.regionalSelecionado.codigo);
                }

                if (vm.filtros.produtoSelecionado && vm.filtros.produtoSelecionado.codigo > 0) {
                    ds.$adicionarFiltro('CodigoProduto', 'eq', vm.filtros.produtoSelecionado.codigo);
                }

                if (vm.filtros.safraSelecionado && vm.filtros.safraSelecionado != 'TODAS') {
                    ds.$adicionarFiltro('AnoSafra', 'eq', vm.filtros.safraSelecionado);
                }
            } else {
                ds = KendoDataSourceFactory.iniciar({
                    endpoint: 'ContratoSafra',
                    fornecedor: true,
                    pageable: false,
                    pageSize: 999,
                    sort: { field: "AnoSafra", dir: "asc" },
                    header: {
                        "OData.TipoConsultaContrato": vm.filtros.tipoConsulta,
                        "OData.DocumentoFornecedor": vm.filtros.fornecedorSelecionado.Documento,
                        "OData.CodigoNucleo": vm.filtros.regionalSelecionado ? vm.filtros.regionalSelecionado.codigo : null,
                        "OData.CodigoProduto": vm.filtros.produtoSelecionado && vm.filtros.produtoSelecionado.codigo > 0 ? vm.filtros.produtoSelecionado.codigo : "null",
                        "OData.AnoSafra": vm.filtros.safraSelecionado == "TODAS" ? -1 : vm.filtros.safraSelecionado
                    }
                });
            }

            vm.grid.setOptions(vm.paramsGridOptions());

            ds.$adicionarCampoModelo("QuantidadeEntregue", { type: "number" });
            ds.$adicionarCampoModelo("QuantidadeEntregar", { type: "number" });
            ds.$adicionarCampoModelo("QuantidadeContribuicaoCif", { type: "number" });
            ds.$adicionarCampoModelo("QuantidadeContribuicaoFob", { type: "number" });
            ds.$adicionarCampoModelo("QuantidadeTotalFixadaPaga", { type: "number" });
            ds.$adicionarCampoModelo("QuantidadeRecebidaFixada", { type: "number" });
            ds.$adicionarCampoModelo("QuantidadeFixar", { type: "number" });
            ds.$adicionarCampoModelo("QuantidadeNaoCumpridaFixa", { type: "number" });
            ds.$adicionarCampoModelo("QuantidadeNaoCumpridaPaga", { type: "number" });
            ds.$adicionarCampoModelo("QuantidadeNaoCumprida", { type: "number" });
            ds.$adicionarCampoModelo("QuantidadeContratada", { type: "number" });

            ds.$adicionarCampoAggregate("QuantidadeEntregue", "sum");
            ds.$adicionarCampoAggregate("QuantidadeEntregar", "sum");
            ds.$adicionarCampoAggregate("QuantidadeContribuicaoCif", "sum");
            ds.$adicionarCampoAggregate("QuantidadeContribuicaoFob", "sum");
            ds.$adicionarCampoAggregate("QuantidadeTotalFixadaPaga", "sum");
            ds.$adicionarCampoAggregate("QuantidadeRecebidaFixada", "sum");
            ds.$adicionarCampoAggregate("QuantidadeFixar", "sum");
            ds.$adicionarCampoAggregate("QuantidadeNaoCumpridaFixa", "sum");
            ds.$adicionarCampoAggregate("QuantidadeNaoCumpridaPaga", "sum");
            ds.$adicionarCampoAggregate("QuantidadeNaoCumprida", "sum");
            ds.$adicionarCampoAggregate("QuantidadeContratada", "sum");
            
            vm.grid.setDataSource(ds.$gerarDataSource());
            vm.ultimoFiltro = angular.copy(vm.filtros);

            var mensagem = FornecedorContratosService.getMensagemContaCorrente().then(function (response) {
                $rootScope.MensagemContaCorrenteContratos = response.data;
            });
        };

        vm.habilitarFiltro = function () {
            return vm.filtros.fornecedorSelecionado;
        };

        function safraOnChange() {
            vm.filtros.safraSelecionado = this.dataItem(this.select());
            recarregarCombos();
            $scope.$evalAsync();
        }

        function limparRegional() {
            if (!vm.ehUsuarioExterno()) {
                vm.regional.dataSource.data([]);
            }
            vm.filtros.regionalSelecionado = undefined;
            vm.regional.value('');
        }

        function limparFornecedor() {
            if (!vm.ehUsuarioExterno()) {
                vm.filtros.fornecedorSelecionado = undefined;
                vm.fornecedores.value('');
                vm.fornecedores.text('');
            }
        }

        function limparProduto() {
            vm.filtros.produtoSelecionado = undefined;
            vm.produto.dataSource.data([]);
            vm.produto.value('');
        }

        function limparSafra() {
            vm.filtros.fazendaSelecionada = undefined;
            vm.safra.dataSource.data([]);
            vm.safra.value('');
        }

        function limparFazenda() {
            vm.filtros.safraSelecionado = undefined;
            vm.fazendas.dataSource.data([]);
            vm.fazendas.value('');
        }

        function posicaoAtual() {
            return vm.filtros.posicao;
        }

        vm.paramsGridOptions = function () {
            return {
                resizable: true,
                filterable: true,
                sortable: true,
                columnMenu: true,
                pageSize: 999,
                excel: {
                    allPages: false,
                    fileName: 'Contratos.csv'
                },
                toolbar: [{
                    name: 'excel',
                    template: "<kendo-button class='btn btn-default k-grid-excel'>Gerar Excel</kendo-button>"
                }],
                dataBound: function (e) {
                    HelperFactory.mergeFooterTemplate(e, 0, 2);
                    if (!$('.k-toolbar-footer').length) {
                        $('#gridContratos').find(".k-grid-toolbar").addClass('k-toolbar-footer').insertAfter($('#gridContratos'));
                    } else {
                        $('.k-grid-toolbar').not('.k-toolbar-footer').remove();
                    }
                },
                columns: [{
                    title: "Quantidades Gerais (Kg)",
                    headerAttributes: { style: "text-align: center; border-right-color: rgb(204, 204, 204) !important;border-right-style: solid !important;border-right-width: 1px !important;border-collapse: separate !important;" },
                    attributes: { style: "text-align: right" },
                    columns: [{
                        command: !vm.filtros.posicao || vm.filtros.posicao == 1 ? [{
                            template: '<button  title="Detalhes"   type="button" class="btn k-button k-button-icontext btn-default btn-circle" ng-click="vm.abrirDetalhe(dataItem)" >' + '<i class="k-icon k-i-search ob-icon-only"  ></i></button>'
                        },
                        {
                            template: '<button  title="Conta Corrente"   type="button" class="btn k-button k-button-icontext btn-default btn-circle" ng-click="vm.abrirContaCorrente(dataItem)" >' + '<i class="k-icon k-i-more ob-icon-only"  ></i></button>'
                        }
                        ] : [{
                            template: '<button  title="Conta Corrente"   type="button" class="btn k-button k-button-icontext btn-default btn-circle" ng-click="vm.abrirContaCorrente(dataItem)" >' + '<i class="k-icon k-i-more ob-icon-only"  ></i></button>'
                        }],
                        attributes: { style: "text-align: center" },
                        headerAttributes: { style: "text-align: center" },
                        title: "Ações",
                        width: 70,
                        footerAttributes: { style: "text-align: center" },
                        footerTemplate: "TOTAL"
                    },
                    vm.filtros.posicao == 1 ? {
                        title: 'Contrato',
                        field: 'ContratoCompraMTP',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: center" },
                    } : {
                            title: "Safra",
                            field: 'AnoSafra',
                            headerAttributes: { style: "text-align: center" },
                            attributes: { style: "text-align: center" },
                        },
                    {
                        title: "Contratada",
                        field: 'QuantidadeContratada',
                        aggregates: ["sum"],
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: right" },
                        template: "#if(data.QuantidadeContratada) {# #=kendo.toString (data.QuantidadeContratada,'n0')# #}else{#0#}#",
                        footerAttributes: { style: "text-align: right" },
                        footerTemplate: function (data) {
                            return data.QuantidadeContratada && data.QuantidadeContratada.sum ? kendo.toString(data.QuantidadeContratada.sum, 'n0') : 0
                        },
                    }, {
                        title: "Entregue",
                        field: 'QuantidadeEntregue',
                        aggregates: ["sum"],
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: right" },
                        template: "#if(data.QuantidadeEntregue) {# #=kendo.toString (data.QuantidadeEntregue,'n0')# #}else{#0#}#",
                        footerTemplate: function (data) {
                            return data.QuantidadeEntregue && data.QuantidadeEntregue.sum ? kendo.toString(data.QuantidadeEntregue.sum, 'n0') : 0
                        },
                        footerAttributes: { style: "text-align: right" },
                    }, {
                        title: "A Entregar",
                        field: 'QuantidadeEntregar',
                        aggregates: ["sum"],
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: right" },
                        template: "#if(data.QuantidadeEntregar) {# #=kendo.toString(data.QuantidadeEntregar, 'n0')# #}else{#0#}#",
                        footerAttributes: { style: "text-align: right" },
                        footerTemplate: function (data) {
                            return data.QuantidadeEntregar && data.QuantidadeEntregar.sum ? kendo.toString(data.QuantidadeEntregar.sum, 'n0') : 0
                        },
                    }, {
                        title: 'Qtd. Fixar',
                        field: 'QuantidadeFixar',
                        aggregates: ["sum"],
                        template: "#if(data.QuantidadeFixar) {# #=kendo.toString(data.QuantidadeFixar, 'n0')# #}else{#0#}#",
                        headerAttributes: { style: "text-align: center; vertical-align:middle" },
                        footerAttributes: { style: "text-align: right" },
                        attributes: { style: "text-align: right" },
                        footerTemplate: function (data) {
                            return data.QuantidadeFixar && data.QuantidadeFixar.sum ? kendo.toString(data.QuantidadeFixar.sum, 'n0') : 0
                        },

                    },
                    vm.filtros.posicao == 1 ? {
                        title: "Frete",
                        field: 'DescricaoTipoFrete',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: center" },
                        footerAttributes: { style: "text-align: right" },
                    } : { hidden: true }
                    ]
                },
                {
                    title: "Quantidade Fixada (Kg)",
                    headerAttributes: { style: "text-align: center; border-right-color: rgb(204, 204, 204) !important;border-right-style: solid !important;border-right-width: 1px !important;border-collapse: separate !important;" },
                    attributes: { style: "text-align: right" },
                    footerAttributes: { style: "text-align: right" },
                    columns: [{
                        title: "Qtd. Paga",
                        field: 'QuantidadeTotalFixadaPaga',
                        aggregates: ["sum"],
                        template: "#if(data.QuantidadeTotalFixadaPaga) {# #=kendo.toString(data.QuantidadeTotalFixadaPaga, 'n0')# #}else{#0#}#",
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: right" },
                        footerAttributes: { style: "text-align: right" },
                        footerTemplate: function (data) {
                            return data.QuantidadeTotalFixadaPaga && data.QuantidadeTotalFixadaPaga.sum ? kendo.toString(data.QuantidadeTotalFixadaPaga.sum, 'n0') : 0
                        },
                    }, {
                        title: "A Pagar",
                        field: 'QuantidadeRecebidaFixada',
                        aggregates: ["sum"],
                        template: "#if(data.QuantidadeRecebidaFixada) {# #=kendo.toString(data.QuantidadeRecebidaFixada, 'n0')# #}else{#0#}#",
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: right" },
                        footerAttributes: { style: "text-align: right" },
                        footerTemplate: function (data) {
                            return data.QuantidadeRecebidaFixada && data.QuantidadeRecebidaFixada.sum ? kendo.toString(data.QuantidadeRecebidaFixada.sum, 'n0') : 0
                        },
                    }]
                },
                {
                    title: "Quantidade Não Cumprida (Kg)",
                    headerAttributes: { style: "text-align: center" },
                    footerAttributes: { style: "text-align: right" },
                    attributes: { style: "text-align: right" },
                    columns: [{
                        title: "Paga",
                        field: 'QuantidadeNaoCumpridaPaga',
                        aggregates: ["sum"],
                        template: "#if(data.QuantidadeNaoCumpridaPaga) {# #=kendo.toString(data.QuantidadeNaoCumpridaPaga, 'n0')# #}else{#0#}#",
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: right" },
                        footerAttributes: { style: "text-align: right" },
                        footerTemplate: function (data) {
                            return data.QuantidadeNaoCumpridaPaga && data.QuantidadeNaoCumpridaPaga.sum ? kendo.toString(data.QuantidadeNaoCumpridaPaga.sum, 'n0') : 0
                        },
                    }, {
                        title: "A Pagar",
                        field: 'QuantidadeNaoCumpridaFixa',
                        aggregates: ["sum"],
                        template: "#if(data.QuantidadeNaoCumpridaFixa) {# #=kendo.toString(data.QuantidadeNaoCumpridaFixa, 'n0')# #}else{#0#}#",
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: right" },
                        footerAttributes: { style: "text-align: right" },
                        footerTemplate: function (data) {
                            return data.QuantidadeNaoCumpridaFixa && data.QuantidadeNaoCumpridaFixa.sum ? kendo.toString(data.QuantidadeNaoCumpridaFixa.sum, 'n0') : 0
                        },
                    }, {
                        title: "A Fixar",
                        field: 'QuantidadeNaoCumprida',
                        aggregates: ["sum"],
                        template: "#if(data.QuantidadeNaoCumprida) {# #=kendo.toString(data.QuantidadeNaoCumprida, 'n0')# #}else{#0#}#",
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: right" },
                        footerAttributes: { style: "text-align: right" },
                        footerTemplate: function (data) {
                            return data.QuantidadeNaoCumprida && data.QuantidadeNaoCumprida.sum ? kendo.toString(data.QuantidadeNaoCumprida.sum, 'n0') : 0
                        },
                    }]
                }
                ],

            }
        }

        vm.getContratosFromDatasource = function(anoSafra) {
            var deferred  = $q.defer();

            var ds = KendoDataSourceFactory.iniciar({
                endpoint: 'ContratoSafra',
                fornecedor: true,
                pageable: false,
                pageSize: 999,
                sort: { field: "CodigoEmpresa", dir: "asc" },
                header: {
                    "OData.TipoConsultaContrato": vm.filtros.tipoConsulta,
                    "OData.DocumentoFornecedor": vm.filtros.fornecedorSelecionado.Documento,
                    "OData.CodigoNucleo": vm.filtros.regionalSelecionado ? vm.filtros.regionalSelecionado.codigo : null,
                    "OData.CodigoProduto": vm.filtros.produtoSelecionado && vm.filtros.produtoSelecionado.codigo > 0 ? vm.filtros.produtoSelecionado.codigo : "null",
                    "OData.AnoSafra": anoSafra,
                    "OData.ComChaves": true
                },
                change: function() {
                    var contratosSafra = []
                    data = this._data;
                    data.forEach(function(obj) {
                        contratosSafra.push({
                            CodigoEmpresa: obj.CodigoEmpresa,
                            CodigoFilial: obj.CodigoFilial,
                            ContratoCompraMTP: obj.ContratoCompraMTP
                        });
                    })
                    deferred.resolve(contratosSafra);
                }
            });
            ds.$gerarDataSource().read();

            return deferred.promise;
        }
    }
})();