(function () {
    'use strict';

    angular
       .module('bunge.components')
       .directive('modalGrupoAcesso', function () {
           return {
               restrict: 'EA',
               transclude: true,
               scope: {
                   model: "=",
                   onRemove: "&"
               },
               controller: ['$uibModal', '$scope', '$uibModalStack', 'AdministracaoAdministradorDePerfilService', 'HelperFactory', 'Flash', '$compile', 'KendoDataSourceFactory', function ($uibModal, $scope, $uibModalStack, AdministracaoAdministradorDePerfilService, HelperFactory, Flash, $compile, KendoDataSourceFactory) {
                   function iniciar() {
                       Flash.clear();
                   }
                   iniciar();

                   $scope.appsSelected = [];
                   $scope.permissionsSelected = [];
                   $scope.gridFuncionalidades = {
                       editable: "inline",
                       sortable: true,
                       pageable: true,
                       resizable: true,
                       filterable: true,
                       columnMenu: true,
                       toolbar: [
          {
              name: "create",
              template: "<button class='btn btn-default k-grid-add' ng-model='btnNew'>ADICIONAR FUNCIONALIDADE</button>"
          }
                       ],
                       dataSource: KendoDataSourceFactory.iniciar({
                           endpoint: 'FunctionalityGroupOData', STS: true, expand: 'Functionality', pageSize: 50
                       }).$adicionarFiltro('IdGroup', 'eq', $scope.model.Id ? $scope.model.Id : 0).$adicionarIdModelo('Id').$adicionarCampoModelo("ReadOnly", { type: "boolean" }).$gerarDataSource(),
                       columns: [
				{
				    title: 'Descrição',
				    field: 'Functionality.Description',
				    template: "#= data.Functionality != undefined ? data.Functionality.Description : ''#"
				    , width: '50%',
				    editor: function (container, options) {
				        var id = options.model.Functionality ? options.model.Functionality.Id : 0;
				        var element = angular.element('<select kendo-combo-box="comboboxFuncionalidade" maxlength="200"  ' +
                            'k-placeholder="\'Selecione a funcionalidade...\'" ' +
                            'options="funcionalidadesOptions(' + id + ')"></select>');
				        element.appendTo(container);
				        $compile(angular.element(container).contents())($scope);
				    }
				},
                	{
                	    title: 'Apenas Consultar', field: 'ReadOnly', width: '30%',
                	    template: '<input type="checkbox" #= data.ReadOnly ? checked="checked" : "" # disabled="disabled" />',
                	},
				{
				    command: ["edit",
                                         {
                                             template: '<button type="button" class="btn k-button k-button-icontext btn-danger ' +
                                             'btn-circle" ng-click="excluir(dataItem)" title="Excluir"><i class="glyphicon glyphicon-remove"></i></button>'
                                         }
				    ],
				    width: '20%',
				    title: "Ações"
				}
                       ],
                       save: function (e) {
                           e.preventDefault();
                           if (!$scope.selectedFuncionalidade || ($scope.selectedFuncionalidade && !$scope.selectedFuncionalidade.Id)) {
                               e.preventDefault();
                               this.editRow(e);
                               Flash.clear();
                               Flash.create('danger', "Preencha todos os campos para inclusão/edição.");
                               $scope.$evalAsync();
                               return;
                           }

                           if (e.model.Id) {
                               angular.forEach(this._data, function (value, key) {
                                   if (value.Id == e.model.Id) {
                                       e.model.Functionality.Id = $scope.selectedFuncionalidade.Id;
                                       e.model.Functionality.Description = $scope.selectedFuncionalidade.Description;
                                   }
                               });
                               this.refresh();
                           } else {
                               e.model.Id = guid();
                               e.model.Functionality = {};
                               e.model.Functionality.Id = $scope.selectedFuncionalidade.Id;
                               e.model.Functionality.Description = $scope.selectedFuncionalidade.Description;
                               this._data.push(e.model);
                               this.refresh();
                           }
                       },
                       messages: {
                           commands: {
                               edit: " ",
                               update: " ",
                               destroy: " ",
                               canceledit: " "
                           }
                       },
                   };

                   $scope.selectedFuncionalidade;
                   $scope.funcionalidadesOptions = function (data) {
                       return {
                           dataSource: KendoDataSourceFactory.iniciar({
                               endpoint: 'FunctionalityOData', STS: true, pageSize: 500
                           }).$adicionarFiltro('App/Id', 'eq', $scope.model.AppId).$gerarDataSource(),
                           dataTextField: 'Description',
                           dataValueField: 'Id',
                           change: function (e, selected, data) {
                               var model = this.dataItem(this.select());
                               $scope.selectedFuncionalidade = angular.copy(model);
                           },
                           dataBound: function (e) {
                               if (!this.dataSource.filtered) {
                                   this.dataSource.filtered = true;
                                   $scope.selectedFuncionalidade = {};
                                   if (data > 0) {
                                       var options = this.dataSource._data;
                                       for (var i = 0; i < options.length; i++) {
                                           if (options[i].Id == data) {
                                               this.value(data);
                                               $scope.selectedFuncionalidade = options[i];
                                           }
                                       }
                                   }

                                   var grid = angular.element("#funcionalidades").data("kendoGrid");

                                   var bIds = {}
                                   grid._data.forEach(function (obj) {
                                       if (obj.Functionality && obj.Functionality.Id != data)
                                           bIds[obj.Functionality.Id] = obj;
                                   });

                                   var newData = this._data().filter(function (obj) {
                                       return !(obj.Id in bIds);
                                   });

                                   this._data(newData);
                                   this.dataSource.data(newData);
                                   this.refresh();
                               }
                           }
                       }
                   };

                   $scope.excluir = function (dataItem) {
                       var grid = angular.element("#funcionalidades").data("kendoGrid");

                       grid.saveRow();

                       grid.dataSource._data.forEach(function (element, index, array) {
                           if (element && element.Id == dataItem.Id) {
                               grid.dataSource._data.splice(index, 1);
                               grid.refresh();
                           }
                       });
                   };

                   function guid() {
                       function s4() {
                           return Math.floor((1 + Math.random()) * 0x10000)
                             .toString(16)
                             .substring(1);
                       }
                       return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
                         s4() + '-' + s4() + s4() + s4();
                   }

                   $scope.save = function () {

                       var data = {
                           Id: $scope.model.Id,
                           Description: $scope.model.Description.toUpperCase(),
                           FunctionalityList: []
                       }

                       var grid = angular.element("#funcionalidades").data("kendoGrid");
                       grid.saveRow();
                       var error = false;
                       angular.forEach(grid._data, function (value, key) {
                           if (value.Id) {
                               data.FunctionalityList.push({ IdFunctionality: value.Functionality.Id, ReadOnly: value.ReadOnly });
                           } else {
                               error = true;
                           }
                       });

                       if (error) {
                           Flash.clear();
                           Flash.create('danger', "Preencha todos os campos para inclusão/edição.");
                           $scope.$evalAsync();
                           return;
                       }

                       if ($scope.model.Id) {
                           AdministracaoAdministradorDePerfilService.atualizarFuncGroup(data).then(function (data) {
                               Flash.create('success', "Perfil " + $scope.model.Description.toUpperCase() + " atualizado com sucesso.");
                               $scope.close();
                           }, function (data) {
                              
                               HelperFactory.getErrorMessage(data.data);
                           });
                       } else {
                           AdministracaoAdministradorDePerfilService.cadastrarFuncGroup(data).then(function (data) {
                               Flash.create('success', "Novo perfil " + $scope.model.Description.toUpperCase() + " criado com sucesso.");
                               $scope.close();
                           }, function (data) {
                               HelperFactory.getErrorMessage(data.data);
                           });
                       }
                   };

                   $scope.possuiFuncionalidades = function () {
                       var grid = angular.element("#funcionalidades").data("kendoGrid");
                       return grid._data && grid._data.length > 0;
                   }

                   $scope.close = function () {
                       $uibModalStack.dismissAll();
                   }

                   var modalInstance = $uibModal.open({
                       animation: true,
                       scope: $scope,
                       templateUrl: '/app/components/modal-grupo-acesso/modal-grupo-acesso.template.html',
                   });

                   modalInstance.result.then(function (selectedItem) {
                   }, function () {
                       $scope.onRemove();
                   });
               }],
           };
       });
})();

