(function () {
    'use strict';

    angular
       .module('bunge.components')
       .directive('pagHeader', ['$state', '$rootScope', '$compile', '$templateRequest', 'AuthenticationService', 'SIDEBAROPTS', '$window',
    function ($state, $rootScope, $compile, $templateRequest, AuthenticationService, SIDEBAROPTS, $window) {

        var getTemplateUrl = function () {
            var currentState = $rootScope.currentState ? $rootScope.currentState.name : $state.current.name;
            if (currentState == 'login') {
                return '/app/components/header/pagHeader.template.html';
            } else if (currentState == 'esqueci-minha-senha' || currentState == 'esqueci-minha-senha-chave') {
                return '/app/components/header/pagHeaderRecup.template.html';
            } else if (currentState == 'primeiro-acesso' || currentState == 'primeiro-acesso-chave') {
                return '/app/components/header/pagHeaderPrimeiroAcesso.template.html';
            } else {
                return '/app/components/header/pagHeaderLogado.template.html';
            }
        };

        var checkSideBarEnabled = function (scope) {
            scope.sideBarEnabled = false;
            if (!$state.current.name) {
                scope.sideBarEnabled = false;
                return false;
            }
            var currentState = $rootScope.currentState ? $rootScope.currentState.name : $state.current.name;
            for (var i in SIDEBAROPTS) {
                var regEx = new RegExp(SIDEBAROPTS[i]);
                if (regEx.test(currentState)) {
                    scope.sideBarEnabled = true;
                }
            }
        };

        var linker = function (scope, element, attrs) {
            $rootScope.$on('$locationChangeSuccess', function (event, next, current) {
                $templateRequest(getTemplateUrl()).then(function (html) {
                    var template = angular.element(html);
                    element.html('');
                    element.append(template);
                    $compile(template)(scope);

                    if ($rootScope.currentUser) {
                        $rootScope.$evalAsync();
                        scope.userName = $rootScope.currentUser.userSystemName;
                        scope.userType = $rootScope.currentUser.userRole;
                    }

                    checkSideBarEnabled(scope);
                });
            });

            scope.showUserMenuContext = showUserMenuContext;

            function showUserMenuContext(){
                return scope.userType != 'Interno' &&  scope.userType != 'Root';
            };


            $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                $templateRequest(getTemplateUrl()).then(function (html) {
                    var template = angular.element(html);
                    element.html('');
                    element.append(template);
                    $compile(template)(scope);

                    if ($rootScope.currentUser) {
                        $rootScope.$evalAsync();
                        scope.userName = $rootScope.currentUser.userSystemName;
                        scope.userType = $rootScope.currentUser.userRole;
                    }
                    checkSideBarEnabled(scope);
                });
            });

            scope.logoff = function () {
                AuthenticationService.ClearCredentials();
            };

            scope.changeSideBar = function () {
                angular.element(document.querySelector('#wrapper')).toggleClass('toggled');
                angular.element(document.querySelector('.c-mask')).toggleClass('is-active');

                if (angular.element(document.querySelector('#wrapper')).hasClass('toggled')) {
                    angular.element(document.querySelector('body')).css('overflow', 'hidden');
                    $window.onclick = function (event) {
                        closeSearchWhenClickingElsewhere(event, scope.changeSideBar);
                    };
                } else {
                    $window.onclick = null;
                    angular.element(document.querySelector('body')).css('overflow', 'auto');
                }
            };

            function closeSearchWhenClickingElsewhere(event, callbackOnClose) {
                var clickedElement = event.target;
                if (!clickedElement) {
                    return;
                }

                var elementClasses = clickedElement.classList;
                var clickedOnSearchDrawer = elementClasses.contains('glyphicon-th-large') || elementClasses.contains('navbar-toggle');

                if (!clickedOnSearchDrawer) {
                    callbackOnClose();
                    return;
                }
            }
        };

        return {
            restrict: 'EA',
            link: linker,
            replace: true
        };
    }]);
})();

