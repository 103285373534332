(function () {

    'use strict';

    angular
        .module('bunge')
        .factory('ModulesLoaded', ModulesLoaded);

    function ModulesLoaded() {

       var loaded = {};

        return {            
            GetLoaded: function(){
            return loaded;
            },
        };
    }
})();