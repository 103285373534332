(function() {

    angular
        .module('bunge.modules.fornecedor.conta-corrente.nota-fiscal')
        .controller('FornecedorContaCorrenteNotaFiscalController', FornecedorContaCorrenteNotaFiscalController);

    FornecedorContaCorrenteNotaFiscalController.$inject = ['KendoDataSourceFactory', '$stateParams', 'ContaCorrenteParamsFactory', 'HelperFactory'];

    function FornecedorContaCorrenteNotaFiscalController(KendoDataSourceFactory, $stateParams, ContaCorrenteParamsFactory, HelperFactory) {

        var vm = this;

        vm.paramsGridOptions = function(dataItem) {
            var dataSourceParams = {
                endpoint: 'NotaFiscalAgrupadaFornecedor',
                fornecedor: true,
                pageable: false,
                pageSize: 999,
                sort: {
                    field: "NomeFornecedor",
                    dir: "asc"
                },
                header: ContaCorrenteParamsFactory.getHeader($stateParams)
            }

            var ds = KendoDataSourceFactory.iniciar(dataSourceParams);
            ds.$adicionarCampoModelo("QuantidadeEntregue", { type: "number" });
            ds.$adicionarCampoAggregate("QuantidadeEntregue", "sum");

            return {
                resizable: true,
                filterable: true,
                sortable: true,
                columnMenu: true,
                dataSource: ds.$gerarDataSource(),
                excel: {
                    allPages: false,
                    fileName: 'Nota Fiscal.csv'
                },
                toolbar: [{
                    name: 'excel',
                    template: "<kendo-button class='btn btn-default k-grid-excel'>Gerar Excel</kendo-button>"
                }],
                dataBound: function(e) {
                    HelperFactory.mergeFooterTemplate(e, 0, 2);
                    HelperFactory.moveGridToolbarToFooter("#gridNotaFiscal");
                },
                columns: [{
                        command: [{
                            template: '<button  title="Detalhes"   type="button" class="btn k-button k-button-icontext btn-default btn-circle" ng-click="vm.abrirDetalhe(dataItem)" >' + '<i class="k-icon k-i-search ob-icon-only"  ></i></button>'
                        }],
                        title: "Ações",
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: center" },
                        footerTemplate: "TOTAL",
                        footerAttributes: { style: "text-align: center" }
                    },
                    {
                        title: "Fornecedor",
                        field: 'NomeFornecedor',
                        footerTemplate: "Total",
                        footerAttributes: { style: "text-align: center" },
                    },
                    {
                        title: "Qtd. Entregue (kg)",
                        field: 'QuantidadeEntregue',
                        aggregates: ["sum"],
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: right" },
                        template: "#if(data.QuantidadeEntregue) {# #=kendo.toString (data.QuantidadeEntregue,'n0')# #}else{#0#}#",
                        footerTemplate: function(data) {
                            return data.QuantidadeEntregue && data.QuantidadeEntregue.sum ? kendo.toString(data.QuantidadeEntregue.sum, 'n0') : 0
                        },
                        footerAttributes: { style: "text-align: right" },
                    }
                ],
            }
        };


        vm.abrirDetalhe = function(dataItem) {
            var headerParams = ContaCorrenteParamsFactory.getHeader($stateParams);
            headerParams["OData.CodigoFilialEntrega"] = dataItem.CodigoFornecedor;

            vm.notaFiscalDetalhe = [];
            vm.notaFiscalDetalhe.push({ header: headerParams });
        };

    }
})();