(function () {

    'use strict';

    angular
        .module('bunge')
        .factory('TokenManagerFactory', TokenManagerFactory);

    TokenManagerFactory.$inject = ['$window', 'jwtHelper', '$location'];
    function TokenManagerFactory($window, jwtHelper, $location) {
        var storage;

        function RemoveToken() {
            return hub().onConnect().then(function () {
                return hub().del('jwt_token');
            })
        }

        function GetToken() {
            return hub().onConnect().then(function () {
                return hub().get('jwt_token');
            })
        }

        function SetToken(token) {
            hub().onConnect().then(function () {
                return hub().set('jwt_token', token);
            },
            function (e) {
            })
        }

        function DecodeToken(token) {
            return jwtHelper.decodeToken(token || GetToken());
        }

        function hub() {
            if (!storage) {
                storage = new CrossStorageClient('https://' + $location.host() + ':' + $location.port() + '/hub.html')
            }
            return storage;
        }

        return {     
            GetToken: GetToken,
            SetToken: SetToken,
            RemoveToken: RemoveToken,
            DecodeToken: DecodeToken,
            hub: hub

        };
    }
})();