(function () {

    angular
        .module('bunge.modules.fornecedor.conta-corrente')
        .controller('FornecedorContaCorrenteController', FornecedorContaCorrenteController);

    FornecedorContaCorrenteController.$inject = ['FOR_CC_TABS_CONSTANT', '$stateParams', '$state', '$timeout', 'KendoDataSourceFactory', '$rootScope'];

    function FornecedorContaCorrenteController(FOR_CC_TABS_CONSTANT, $stateParams, $state, $timeout, KendoDataSourceFactory, $rootScope) {
        var vm = this;
        vm.tabData = [];
        $rootScope.filtrosContratos = $stateParams['filtros'];
        if($stateParams['contrato']) {
            vm.codigoContrato = $stateParams['contrato'].ContratoCompraMTP
        }
        vm.getPosicao = function () {
            return $stateParams['posicao'];
        };

        vm.voltar = function () {
            $rootScope.filtrosContratos = undefined;
            return $state.go('fornecedor.contratos', {
                filtros: $stateParams['filtros']
            });
        };

        function iniciar() {
            if ($stateParams['posicao']) {
                if ($stateParams['posicao'] == 1) {
                    var ds = KendoDataSourceFactory.iniciar({
                        endpoint: 'Contrato',
                        fornecedor: true,
                        pageable: false,
                        pageSize: 999,
                        expand: 'Fornecedor,Filial',
                        sort: { field: "ContratoCompraMTP", dir: "desc" }
                    });

                    if ($stateParams['codigoProduto'] && $stateParams['codigoProduto'] != 'null') {
                        ds.$adicionarFiltro('CodigoProduto', 'eq', $stateParams['codigoProduto']);
                    }

                    if ($stateParams['codigoNucleo'] && $stateParams['codigoNucleo'] > 0) {
                        ds.$adicionarFiltro('Nucleo/Codigo', 'eq', $stateParams['codigoNucleo']);
                    }

                    if ($stateParams['anoSafra'] && $stateParams['anoSafra'] != 'TODAS') {
                        ds.$adicionarFiltro('AnoSafra', 'eq', $stateParams['anoSafra']);
                    }

                    vm.contratoComboOptions = {
                        dataSource: ds.$gerarDataSource(),
                        placeholder: "Selecione um contrato",
                        dataTextField: "ContratoCompraMTP",
                        dataValueField: "ContratoCompraMTP",
                        value: $stateParams['contrato'].ContratoCompraMTP,
                        change: function (e) {
                            $stateParams['contrato'] = this.dataItem();
                            $state.transitionTo($state.current.name, $stateParams, { reload: $state.current.name });
                        }
                    }
                }

                var redirect = $state.current.name != "fornecedor.conta-corrente";
                for (var item in FOR_CC_TABS_CONSTANT) {

                    var tabItem = FOR_CC_TABS_CONSTANT[item];

                    var model = {
                        heading: '<p>' + tabItem.legenda + '</p>',
                        route: tabItem.route
                    };

                    vm.tabData.push(model);

                    if (!redirect) {
                        $state.go(tabItem.route, $stateParams);
                        redirect = true;
                    }
                }
            } else {
                $state.go('fornecedor.contratos');
            }
        };

        iniciar();

    }
})();