(function () {
     'use strict';
     
angular.module('bunge.modules.login')
    .config(['$stateProvider',ConfigLogin]);

function ConfigLogin($stateProvider) {
    $stateProvider          
             .state('login', {
            url: '/login',
            templateUrl: 'app/modules/login/view/login.view.html',
            controller: 'LoginController',
            controllerAs: 'vm'
        })
}
})();
