(function () {
    'use strict';

    angular.module('bunge.modules.administracao')
        .config(['$stateProvider', ConfigAdministracao]);

    function ConfigAdministracao($stateProvider) {
        $stateProvider
            .state('administracao', {
                url: '/administracao',
                templateUrl: 'app/modules/administracao/view/administracao.view.html',
                controller: 'AdministracaoController',
                controllerAs: 'vm',
                resolve: { // Any property in resolve should return a promise and is executed before the view is loaded
                    depsAdmin: ['DynamicModulesLoader', '$rootScope', 'ModulesLoaded', function (DynamicModulesLoader, $rootScope, ModulesLoaded) {
                        // you can lazy load files for an existing module
                        if (ModulesLoaded.GetLoaded()['administracao']) {
                            return;
                        }
                        return DynamicModulesLoader.LoadModulesFromToken('administracao');

                    }]
                }
            })
    }
})();
