(function () {
    'use strict';

    angular
        .module('bunge.components')
        .directive('modalUsuarioExterno', function () {
            return {
                restrict: 'EA',
                transclude: true,
                scope: {
                    model: "=",
                    onRemove: "&"
                },
                controller: ['Flash','$uibModal', '$scope', '$uibModalStack', 'ModalUsuarioExternoService', function (Flash, $uibModal, $scope, $uibModalStack, ModalUsuarioExternoService) {
                    $scope.save = function () {
                        var data = {
                            Documento: $scope.model.Login,
                            Email: $scope.model.Email,
                            Status: $scope.model.StatusId.Value ? $scope.model.StatusId.Value : $scope.model.StatusId
                        };
                        ModalUsuarioExternoService.atualizarStatusEmail(data).then(function () {
                            Flash.create('success', "Salvo com sucesso!");
                            $uibModalStack.dismissAll();
                        }, function (motivo) {
                            Flash.clear();
                            Flash.create('danger', motivo.data.exceptionMessage, 8000);
                        });
                    };

                    $scope.close = function () {
                        $uibModalStack.dismissAll();
                    };

                    $scope.statusDataSource = {
                        dataSource: [
                            { Text: 'Normal', Value: 1 },
                            { Text: 'Expirado', Value: 2 },
                            { Text: 'Bloqueado', Value: 3 },
                        ],
                        dataTextField: 'Text',
                        dataValueField: 'Value',
                        value: $scope.model.StatusId,
                        autoBind: true
                    };
                    var modalInstance = $uibModal.open({
                        animation: true,
                        scope: $scope,
                        templateUrl: '/app/components/modal-usuario-externo/modal-usuario-externo.template.html',
                    });

                    modalInstance.result.then(function (selectedItem) {
                    }, function () {
                        $scope.onRemove();
                    });
                }]
            };
        });
})();

