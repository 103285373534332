(function () {
    'use strict';

    angular.module('bunge.modules.alterar-email')
        .config(['$stateProvider',ConfigAlterarEmail]);

    function ConfigAlterarEmail($stateProvider) {
        $stateProvider
            .state('configuracoes.alterar-email', {
                url: '/alterar-email',
                templateUrl: 'app/modules/alterar-email/view/alterar-email.view.html',
                controller: 'AlterarEmailController',
                controllerAs: 'vm'
            })
    }
})();
