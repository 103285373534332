(function() {
    'use strict';

    angular.module('bunge').factory('FOR_SALDO_TABS_CONSTANT', FOR_SALDO_TABS_CONSTANT);

    function FOR_SALDO_TABS_CONSTANT() {
        return [{
                order: 1,
                route: 'fornecedor.conta-corrente.saldo-contrato.resumido',
                legenda: 'RESUMIDO'
            },
            {
                order: 2,
                route: 'fornecedor.conta-corrente.saldo-contrato.detalhado',
                legenda: 'DETALHADO'
            }
        ];
    }
})();