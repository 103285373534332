(function () {
    'use strict';

    angular
       .module('bunge.components')
       .directive('visibilidadeTabela', ['Session', '$state', function ($rootScope, $state) {

           return {
               restrict: 'A',
               link: function (scope, element, attrs) {
                   scope.$watch('visibilidadeTabela._data', function (tabela) {
                       if (!tabela || tabela.length == 0) {
                           if (!element.next().hasClass('msg')) {
                               element.hide();
                               element.after("<div class='msg'>Não há registros de " + attrs.nome + " para exibir</div>");
                           }                       
                       } else {
                           if (element.next().hasClass('msg')) {
                               element.show();
                               element.next().remove();
                               scope.visibilidadeTabela.refresh();
                           }
                       }
                   });
               },
               scope: {
                   'visibilidadeTabela': '=',
               },
           };
       }]);
})();

