if (window.location.pathname !== '/') {
    window.location.href = '/#' + window.location.pathname;
}

(function () {
    'use strict';

    angular
        .module('bunge.modules.alterar-email')
        .controller('AlterarEmailController', AlterarEmailController);

    AlterarEmailController.$inject = ['$rootScope', 'Flash', 'NewEmailService', '$scope','$state'];
    function AlterarEmailController($rootScope, Flash, NewEmailService, $scope, $state) {
        var vm = this;

        vm.init = function () {
            if ($rootScope.currentUser.userType == 2) {
                $state.go('home');
            }
            
            // Recupera o e-mail do usuário autenticado.
            NewEmailService.RecuperarEmail($rootScope.currentUser.userName).then(function(result){
                vm.email = result.email;
            });
        };
        
        vm.init();
        vm.salvar = function (form) {
            var req = {
                Login: $rootScope.currentUser.userName,
                Email: vm.email,
                Senha: vm.password,
                ConfirmSenha: vm.confirmPassword
            };
            NewEmailService.Alterar(req).then(function (result) {
                vm.limpar(form);
                Flash.create('success', "Atualizado com sucesso!", 5000);
            }, function (result) {
                vm.limpar(form);
                Flash.create('danger', "Não foi possível realizar alteração. Verifique suas credenciais.", 5000);
            });
        };

        vm.limpar = function (form) {
            vm.password = '';
            vm.confirmPassword = '';
            form.$setPristine();
        };
    }
})();