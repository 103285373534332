(function () {

    'use strict';

    angular
        .module('bunge.modules.primeiro-acesso')
        .service('PrimeiroAcessoService', PrimeiroAcessoService);

    PrimeiroAcessoService.$inject = ['ENDPOINT_URI', '$resource'];
    function PrimeiroAcessoService(ENDPOINT_URI, $resource) {

        var Program = $resource(ENDPOINT_URI.endpoint_backendReq + 'pedido', {}, {
            criarPedido: {
                url: ENDPOINT_URI.endpoint_backendReq + 'pedido',
                method: 'POST',
            },
            executarPedido: {
                url: ENDPOINT_URI.endpoint_backendReq + 'pedido/executar-pedido',
                method: 'PUT',
            },
            verificarChave: {
                url: ENDPOINT_URI.endpoint_backendReq + 'pedido/obter-pedido/:tipoPedido/:chave',
                method: 'GET',
                params: {
                    chave: '@chave',
                    tipoPedido: '@tipoPedido'
                }
            }
        });

        function Requisitar(user) {
            var program = new Program();
            program.TipoRegistro = 1;
            program.NumeroDocumento = user.NumeroDocumento;
            return program.$criarPedido().then(function (res) {
                return "ok";
            }, function (res) {
                if (res.data.innerException.exceptionMessage) {
                    return res.data.innerException.exceptionMessage;
                } else {
                    return "";
                }
            });
        }

        function Executar(user) {
            var program = new Program();
            program.Chave = user.Chave;
            program.Senha = user.Senha;
            program.ConfirmacaoSenha = user.ConfirmacaoSenha;
            return program.$executarPedido().then(function (res) {
                return "ok";
            }, function (res) {
                if (res.data.innerException.exceptionMessage) {
                    return res.data.innerException.exceptionMessage;
                } else  {
                    return "";
                }
            });
        }

        function VerificarChave(user) {
            var program = new Program();
            return program.$verificarChave({ chave: user.chave, tipoPedido: 1 }).then(function (res) {
                return "ok";
            }, function (res) {
                return res.data;
            });
        }

        return {
            Requisitar: Requisitar,
            Executar: Executar,
            VerificarChave: VerificarChave
        };
    }
})();