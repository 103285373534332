(function () {

    'use strict';

    angular
        .module('bunge.system')
        .factory('KendoDataSourceFilterGenerator', KendoDataSourceFilterGenerator);

    function KendoDataSourceFilterGenerator() {
        function KendoDataSourceFilterGenerator() {
            angular.extend(this);
            this.filtros = [];
        }

        KendoDataSourceFilterGenerator.prototype = {
            $adicionarFiltro: function (campo, operador, valor) {
                var params = {
                    field: campo,
                    operator: operador,
                    value: valor
                };
                if (!this.filterSet) {
                    this.filtros.push(params);
                } else {
                    this.filterSet.filters.push(params);
                }
                return this;
            },
            $iniciarFilterSet: function (tipo) {
                if (this.filterSet) {
                    var parentNode = this.filterSet;
                    this.filterSet = this.filterSet.filters;
                    this.filterSet = { logic: tipo, filters: [], parent: parentNode };
                } else {
                    this.filterSet = { logic: tipo, filters: [] };
                }
                return this;
            },
            $adicionarFiltroLambda: function (campo, operador, childParams) {
                angular.forEach(childParams.filters, function (filter) {
                    filter.field = "child/" + filter.field;
                });
                var params = {
                    field: campo,
                    operator: operador,
                    params: childParams
                };
                if (!this.filterSet) {
                    this.filtros.push(params);
                } else {
                    this.filterSet.filters.push(params);
                }
                return this;
            },
            $finalizarFilterSet: function () {
                this.gerandoFilterSet = false;
                if (this.filterSet.parent) {
                    this.filterSet.parent.filters.push(this.filterSet);
                    this.filterSet = this.filterSet.parent;
                } else {
                    this.filtros.push(this.filterSet);
                    this.filterSet = null;
                }
                return this;
            },
            $gerarFiltro: function () {
                var filters = { filters: [] };
                this.filtros.forEach(function (obj) {
                    if (!obj.filters || (obj.filters && obj.filters.length > 0))
                        filters["filters"].push(obj);
                });
                return filters.length > 0 || filters.filters.length ? filters : null;
            }
        };

        function iniciar() {
            return new KendoDataSourceFilterGenerator();
        }

        return {
            iniciar: iniciar
        };

    }
})();