(function() {

    angular
        .module('bunge.modules.fornecedor.conta-corrente.saldo-contrato.detalhado')
        .controller('FornecedorContaCorrenteSaldoContratoDetalhadoController', FornecedorContaCorrenteSaldoContratoDetalhadoController);

    FornecedorContaCorrenteSaldoContratoDetalhadoController.$inject = ['KendoDataSourceFactory', '$stateParams', 'ContaCorrenteParamsFactory', 'HelperFactory'];

    function FornecedorContaCorrenteSaldoContratoDetalhadoController(KendoDataSourceFactory, $stateParams, ContaCorrenteParamsFactory, HelperFactory) {

        var vm = this;

        vm.paramsGridOptions = function(dataItem) {

            var dataSourceParams = {
                endpoint: 'ContratoSaldo',
                fornecedor: true,
                pageable: false,
                pageSize: 999,
                sort: [{
                    field: "ContratoCompraMTP",
                    dir: "asc"
                },{
                    field: "DataLancamento",
                    dir: "asc"
                }],
                group: [{ 
                    field: 'ContratoCompraMTP', 
                    aggregates: [{
                        field: 'ValorMoedaInterna', 
                        aggregate: 'sum'
                    }]
                }],
                groupable: true,
                header: ContaCorrenteParamsFactory.getHeader($stateParams)
            };

            var ds = KendoDataSourceFactory.iniciar(dataSourceParams);
            ds.$adicionarCampoModelo("ValorMoedaInterna", { type: "number" });
            ds.$adicionarCampoModelo("ValorTaxaCambio", { type: "number" });
            
            ds.$adicionarCampoAggregate("ValorMoedaInterna", "sum");

            return {
                resizable: true,
                filterable: true,
                sortable: true,
                columnMenu: true,
                dataSource: ds.$gerarDataSource(),
                excel: {
                    allPages: false,
                    fileName: 'SaldoContratos.csv'
                },
                toolbar: [{
                    name: 'excel',
                    template: "<kendo-button class='btn btn-default k-grid-excel'>Gerar Excel</kendo-button>"
                }],
                dataBound: function(e) {
                    HelperFactory.mergeSubFooterTemplate(e, 1, 4);
                    HelperFactory.mergeFooterTemplate(e, 1, 4);
                    HelperFactory.moveGridToolbarToFooter("#gridDetalhado");
                },
                columns: [
                    {
                        title: 'Contrato',
                        field: 'ContratoCompraMTP',
                        footerTemplate: "SALDO",
                        footerAttributes: { style: "text-align: center" },
                        groupFooterTemplate: "SUBTOTAL",
                    },
                    {
                        title: 'Tipo',
                        field: 'SubAgrupador'
                    },
                    {
                        title: 'Cotação US$',
                        field: 'ValorTaxaCambio',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: right" },
                        template: "#if(data.ValorTaxaCambio) {# #=kendo.toString (data.ValorTaxaCambio,'n3')# #}else{#0#}#"
                    },
                    {
                        title: 'Data Entrega',
                        field: 'DataLancamento',
                        headerAttributes: { style: "text-align: center" },
                        attributes: { style: "text-align: center" },
                        template: '#= kendo.toString(kendo.parseDate(data.DataLancamento,"yyyy-MM-ddTHH:mm:ss"),"dd/MM/yyyy")#'
                    },
                    {
                        title: 'Valor R$',
                        field: 'ValorMoedaInterna',
                        headerAttributes: { style: "text-align: center" },
                        template: "#if(data.ValorMoedaInterna) {# #=kendo.toString (data.ValorMoedaInterna,'n2')# #}else{#0#}#",
                        attributes: { style: "#if(data.IdDebitoCredito == 'D') {# color: red; #}# text-align: right;" },
                        footerAttributes: { style: "#if(data.ValorMoedaInterna.sum < 0) {# color: red; #}# text-align: right;" },
                        groupFooterAttributes: { style: "#if(data.ValorMoedaInterna.sum < 0) {# color: red; #}# text-align: right;" },
                        groupFooterTemplate: function(data) {
                            return data.ValorMoedaInterna && data.ValorMoedaInterna.sum ? kendo.toString(data.ValorMoedaInterna.sum, 'n2') : 0
                        },
                        footerTemplate: function(data) {
                            return data.ValorMoedaInterna && data.ValorMoedaInterna.sum ? kendo.toString(data.ValorMoedaInterna.sum, 'n2') : 0;
                        }
                    },
                    {
                        title: 'D/C',
                        field: 'IdDebitoCredito',
                        attributes: { style: "#if(data.IdDebitoCredito == 'D') {# color: red; #}#" },
                    }

                ]
            }
        };


    }
})();