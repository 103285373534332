(function () {
    'use strict';

    angular
       .module('bunge.components')
       .directive('currentPublicKey', ['Session', '$state', '$window', '$rootScope', function (Session, $state, $window, $rootScope) {

           return {
               restrict: 'A',
               link: function (scope, element, attrs) {
                   var publicKeyChange = function () {
                       var publicKey = attrs.title;
                       var currentLocation = $window.location.href;

                       var appList = Session.GetAppsByName(attrs.app);
                       for (var appIndex in appList) {
                           var menu = appList[appIndex].menu;
                           menu.forEach(function (value) {
                               if (currentLocation.split('/')[4] == value.internalKey && value.showPublicKey) {
                                   publicKey = value.publicKey;
                               }
                           });
                       } 
                       element.html(publicKey);
                   }
                   $rootScope.$on('$locationChangeSuccess', function (event, next, current) {
                       publicKeyChange();
                   });
                   publicKeyChange();
               },
           };
       }]);
})();

