if (window.location.pathname !== '/') {
    window.location.href = '/#' + window.location.pathname;
}

(function () {
    'use strict';

    angular.module('bunge.modules.administracao').controller('AdministracaoController', AdministracaoController);

    AdministracaoController.$inject = ['$rootScope', 'NavigationService', 'ViewService', 'VisibilidadeMenuLateralFactory'];
    function AdministracaoController($rootScope, NavigationService, ViewService, VisibilidadeMenuLateralFactory) {
        var vm = this;

        $rootScope.currentApp = ViewService.GetViewPublicKey('administracao');

        ViewService.GoToFirstView('administracao');

        if ($rootScope.navPermissions.length == 0)
            NavigationService.BuildUserMenu();

        vm.sideBarMenuVisivel = sideBarMenuVisivel;

        vm.expandir = expandir;

        function sideBarMenuVisivel() {
            return VisibilidadeMenuLateralFactory.getVisivel();
        };

        function expandir() {
            VisibilidadeMenuLateralFactory.setVisivel(false);
        };
    }

})();