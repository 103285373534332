(function() {
    'use strict';

    angular.module('bunge').factory('FOR_NAV_OPTIONS', FOR_NAV_OPTIONS);

    function FOR_NAV_OPTIONS() {
        return {
            contratos: {
                route: '.contratos',
                legenda: 'CONTRATOS',
                publicKey: "FOR0001",
                internalKey: "contratos",
                showPublicKey: true,
                order: 1
            },
            "conta-corrente": {
                route: '.conta-corrente',
                legenda: 'CONTA CORRENTE',
                publicKey: "FOR0004",
                internalKey: "conta-corrente",
                showPublicKey: true,
                order: 2,
                invisivel: true
            },
            pagamentos: {
                route: '.pagamentos',
                legenda: 'PAGAMENTOS',
                publicKey: "FOR0002",
                internalKey: "pagamentos",
                showPublicKey: true,
                order: 3
            },
            administracao: {
                route: '.adm',
                legenda: 'AGRUPAMENTO',
                publicKey: "FOR0003",
                internalKey: "administracao",
                showPublicKey: true,
                order: 4
            },
        };
    }
})();