(function () {
    'use strict';

    angular.module('bunge')
        .config(['$stateProvider', '$urlRouterProvider', '$locationProvider', '$httpProvider', '$resourceProvider', '$injector', 'FlashProvider', '$ocLazyLoadProvider', ConfigBunge]);

    function ConfigBunge($stateProvider, $urlRouterProvider, $locationProvider, $httpProvider, $resourceProvider, $injector, FlashProvider, $ocLazyLoadProvider) {
        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false
        });
        $locationProvider.hashPrefix = '!';

        $httpProvider.interceptors.push("AuthenticationInterceptor");
        $urlRouterProvider.otherwise(function ($injector) {
            var $state = $injector.get('$state');
            var $rootScope = $injector.get('$rootScope');
            var ModulesLoaded = $injector.get('ModulesLoaded');

            var $dynamic = $injector.get('DynamicModulesLoader');
            var TokenManagerFactory = $injector.get('TokenManagerFactory');

            var SessionManagerFactory = $injector.get('SessionManagerFactory');
            var $location = $injector.get('$location');
            var dest = $location.$$url.split('?')[0].split("/")[1];
            if (dest != "administracao" && dest != "fornecedor") {
                $state.go('home');
                return;
            }
            return TokenManagerFactory.GetToken().then(function (token) {
                if (!$rootScope.currentUser && token) {
                    SessionManagerFactory.CreateSession(token);
                }

                $dynamic.LoadModulesFromToken(dest).then(function () {
                    var found = false
                    $state.get().forEach(function (state) {
                        if (("." + state.name).indexOf($location.$$url.split('?')[0].split("/").join(".")) == 0 && state.name != "") {
                            $state.go(state.name);
                            found = true;
                        }
                    });

                    if (!found) {
                        $state.go('home');
                    }

                });
            });
        });

        $resourceProvider.defaults.stripTrailingSlashes = false;
        kendo.culture("pt-BR");
        
        kendo.ui.Menu.fn._mouseleave = function () { };

        FlashProvider.setTemplate('<div smooth-scroll' +
    'duration="700 "' +
    'easing="easeInQuint "' +
    'offset="300" container-id="flash.config.id "' +
     'role="alert" id="{{flash.id}} "' +
                   ' class="alert {{flash.config.class}} alert-{{flash.type}} alert-dismissible alertIn alertOut"> ' +
                   ' <div type="button" class="close" ng-show="flash.showClose" close-flash="{{flash.id}}"> ' +
                        '<span aria-hidden="true">&times;</span> ' +
                        '<span class="sr-only">Close</span> ' +
                    '</div> ' +
                   ' <span dynamic="flash.text"></span> ' +
             '   </div>');
    }

})();
