(function () {
	'use strict';

	angular.module('bunge').factory('ADM_NAV_DYN_OPTIONS', ADM_NAV_DYN_OPTIONS);
	
	function ADM_NAV_DYN_OPTIONS() {
		return {
			parametros: ['app/modules/administracao/submodules/parametros/parametros.routes.js',
                'app/modules/administracao/submodules/parametros/controller/parametros.controller.js',
                'app/modules/administracao/submodules/parametros/services/parametros.service.js'],
			
			"consultar-registro-acesso": ['app/modules/administracao/submodules/consultar-registro-acesso/consultar-registro-acesso.routes.js',
                'app/modules/administracao/submodules/consultar-registro-acesso/controllers/consultar-registro-acesso.controller.js'],
			
			"usuario-externo": ['app/modules/administracao/submodules/usuario-externo/usuario-externo.routes.js',
                'app/modules/administracao/submodules/usuario-externo/controllers/usuario-externo.controller.js',
                'app/modules/administracao/submodules/usuario-externo/services/usuario-externo.service.js'],

			"usuarios-por-fornecedor": ['app/modules/administracao/submodules/usuarios-por-fornecedor/usuarios-por-fornecedor.routes.js',
			    'app/modules/administracao/submodules/usuarios-por-fornecedor/modules/adicionar-usuario/adicionar-usuario.routes.js',
			      'app/modules/administracao/submodules/usuarios-por-fornecedor/modules/consultar-usuarios/consultar-usuarios.routes.js',
                'app/modules/administracao/submodules/usuarios-por-fornecedor/controllers/usuarios-por-fornecedor.controller.js',
                 'app/modules/administracao/submodules/usuarios-por-fornecedor/modules/adicionar-usuario/controller/adicionar-usuario.controller.js',
			      'app/modules/administracao/submodules/usuarios-por-fornecedor/modules/consultar-usuarios/controller/consultar-usuarios.controller.js',
			      'app/modules/administracao/submodules/usuarios-por-fornecedor/modules/consultar-usuarios/services/consultar-usuarios.service.js',
			      'app/modules/administracao/submodules/usuarios-por-fornecedor/modules/adicionar-usuario/services/adicionar-usuario.service.js'
           ],
            "administrador-de-aplicacao": [
'app/modules/administracao/submodules/administrador-de-aplicacao/administrador-de-aplicacao.routes.js',
                'app/modules/administracao/submodules/administrador-de-aplicacao/controller/administrador-de-aplicacao.controller.js',
                'app/modules/administracao/submodules/administrador-de-aplicacao/services/administrador-de-aplicacao.service.js'
            ],
            "manter-portal": [
            'app/modules/administracao/submodules/manter-portal/manter-portal.routes.js',
                'app/modules/administracao/submodules/manter-portal/controller/manter-portal.controller.js',
                'app/modules/administracao/submodules/manter-portal/services/manter-portal.services.js'
            ],
            "manter-aplicacao": [
  'app/modules/administracao/submodules/manter-aplicacao/manter-aplicacao.routes.js',
                'app/modules/administracao/submodules/manter-aplicacao/controller/manter-aplicacao.controller.js',
                'app/modules/administracao/submodules/manter-aplicacao/services/manter-aplicacao.service.js'
            ],
           "manter-funcionalidade": [
            'app/modules/administracao/submodules/manter-funcionalidade/manter-funcionalidade.routes.js',
                'app/modules/administracao/submodules/manter-funcionalidade/controller/manter-funcionalidade.controller.js',
                'app/modules/administracao/submodules/manter-funcionalidade/services/manter-funcionalidade.service.js'
            ],
			"manter-perfil": [
 'app/modules/administracao/submodules/administrador-de-perfil/administrador-de-perfil.routes.js',
                'app/modules/administracao/submodules/administrador-de-perfil/controller/administrador-de-perfil.controller.js',
                'app/modules/administracao/submodules/administrador-de-perfil/services/administrador-de-perfil.service.js'
			],
            "usuario-perfil": [
 'app/modules/administracao/submodules/usuario-perfil/usuario-perfil.routes.js',
                'app/modules/administracao/submodules/usuario-perfil/controller/usuario-perfil.controller.js',
                'app/modules/administracao/submodules/usuario-perfil/services/usuario-perfil.service.js'
            ],
            "acesso-empresa-filial":[
'app/modules/administracao/submodules/acesso-empresa-filial/acesso-empresa-filial.routes.js',
                'app/modules/administracao/submodules/acesso-empresa-filial/controller/acesso-empresa-filial.controller.js',
                'app/modules/administracao/submodules/acesso-empresa-filial/services/acesso-empresa-filial.service.js'
            ],
            
		};
	}
})();